import { PREFIX_URL } from 'constants/CommonUrl';

export const MENU = [
  { path: `/${PREFIX_URL}/dashboard`, name: 'Home', svg: 'Dashboard' },
  { path: `/${PREFIX_URL}/inventory`, name: 'Inventory', svg: 'Master' },
  { path: `/${PREFIX_URL}/client`, name: 'Clients', svg: 'Account' },
  { path: `/${PREFIX_URL}/transaction`, name: 'Transactions', svg: 'Transactions' },
  { path: `/${PREFIX_URL}/notification`, name: 'Bulk Emails', svg: 'Notification' },
  { path: `/${PREFIX_URL}/user`, name: 'Users', svg: 'Account' },
];
