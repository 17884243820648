import React from 'react';

import { Tooltip } from 'antd';

import { isEmpty, isString } from 'util/utils';

import { LAB_LINKS } from 'constants/Common';

const DetailView = {
  section1: {
    name: 'Basic Details',
    data: [
      { part: 1, label: 'Stone ID', key: 'vStnId' },
      { part: 1, label: 'Lab', key: 'lbNm' },
      {
        part: 1,
        label: 'Cert. No.',
        key: 'rptNo',
        render: (value, stone) => {
          if (isEmpty(value) || !isString(value)) return '-';
          if (!isString(LAB_LINKS[stone.lbNm.toUpperCase()])) return value;
          return (
            <Tooltip
              title="Click for Certificate"
              getPopupContainer={(triggerNode) => triggerNode}
              getTooltipContainer={(triggerNode) => triggerNode}
            >
              <a href={LAB_LINKS[stone.lbNm.toUpperCase()].replace('***', value)} target="_blank" rel="noreferrer">
                {value}
              </a>
            </Tooltip>
          );
        },
      },
      { part: 1, label: 'Location', key: 'locNm' },
      { part: 1, label: 'Shape', key: 'shpNm' },
      { part: 2, label: 'Rap ($) ', key: 'rap' },
      { part: 2, label: 'Carat', key: 'crt' },
      { part: 2, label: 'Discount (%)', key: 'back' },
      { part: 2, label: 'Color', key: 'colNm' },
      { part: 2, label: 'Price/Ct ($)', key: 'ctPr', isFormat: true },
      { part: 2, label: 'Clarity', key: 'clrNm' },
      { part: 2, label: 'Amount ($)', key: 'amt', isFormat: true },
      { part: 2, label: 'Cut', key: 'cutNm' },
      { part: 2, label: 'Brown', key: 'shdNm' },
      { part: 2, label: 'Polish', key: 'polNm' },
      { part: 2, label: 'Milky', key: 'mlkNm' },
      { part: 2, label: 'Symmetry', key: 'symNm' },
      { part: 2, label: 'Mix Tint', key: 'mixTintNm' },
      { part: 2, label: 'Fluorescence', key: 'fluNm' },
      { part: 2, label: 'Eye Clean', key: 'eClnNm' },
    ],
  },
  section2: {
    name: 'Measurements',
    data: [
      { label: 'Dimensions (MM)', key: 'msrmnt' },
      { label: 'Culet', key: 'cultNm' },
      { label: 'Table %', key: 'tblPer' },
      { label: 'Crown Height (%)', key: 'cHgt' },
      { label: 'Depth %', key: 'depPer' },
      { label: 'Pavilion Depth (%)', key: 'pHgt' },
      { label: 'Crown Angle', key: 'cAng' },
      { label: 'Star Length', key: 'strLn' },
      { label: 'Pav Angle', key: 'pAng' },
      { label: 'Lower Half', key: 'lwrHal' },
      { label: 'Girdle (%)', key: 'grdlPer' },
      { label: 'Girdle Faceted', key: 'grdlCondNm' },
      { label: 'Girdle', key: 'girdleStr' },
      { label: 'Ratio', key: 'ratio' },
    ],
  },
  section3: {
    name: 'Inclusion Details',
    data: [
      { label: 'Black Inclusion', key: 'blkIncNm' },
      { label: 'Other Inclusion', key: 'wInclNm' },
      { label: 'Open Inclusion', key: 'opIncNm' },
      { label: 'Extra Facet', key: 'eFact' },
      { label: 'Natural', key: 'naturalNm' },
      { label: 'H & A', key: 'hANm' },
    ],
  },
  section4: {
    name: 'Other Information',
    data: [
      { label: 'Key to Symbol', key: 'kToSStr' },
      { label: 'Lab Comments', key: 'lbCmt' },
      { label: 'Laser Ins', key: 'lsrInc' },
    ],
  },
};

export default DetailView;
