import React from 'react';

import { Drawer, Dropdown } from 'antd';

import DiamondDetail from 'components/DiamondDetail';
import DiamondTotalCard from 'components/DiamondListing/DiamondTotalCard';
import SortingRound from 'components/DiamondListing/SortingRound';

import { storeUserDetail } from 'services/UserApi';

import { classNames } from 'util/classNames';
import * as Hooks from 'util/hooks';
import { isEmpty } from 'util/utils';

import MoreOptionItem from './MoreOptionItem';

import closeSvg from 'assets/svg/InventoryResult/close.svg';
import excelWhiteSvg from 'assets/svg/InventoryResult/excel-white.svg';
import mailWhiteSvg from 'assets/svg/InventoryResult/mail-white.svg';
import moreSvg from 'assets/svg/InventoryResult/more.svg';
import printSvg from 'assets/svg/InventoryResult/print.svg';
import sortingSvg from 'assets/svg/InventoryResult/shorting.svg';
import sortinglistdownWhiteSvg from 'assets/svg/InventoryResult/shortinglistdown-white.svg';
import checkboxCheckedSvg from 'assets/svg/checkbox-checked.svg';
import checkboxUncheckedSvg from 'assets/svg/checkbox-unchecked.svg';
import leftArrowWhiteSvg from 'assets/svg/left-arrow-white.svg';
import rightArrowWhiteSvg from 'assets/svg/right-arrow-white.svg';

function TableGrouping({ displayTotal = [], checkbox, isChecked, onCheck, onUncheck, ...props }) {
  const [showOption, setShowOption] = Hooks.useBoolean(false);
  const [titleExpanded, setTitleExpanded] = Hooks.useBoolean(false);
  const [detail, setDetail] = Hooks.useBoolean(false);

  const handleCheck = React.useCallback(() => {
    isChecked ? onUncheck() : onCheck();
  }, [isChecked, onCheck, onUncheck]);

  const formatDateSlot = (start, end) => {
    const datePattern = /\d{2}\/\d{2}\/\d{4}/;
    const timePattern = /\b\d{2}:\d{2} [APMapm]{2}\b/;
    const extractDateTime = (input, pattern) => input?.match(pattern)[0];
    const extractedDate = extractDateTime(start, datePattern);
    const extractedTimeStart = extractDateTime(start, timePattern);
    const extractedTimeEnd = extractDateTime(end, timePattern);
    return `${extractedDate} ${extractedTimeStart}-${extractedTimeEnd}`;
  };
  return (
    <>
      {!props.tableHide && (
        <div className="tableGropupingBox">
          <div className="tableGroupTitle">
            {(props.Collapse || props.multipleCheckBox || props.groupingTitle || props.MoreOption) && (
              <span
                className={classNames([
                  `tableGroupTitleInner`,
                  titleExpanded && 'collpaseBlock',
                  showOption && 'moreOptionShow',
                  props.MoreOption && 'moreOptionShow',
                ])}
              >
                {props.Collapse && (
                  <div className="tableCollapse" onClick={props.onClick}>
                    <img src={props.img} />
                  </div>
                )}
                {checkbox && (
                  <div className="checkBoxSelect" onClick={handleCheck}>
                    <img src={isChecked ? checkboxCheckedSvg : checkboxUncheckedSvg} />
                  </div>
                )}
                {props.row.start ? <span>{formatDateSlot(props.row.start, props.row.end)}</span> : props.groupingTitle && <span>{props.groupingTitle}</span>}

                {props?.openDna?.currentType == 'BidTransectList' && (
                  <>
                    |&nbsp;
                    <span onClick={setDetail.toggle} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      DNA
                    </span>
                  </>
                )}
                {props.Action && (
                  <div className="d-flex tableListIconMain">
                    {props.pending && (
                      <>
                        <span className="tableListIcon" onClick={props.approveClick}>
                          Approve
                        </span>
                        {!props.reject && <span className="tableListIcon">Reject</span>}
                      </>
                    )}
                    <span className="tableListIcon">
                      <img src={mailWhiteSvg} alt="" />
                    </span>
                    <span className="tableListIcon">
                      <img src={excelWhiteSvg} alt="" />
                    </span>
                    <span className="tableListIcon">
                      <img src={printSvg} alt="" />
                    </span>
                  </div>
                )}
                <div className="groupClose" onClick={setTitleExpanded.toggle}>
                  {titleExpanded ? <img src={leftArrowWhiteSvg} alt="" /> : <img src={rightArrowWhiteSvg} alt="" />}
                </div>
                {props.MoreOption ? (
                  <Dropdown
                    overlayClassName="groupingDrodown"
                    overlay={
                      <div onClick={setShowOption.toggle}>
                        {props.data.map((d, index) => (
                          <MoreOptionItem
                            key={index}
                            Link={d.Link}
                            image={d.image}
                            title={d.title}
                            className={d.className}
                          />
                        ))}
                      </div>
                    }
                    trigger={['click']}
                  >
                    <span className="moreOption" onClick={setShowOption.toggle}>
                      <img src={moreSvg} alt="more" />
                    </span>
                  </Dropdown>
                ) : (
                  <span className="moreOption">
                    <img src={moreSvg} alt="more" />
                  </span>
                )}
              </span>
            )}
            <div className="tableHeaderMain">
              <span className="listIcon">
                <img src={sortingSvg} alt="icon" />
              </span>
              {props.totalDiamonds} Diamond
              {props.sortingCount === true ? (
                <div className="selectShortingMainBlock">
                  <div className="selectShorting">
                    <span className="selectShortingCount">1</span>
                    <span className="selectShortingArrow">
                      <img src={sortinglistdownWhiteSvg} />
                    </span>
                  </div>
                  <div className="shortingClose">
                    <img src={closeSvg} alt="icon" />
                  </div>
                </div>
              ) : (
                <SortingRound />
              )}
            </div>
          </div>
        </div>
      )}
      <tr className="boxShadowNone">
        {!props.columns && (
          <td className="groupingTableAmount" style={{ color: 'transparent' }}>
            ㅤ
          </td>
        )}
        {props.columns &&
          props.columns.map((column, index) => (
            <td className="groupingTableAmount" key={isEmpty(column.id) ? index : column.id}>
              {displayTotal.includes(column.id) && <DiamondTotalCard row={props.row} col={column.id} />}
            </td>
          ))}
      </tr>
      <Drawer onClose={setDetail.toggle} visible={detail} wrapClassName="diamondDetailPopup" destroyOnClose>
        {detail && <DiamondDetail data={props?.row?._original} onClose={setDetail.toggle} inDiamondPopup />}
      </Drawer>
    </>
  );
}

export default React.memo(TableGrouping);
