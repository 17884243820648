import React from 'react';

import * as Hooks from 'util/hooks';

import noImageSvgWhite from 'assets/svg/noimage-white.svg';
import noImageSvg from 'assets/svg/noimage.svg';
import './image.less';

const Image = React.memo(({ children, bgDark, ...props }) => {
  const [hasError, setError] = Hooks.useBoolean(false);

  React.useEffect(() => {
    setError.false();
  }, [props.src, setError]);

  return !hasError ? (
    <img onError={setError.true} {...props} />
  ) : children ? (
    children
  ) : (
    <img {...props} src={bgDark ? noImageSvgWhite : noImageSvg} />
  );
});

export default Image;
