export const PREFIX_URL = 'pnshah';
export const PREFIX_URL_WITH_SLASH = `/${PREFIX_URL}`;
export const MEDIA_URL = 'https://s3.ap-south-1.amazonaws.com/finestargroup';

let ALL_COMMON_URL = {};

if (process.env.REACT_APP_ENV === 'production') {
  const BACKEND_URL = 'https://api.mydiamonds.co/';
  //Don't change this value
  ALL_COMMON_URL = {
    BASE_URL: BACKEND_URL,
    BASE_LINK: '',
    BASE_URL_FILE_UPLOAD: '',
    BASE_SYNC_URL: 'https://sync.mydiamonds.co/',
    EXCELFILE_URL: BACKEND_URL + 'data/',
    FRONT_SITE_URL: 'https://mydiamonds.co',
  };
} else {
  //Don't Change
  const BACKEND_URL = 'http://pndevelopapi.democ.in/';

  ALL_COMMON_URL = {
    BASE_URL: BACKEND_URL,
    BASE_LINK: '',
    BASE_URL_FILE_UPLOAD: BACKEND_URL,
    BASE_SYNC_URL: 'https://sync.mydiamonds.co/',
    EXCELFILE_URL: BACKEND_URL + 'data/',
    FRONT_SITE_URL: 'http://pndevelop.democ.in',
  };
}

export const COMMON_URL = ALL_COMMON_URL;
