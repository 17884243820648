import { getUserList } from 'services/UserApi';

import { isArray } from 'util/utils';

const initialState = {};

export const UserActionTypes = {
  SET_EDIT_USER: 'SET_EDIT_USER',
  SET_USERS_LIST: 'SET_USERS_LIST',
  GET_USERS_LIST: 'GET_USERS_LIST',
};

export const UserActions = {
  setEditUser: (user) => ({
    type: UserActionTypes.SET_EDIT_USER,
    payload: user,
  }),
  setUsersList: (id, list, count) => ({
    type: UserActionTypes.SET_USERS_LIST,
    payload: { id, list, count },
  }),
  getUsersList: (id, page, limit) => async (dispatch) => {
    const [, res] = await getUserList({ page, limit, filter: { account: id }, sort: [{ name: 'ASC' }] });

    const list = res?.data?.list;

    return dispatch({
      type: UserActionTypes.SET_USERS_LIST,
      payload: { id, list: res?.code === 'OK' && isArray(list) ? list : [], count: res?.data?.count },
    });
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UserActionTypes.SET_EDIT_USER:
      return { ...state, editUser: payload };

    case UserActionTypes.SET_USERS_LIST:
      return { ...state, [payload?.id]: payload?.list, count: payload?.count };

    default:
      return { ...state };
  }
};
