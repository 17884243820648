import Cookies from 'js-cookie';
import _find from 'lodash/find';
import moment from 'moment';

import Storage from 'services/storage';

import axios from 'util/Api';
import { history } from 'util/history';
import { Notify } from 'util/notify';
import { isArray, isEmpty, logWarn } from 'util/utils';

import { BASE_SYNC_URL, BASE_URL_FILE_UPLOAD } from 'constants/Common';

import { resetStore } from 'appRedux/resetStore';

export const handleLogout = () => {
  Cookies.remove('token');
  Storage.deleteAll();
  resetStore();
  history.push(`/signin`);
};

export const checkDate = (start, end) =>
  !isEmpty(start) && !isEmpty(end) ? moment(start).isBefore(moment(end)) : undefined;

export const displayDate = (date) => (!isEmpty(date) ? moment(date).format('MMM DD YYYY, hh:mm:ss a') : '-');

export const displyFromNow = (date) => (!isEmpty(date) ? moment(date).fromNow() : '-');

export const getDays = (date) => (!isEmpty(date) ? `${moment().diff(date, 'days')} days ago` : undefined);

export const getDefaultsValue = (array, key) =>
  isArray(array) ? _find(array, { isPrimary: false })?.[key] : undefined;

export const getPrimaryValue = (array, key) => (isArray(array) ? _find(array, { isPrimary: true })?.[key] : undefined);

export const getSyncValue = () => {
  return false; // Told by CTO
  // const CurrentUserType = (Storage.get('user'));

  // if (CurrentUserType.type === USERS_TYPE.SUPER_ADMIN) return true;

  // const StatusData = (Storage.get('permission'));

  // const SyncStatus = _find(StatusData, object => {
  //   if (object.module == 'sync') return object;
  // });

  // const isSync = SyncStatus && SyncStatus.permissions.view;
  // // localStorage.setItem('isSync', isSync);
  // return isSync;
};

export const callApi = ({ method, url, request, config, ...params }, cb) => {
  const token = Cookies.get('token');
  config = {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`,
      ...config?.headers,
    },
  };
  if (params?.isSync) config.baseURL = BASE_SYNC_URL;
  if (params?.isFileUploadUrl) config.baseURL = BASE_URL_FILE_UPLOAD;
  if (request?.useCache) request.useCache = true;
  const notify = config?.notify ?? true;

  axios[method](url, request, config)
    .then(({ data }) => {
      cb(data?.code !== 'OK', data);
      if (data?.code === 'E_UNAUTHORIZED') handleLogout();
    })
    .catch((error) => {
      if (error?.response?.data) {
        if (notify) Notify.error({ message: error.response.data?.message });
        cb(error || true, error.response.data);
      } else {
        if (notify) Notify.error({ message: 'Something bad happend' });
        cb(error || true, {});
      }
      logWarn(error);
    });
};

export const callApiAsync = (object) =>
  new Promise((resolve) => {
    callApi(object, (...args) => resolve(args));
  });

/**
 * Adds url params to api config
 */
export const withUrlParams = (conf, params) => ({
  ...conf,
  url: `${conf.url}/${params.join('/')}`.replace(/[/]+/g, '/'),
});

const UtilService = {
  callApi,
  callApiAsync,
  checkDate,
  displayDate,
  displyFromNow,
  getDays,
  getDefaultsValue,
  getPrimaryValue,
  getSyncValue,
  handleLogout,
  withUrlParams,
};

export default UtilService;
