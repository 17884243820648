import React from 'react';

import Modal from 'containers/CommonModal';

import { formatDate, returnIfNotEmpty } from 'util/utils';

import eyeGray from 'assets/svg/InventoryResult/view.svg';

function OfferDetailsCell({ row }) {
  const [detailsVisible, setDetailsVisibility] = React.useState(false);

  const hideDetails = React.useCallback(() => setDetailsVisibility(false), [setDetailsVisibility]);

  const details = React.useMemo(() => {
    const { user, offerValidDate, broker, seller, remarks } = row?.original;
    return { user, offerValidDate, broker, seller, remarks };
  }, [row?.original]);

  return (
    <div className="modal-details">
      <span title="View Offer Details" onClick={setDetailsVisibility.true}>
        <img src={eyeGray} alt="View offer Details" style={{ display: 'inline', height: '15px', width: '15px' }} />
      </span>
      <Modal
        visible={detailsVisible}
        onCancel={hideDetails}
        title="Offer Details"
        footer={null}
        destroyOnClose
        noDefaults
        centered
      >
        <div className="detailShow d-flex flex-wrap">
          <div className="detail separator">
            <span>Party: </span>
            <span>{returnIfNotEmpty(details.user?.account?.companyName, '-')}</span>
          </div>
          <div className="detail separator">
            <span>Buyer: </span>
            <span>
              {returnIfNotEmpty([details?.user?.firstName, details?.user?.lastName].filter(Boolean).join(' '), '-')}
            </span>
          </div>
          {/* <div className="detail separator">
            <span>Broker: </span>
            <span>
              {returnIfNotEmpty([details?.broker?.firstName, details?.broker?.lastName].filter(Boolean).join(' '), '-')}
            </span>
          </div>
          <div className="detail separator">
            <span>Seller: </span>
            <span>
              {returnIfNotEmpty([details?.seller?.firstName, details?.seller?.lastName].filter(Boolean).join(' '), '-')}
            </span>
          </div> */}
          <div className="detail">
            <span>Invoice Date: </span>
            <span>{returnIfNotEmpty(formatDate(details?.offerValidDate), '-')}</span>
          </div>
          <div className="detail width-100">
            <span>Comment: </span>
            <span>{returnIfNotEmpty(details?.remarks, '-')}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

OfferDetailsCell.propTypes = {};

export default React.memo(OfferDetailsCell);
