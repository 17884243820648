import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';

import { getSettings, updateSettings } from 'services/CommonApi';
import { showNotification } from 'services/NotificationService';

import { isEmpty, isObject } from 'util/utils';

import { PROJECT_SETTINGS } from 'constants/Common';

import './setting.less';

const defaultValues = {
  minBuynowDiscount: 0,
  maxBuynowDiscount: 0,
  bestBuyMinBuynowDiscount: 0,
  bestBuyMaxBuynowDiscount: 0,
  noOfOrderStone: 0,
};

const validationSchema = Yup.object().shape({
  minBuynowDiscount: Yup.number()
    .typeError('Please Insert Min Discount')
    .min(-100, 'Min Discount must be greated than or equal to -100')
    .max(0, 'Min Discount must be less than or equal to 0')
    .required('Please Insert Min Discount'),
  maxBuynowDiscount: Yup.number()
    .typeError('Please Insert Max Discount')
    .min(0, 'Min Discount must be greated than or equal to 0')
    .max(100, 'Min Discount must be less than or equal to 100')
    .required('Please Insert Max Discount'),
  bestBuyMinBuynowDiscount: Yup.number()
    .typeError('Please Insert Best Buy Min Discount')
    .min(-100, 'Best Buy Min Discount must be greated than or equal to -100')
    .max(0, 'Best Buy Min Discount must be less than or equal to 0')
    .required('Please Insert Best Buy Min Discount'),
  bestBuyMaxBuynowDiscount: Yup.number()
    .typeError('Please Insert Best Buy Max Discount')
    .min(0, 'Best Buy Min Discount must be greated than or equal to 0')
    .max(100, 'Best Buy Min Discount must be less than or equal to 100')
    .required('Please Insert Best Buy Max Discount'),
  noOfOrderStone: Yup.number()
    .min(0, 'Best Buy Min Discount must be greated than or equal to 0')
    .max(100, 'Best Buy Min Discount must be less than or equal to 100'),
});

function DiscountSettings() {
  const [setting, setSetting] = React.useState({});

  const initialValues = React.useMemo(() => {
    const updatedEntries = Object.entries(defaultValues).map(([key, value]) => {
      return [key, isEmpty(setting?.[key]) ? value : setting?.[key]];
    });
    return Object.fromEntries(updatedEntries);
  }, [setting]);

  const getList = React.useCallback(async () => {
    const [err, res] = await getSettings({ type: PROJECT_SETTINGS.TYPE.MIN_MAX });
    if (!err && isObject(res?.data?.settings)) setSetting(res?.data?.settings);
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const onSubmit = React.useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    const [err, res] = await updateSettings({ type: PROJECT_SETTINGS.TYPE.MIN_MAX, settings: values });
    setSubmitting(false);
    if (!err) {
      showNotification(res);
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { handleSubmit, handleReset, handleChange, handleBlur } = formik;

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <div className="headeTitle">
        <h2>Discount Difference</h2>
      </div>
      <div className="d-flex flex-wrap homeSettingWrapper mt-20">
        <InputBlock
          type="number"
          name="minBuynowDiscount"
          label="Min Discount"
          placeholder="Min Discount"
          min={-100}
          max={0}
          value={formik.values.minBuynowDiscount}
          error={formik.touched?.minBuynowDiscount && formik.errors?.minBuynowDiscount}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputBlock
          type="number"
          name="maxBuynowDiscount"
          label="Max Discount"
          placeholder="Max Discount"
          min={0}
          max={100}
          value={formik.values.maxBuynowDiscount}
          error={formik.touched?.maxBuynowDiscount && formik.errors?.maxBuynowDiscount}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputBlock
          className="mt-20"
          type="number"
          name="bestBuyMinBuynowDiscount"
          label="Best Buy Min Discount"
          placeholder="Best Buy Min Discount"
          min={-100}
          max={0}
          value={formik.values.bestBuyMinBuynowDiscount}
          error={formik.touched?.bestBuyMinBuynowDiscount && formik.errors?.bestBuyMinBuynowDiscount}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputBlock
          className="mt-20"
          type="number"
          name="bestBuyMaxBuynowDiscount"
          label="Best Buy Max Discount"
          placeholder="Best Buy Max Discount"
          min={0}
          max={100}
          value={formik.values.bestBuyMaxBuynowDiscount}
          error={formik.touched?.bestBuyMaxBuynowDiscount && formik.errors?.bestBuyMaxBuynowDiscount}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <InputBlock
          className="mt-20"
          type="number"
          name="noOfOrderStone"
          label="Pending Order Stones"
          placeholder="Pending Order Stones"
          min={0}
          max={100}
          value={formik.values?.noOfOrderStone}
          error={formik.touched?.noOfOrderStone && formik.errors?.noOfOrderStone}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <div className="width-100 d-flex mt-40">
          <button className="commonButton mr-10" type="submit" value="Submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default DiscountSettings;
