import * as DiamondBlockAPI from 'services/DiamondBlockApi';

import { BASE_URL } from 'constants/Common';

import { LoaderService } from './LoaderService';

export class MemoService {
  static async viewPdf(blockTxnId) {
    LoaderService.startLoading(true);
    const [err, res] = await DiamondBlockAPI.getMemoPdf({ id: blockTxnId });
    if (!err) window.open(`${BASE_URL}data${res?.data}`);
    LoaderService.stopLoading(true);
  }
}
