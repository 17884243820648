export const API_ROUTES = {
  Auth: {
    forgot: { method: 'post', url: 'admin/v1/auth/forgot-password', config: { loader: false } },
  },

  Master: {
    paginate: { method: 'post', url: 'admin/v1/master/paginate', config: { loader: false } },
    getDetail: { method: 'get', url: 'admin/v1/master', config: { loader: false } },
    listByCode: { method: 'post', url: 'admin/v1/master/list-by-code', config: { loader: false } },
    update: { method: 'put', url: 'admin/v1/master' },
    syncPaginate: { method: 'post', url: '/admin/hk/sync/master', isSync: true },
    create: { method: 'post', url: 'admin/v1/master/create' },
    sequenceUpdate: { method: 'post', url: 'admin/v1/master/bulk-sequence-update' },
    delete: { method: 'post', url: '/admin/v1/account/delete-user-account' },
  },

  Account: {
    create: { method: 'post', url: 'admin/v1/account/create' },
    update: { method: 'post', url: 'admin/v1/account/update' },
    paginate: { method: 'post', url: 'admin/v1/account/paginate', config: { loader: false } },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/ledger', isSync: true },
    get: { method: 'post', url: 'admin/v1/account' },
    getDetail: { method: 'post', url: '/admin/v1/account/getdetails' },
    delete: { method: 'delete', url: '/admin/v1/account/delete' },
    deleteOld: { method: 'post', url: '/admin/v1/account/delete-user-account' },
    AccountCodeUpdate: { method: 'post', url: '/admin/v1/account/update/code' },
    getUsers: { method: 'post', url: 'admin/v1/account/getUsers' },
    changeStatus: { method: 'post', url: 'admin/v1/account/changeStatus', config: { loader: false } },
    UpsertVendorFolder: {
      method: 'post',
      url: '/diamond-sheet/upsert-vendor-folder',
      isFileUploadUrl: true,
    },
    vendorList: { method: 'post', url: '/diamond-sheet/vendor-folder-list', isFileUploadUrl: true },
    // UpdateVendorFolder: { method: "post", url: "/diamond-sheet/updateVendorFolder", isFileUploadUrl: true },
    AccountList: { method: 'post', url: '/admin/v1/user/sub-user-list' },
  },

  Seller: {
    paginate: { method: 'post', url: 'admin/v1/seller/paginate', config: { loader: false } },
  },

  Event: {
    paginate: { method: 'post', url: 'admin/v1/event/paginate' },
    create: { method: 'post', url: 'admin/v1/event/create' },
    update: { method: 'put', url: 'admin/v1/event/' },
    find: { method: 'get', url: 'admin/v1/event/' },
    sequenceUpdate: { method: 'post', url: '/admin/v1/event/sequence' },
  },

  Version: {
    paginate: { method: 'post', url: 'admin/v1/version/paginate' },
    create: { method: 'post', url: 'admin/v1/version/create' },
    update: { method: 'post', url: 'admin/v1/version/update' },
    find: { method: 'get', url: 'admin/v1/version/view/' },
    delete: { method: 'delete', url: 'admin/v1/version/destroy/' },
  },

  Contactus: {
    paginate: { method: 'post', url: 'admin/v1/contactus/paginate' },
  },

  Sizemaster: {
    paginate: { method: 'post', url: 'admin/v1/size/paginate', config: { loader: false } },
    create: { method: 'post', url: 'admin/v1/size/create' },
    view: { method: 'get', url: 'admin/v1/size' },
    update: { method: 'put', url: 'admin/v1/size' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/size', isSync: true },
  },

  CutGroup: {
    paginate: { method: 'post', url: '/admin/v1/cut-group/paginate' },
    create: { method: 'post', url: '/admin/v1/cut-group/create' },
    view: { method: 'get', url: '/admin/v1/cut-group' },
    update: { method: 'put', url: '/admin/v1/cut-group' },
  },

  EmailGroup: {
    paginate: { method: 'post', url: '/admin/v1/email-group/paginate' },
    create: { method: 'post', url: '/admin/v1/email-group/create' },
    update: { method: 'post', url: '/admin/v1/email-group/update' },
    delete: { method: 'post', url: '/admin/v1/email-group/destroy' },
    view: { method: 'get', url: '/admin/v1/email-group/' },
  },

  Terms: {
    paginate: { method: 'post', url: 'admin/v1/day-term/paginate', config: { loader: false } },
    list: { method: 'get', url: '/admin/v1/day-term/' },
    syncPaginate: { method: 'post', url: '/admin/hk/sync/accountTerm', isSync: true }, //admin/v1
  },

  Gridcolumn: {
    list: { method: 'post', url: 'admin/v1/grid/get-columns' },
    update: { method: 'post', url: 'admin/v1/grid/upsert' },
    dropdownlist: { method: 'post', url: 'admin/v1/grid/list' },
  },

  Comment: {
    paginate: { method: 'post', url: 'admin/v1/diamond-comment/by-user' },
  },

  Diamond: {
    paginate: { method: 'post', url: 'admin/v1/diamond/paginate' },
    search: { method: 'post', url: 'admin/v1/diamond/search/list' },
    delete: { method: 'post', url: 'admin/v1/diamond/search/delete' },
    quickSearch: { method: 'post', url: 'admin/v1/diamond/quick-search' },
    syncPaginate: { method: 'post', url: '/admin/hk/sync/diamond', isSync: true },
    view: { method: 'post', url: '/admin/v1/diamond/view' },
    excel: { method: 'post', url: '/admin/v1/diamond/excel' }, //admin/v1
    upload: { method: 'post', url: '/admin/v1/diamond/upload-sheet' }, //admin/v1
    // List Report No
    getReportNoList: { method: 'post', url: '/admin/v1/diamond/reportno/paginate' },
  },

  Time: {
    getTime: { method: 'get', url: '/admin/v1/common/server-time' },
    getBidTime: { method: 'post', url: '/admin/v1/diamond-bid/bid-config' },
  },

  PlatformDetail: {
    paginate: { method: 'post', url: 'admin/v1/platform-detail/paginate' },
    add: { method: 'post', url: '/admin/v1/platform-detail/create' },
    view: { method: 'get', url: '/admin/v1/platform-detail' },
    update: { method: 'put', url: '/admin/v1/platform-detail' },
    delete: { method: 'post', url: '/admin/v1/platform-detail/destroy' },
    sync: { method: 'post', url: '/admin/v1/uploadglobalinventory/sync' },
  },

  TermsDetail: {
    paginate: { method: 'post', url: '/admin/v1/account-term/paginate' },
    add: { method: 'post', url: '/admin/v1/account-term/create' },
    view: { method: 'get', url: '/admin/v1/account-term/' },
    update: { method: 'post', url: '/admin/v1/account-term/update' },
    approve: { method: 'post', url: '/admin/v1/account-term/approve' },
    delete: { method: 'post', url: '/admin/v1/account-term/delete' },
  },

  Banner: {
    paginate: { method: 'post', url: '/admin/v1/banner/paginate' },
    add: { method: 'post', url: '/admin/v1/banner/create' },
    view: { method: 'get', url: '/admin/v1/bannerview/' },
    update: { method: 'put', url: '/admin/v1/banner/' },
    approve: { method: 'post', url: '/admin/v1/banner/approve' },
    delete: { method: 'delete', url: '/admin/v1/banner/' },
  },

  FeatureStone: {
    paginate: { method: 'post', url: '/admin/v1/featuredStone/paginate' },
    add: { method: 'post', url: '/admin/v1/featuredStone/create' },
    delete: { method: 'post', url: '/admin/v1/featuredStone/destroy' },
  },

  Country: {
    paginate: { method: 'post', url: 'admin/v1/country/paginate', config: { loader: false } },
    add: { method: 'post', url: '/admin/v1/country/add' },
    update: { method: 'post', url: '/admin/v1/country/update' },
    delete: { method: 'post', url: '/admin/v1/country/delete' },
    fetchId: { method: 'post', url: '/admin/v1/country/' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/country', isSync: true }, //admin/v1
  },

  State: {
    paginate: { method: 'post', url: 'admin/v1/state/paginate', config: { loader: false } },
    add: { method: 'post', url: '/admin/v1/state/add' },
    update: { method: 'post', url: '/admin/v1/state/update' },
    delete: { method: 'post', url: '/admin/v1/state/delete' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/state', isSync: true }, //admin/v1
  },

  City: {
    paginate: { method: 'post', url: 'admin/v1/city/paginate', config: { loader: false } },
    add: { method: 'post', url: '/admin/v1/city/add' },
    update: { method: 'post', url: '/admin/v1/city/update' },
    delete: { method: 'post', url: '/admin/v1/city/delete' },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/city', isSync: true }, //admin/v1
  },

  Parcel_master: {
    paginate: { method: 'post', url: 'admin/v1/parcel-price/paginate' },
    dropdownlist: { method: 'post', url: 'admin/v1/parcel-price/get-dates' },
    update: { method: 'post', url: 'admin/v1/parcel-price/upsert' },
  },

  Pricing: {
    paginate: { method: 'post', url: '/admin/v1/pricing/paginate' },
  },

  Feedback: {
    paginate: { method: 'post', url: 'admin/v1/feedback/paginate' },
    destroy: { method: 'post', url: 'admin/v1/feedback/destroy' },
  },

  FeaturedStone: {
    paginate: { method: 'post', url: 'admin/v1/featuredStone/paginate' },
    destroy: { method: 'post', url: 'admin/v1/featuredStone/destroy' },
    create: { method: 'post', url: 'admin/v1/featuredStone/create' },
  },

  TrackType: {
    paginate: { method: 'post', url: 'admin/v1/diamond-track/paginate' },
  },

  ActiveDeactive: {
    list: { method: 'post', url: 'admin/v1/common/boolean-status-update', config: { loader: false } },
  },

  DiamondTrack: {
    paginate: { method: 'post', url: '/admin/v1/diamond-track/paginate' },
    changeStatus: { method: 'post', url: '/admin/v1/diamond-track/change-status', config: { loader: false } },
    create: { method: 'post', url: 'admin/v1/diamond-track/create' },
    delete: { method: 'post', url: 'admin/v1/diamond-track/delete' },
    statusUpdate: { method: 'post', url: '/admin/v1/diamond-track/status-update ', config: { loader: false } },
  },

  DiamondBid: {
    paginate: { method: 'post', url: '/admin/v1/diamond-bid/paginate' },
    changeStatus: { method: 'post', url: '/admin/v1/diamond-bid/change-status', config: { loader: false } },
    delete: { method: 'post', url: '/admin/v1/dynamic-diamond-bid/delete' },
    create: { method: 'post', url: '/admin/v1/diamond-bid/create' },
    dynamicBid: { method: 'post', url: '/admin/v1/dynamic-diamond-bid/paginate' },
    winner: { method: 'post', url: '/admin/v1/dynamic-diamond-bid/bids-result' },
  },

  VendorDiamond: {
    updateStatus: { method: 'post', url: '/admin/v1/vendor-diamond/update-status', config: { loader: false } },
  },

  User: {
    paginate: { method: 'post', url: 'admin/v1/user/paginate', config: { loader: false } },
    syncPaginate: { method: 'post', url: 'admin/hk/sync/user', isSync: true }, //admin/v1
    ResetPassword: { method: 'post', url: 'admin/v1/user/reset-password' },
    Upsert: { method: 'post', url: 'admin/v1/user/create' },
    update: { method: 'put', url: 'admin/v1/user/' },
    VerifiedUser: { method: 'post', url: 'admin/v1/user/verify-users' },
    delete: { method: 'post', url: '/admin/v1/user/admin-user-delete' },
    getDetail: { method: 'get', url: '/admin/v1/user/' },
    verifiedEmail: { method: 'post', url: '/admin/v1/user/resend-verification-email' },
    createMany: { method: 'post', url: '/admin/v1/user/create-many' },
    manualUserSync: { method: 'post', url: '/admin/v1/user/manualUserSync' },
  },

  Permission: {
    update: { method: 'post', url: 'admin/v1/permission/upsert' },
    paginate: { method: 'post', url: 'admin/v1/permission/paginate' },
    listByRole: { method: 'get', url: 'admin/v1/permission/view-by-role/' },
    listByCode: { method: 'post', url: 'admin/v1/master/list-by-code' },
    listByUser: { method: 'post', url: 'admin/v1/permission/user-permission' },
  },

  Setting: {
    getSetting: { method: 'get', url: 'admin/v1/setting/' },
    upsert: { method: 'post', url: '/admin/v1/setting/upsert' },
    view: { method: 'post', url: '/admin/v1/setting/view' },
  },

  Notification: {
    BulkEmail: { method: 'post', url: '/admin/v1/bulknotification/process' },
    paginate: { method: 'post', url: 'admin/v1/notification/paginate' },
    upsert: { method: 'post', url: '/admin/v1/notification/sendNotification' },
  },

  PageTracking: {
    paginate: { method: 'post', url: '/admin/v1/analytics/paginate', config: { loader: false } },
  },

  Transport: {
    paginate: { method: 'post', url: '/admin/v1/transport/paginate' },
    update: { method: 'post', url: '/admin/v1/transport/update' },
  },

  Hospitality: {
    paginate: { method: 'post', url: '/admin/v1/product-order/paginate' },
    update: { method: 'post', url: '/admin/v1/product-order/update' },
  },

  Career: {
    paginate: { method: 'post', url: '/admin/v1/career/paginate' },
  },

  Product: {
    paginate: { method: 'post', url: '/admin/v1/master/list-by-code' },
    mainPaginate: { method: 'post', url: '/admin/v1/product/paginate' },
    sequenceUpdate: { method: 'post', url: '/admin/v1/product/bulk-sequence-update' },
  },

  Platform: {
    paginate: { method: 'post', url: '/admin/v1/user/by-login-device-type' },
  },

  ResetPassword: {
    paginate: { method: 'post', url: '/auth/reset-password-by-user' }, //admin/v1
  },

  VerifyEmail: {
    verify: { method: 'post', url: '/admin/v1/auth/verify-email' },
  },

  SearchHistory: {
    paginate: { method: 'post', url: '/admin/v1/diamond/search/history' },
  },

  Location: {
    paginate: { method: 'post', url: '/admin/v1/news-event/paginate' },
    getsingle: { method: 'get', url: '/admin/v1/news-event/' },
    upsert: { method: 'put', url: '/admin/v1/news-event/' },
  },

  UploadHistory: {
    paginate: { method: 'post', url: '/admin/v1/diamond-sheet/history', isFileUploadUrl: true },
    ErrorRecord: { method: 'post', url: '/admin/v1/diamond-sheet/error-records' },
  },

  StockSummary: {
    paginate: { method: 'post', url: '/admin/v1/diamond/summary-report', isSync: false },
    list: { method: 'post', url: '/admin/v1/diamond/summary', isSync: false },
  },

  StockDist: {
    summary: { method: 'post', url: 'admin/v1/bifurcateglobalinventory/get-summary' },
    list: { method: 'post', url: 'admin/v1/bifurcateglobalinventory/getData' },
  },

  dashboard: {
    Admin: { method: 'post', url: '/admin/v1/dashboard' },
  },

  MatchPair: {
    Paginate: { method: 'post', url: 'admin/v1/match-pair/diamond/filter' },
    Insert: { method: 'post', url: 'admin/v1/match-pair/diamond/filter' },
  },

  Demand: {
    Upsert: { method: 'post', url: '/admin/v1/diamond/search/upsert' },
  },

  Theme: {
    project: { method: 'post', url: 'admin/v1/theme-setup/paginate', config: { loader: false } },
    upsert: { method: 'post', url: 'admin/v1/theme-setup/upsert', config: { loader: false } },
  },

  File: {
    Upload: { method: 'post', url: '/admin/v1/upload-file', config: { loader: false } },
  },

  Inventory: {
    exportExcel: { method: 'post', url: '/admin/v1/diamond/excel' },
    printPdf: { method: 'post', url: '/admin/v1/diamond/print' },
    xRayMail: { method: 'post', url: '/admin/v1/diamond/x-ray-mail' },
    diamondComment: { method: 'post', url: '/admin/v1/diamond-comment/upsert' },
    downloadZip: { method: 'post', url: '/admin/v1/diamond/download-zip' },
    downloadFile: { method: 'post', url: '/admin/v1/download-file' },
    ftc: { method: 'post', url: '/admin/v1/diamond/diamond-ftc' },
    downloadCertificate: { method: 'post', url: '/admin/v1/diamond/download-certificate' },
    imageCheck: { method: 'post', url: '/admin/v1/diamond/diamond-files' },
    print: { method: 'post', url: '/admin/v1/diamond/pdf' },
  },

  DiamondConfirm: {
    Request: { method: 'post', url: 'admin/v1/diamond-confirm/request' },
  },

  Memo: {
    Insert: { method: 'post', url: '/admin/v1/diamond-block/create' },
    paginate: { method: 'post', url: 'admin/v1/diamond-block/paginate' },
    viewDetails: { method: 'post', url: 'admin/v1/diamond-block/view-details' },
    changeStatus: { method: 'post', url: '/admin/v1/diamond-block/change-status', config: { loader: false } },
    GeneratePdf: { method: 'post', url: 'admin/v1/diamond-block/createMemo-pdf' },
  },

  Broker: {
    list: { method: 'post', url: '/admin/v1/broker/list', config: { loader: false } },
  },

  UserTerms: {
    Request: { method: 'post', url: '/admin/v1/user/user-term' },
  },

  StageMemo: {
    paginate: { method: 'post', url: '/admin/v1/diamond-block/master/paginate', config: { loader: false } },
  },

  Order: {
    paginate: { method: 'post', url: '/admin/v1/memo/paginate' },
    statusChange: { method: 'post', url: 'admin/v1/order/change-status', config: { loader: false } },
    parseExcel: { method: 'post', url: 'admin/v1/order/upload-excel' },
  },

  projectSettings: {
    getSetting: { method: 'get', url: 'admin/v1/project-setting/' },
    upsert: { method: 'post', url: '/admin/v1/project-setting/upsert' },
    view: { method: 'post', url: '/admin/v1/project-setting/view' },
  },

  office: {
    list: { method: 'post', url: '/admin/v1/cabin-slot/paginate' },
    create: { method: 'post', url: '/admin/v1/cabin-schedule/create' },
    paginate: { method: 'post', url: 'admin/v1/cabin-schedule/list' },
    destroy: { method: 'post', url: 'admin/v1/cabin-schedule/destroy' },
  },

  Report: {
    proforma: { method: 'post', url: '/admin/v1/proforma/paginate' },
  },

  InventorySearch: {
    paginate: { method: 'post', url: '/admin/v1/diamond/reportno/paginate' },
  },

  OfferHistory: {
    paginate: { method: 'post', url: '/admin/v1/diamond-track/offer-count' },
  },

  similarStone: {
    paginate: { method: 'post', url: '/admin/v1/match-pair/diamond' },
  },

  SGIStatus: {
    check: { method: 'post', url: '/sync/Diamond/find-diamond-raw-data-by-sheet', isSync: true },
    compareStock: { method: 'post', url: '/sync/diamond/compare-stock', isSync: true },
    getSGIData: { method: 'post', url: '/sync/Diamond/get-stone-status', isSync: true },
  },

  Offer: {
    UploadExcel: { method: 'post', url: '/admin/v1/diamond-track/create-by-excel' },
  },

  password: {
    change: { method: 'post', url: '/admin/v1/auth/reset-password-by-user' },
  },

  bidConfig: {
    paginate: { method: 'post', url: '/admin/v1/bid-configuration/paginate' },
    create: { method: 'post', url: '/admin/v1/bid-configuration/create' },
    update: { method: 'put', url: '/admin/v1/bid-configuration/update' },
    delete: { method: 'post', url: '/admin/v1/bid-configuration/destroy' },
    view: { method: 'get', url: '/admin/v1/bid-configuration/' },
  },
};

export default API_ROUTES;
