import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useTable } from 'react-table';

import { Checkbox, Drawer } from 'antd';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';

import CircularProgress from 'components/CircularProgress';
import DiamondDetail from 'components/DiamondDetail';
import DiamondSliderImage from 'components/DiamondDetail/DiamondSliderImage';
import Embed from 'components/DiamondDetail/Embed';
import IFrame from 'components/DiamondDetail/IFrame';
import NotAvailable from 'components/DiamondDetail/NoDataFoundComponent';
import DiamondListingHead from 'components/DiamondListing/DiamondListingHead';
import NoDataShow from 'components/DiamondListing/NoDataShow';
import Status from 'components/DiamondListing/Status';
import InlineList from 'components/List/InlineList';
import { ListItemTitle, LiveStatus } from 'components/List/ListItem';
import ListItemBody from 'components/List/ListItemBody';
import { formatDiamondList } from 'components/List/_listUtils';
import PrintMemo from 'components/PrintMemo';
import HoldDetailsCell from 'components/Transaction/Hold/HoldDetailsCell';
import MemoDetailsCell from 'components/Transaction/Hold/MemoDetailsCell';
import OfferDetailsCell from 'components/Transaction/Offer/OfferDetailsCell';
import OrderDetailsCell from 'components/Transaction/Order/OrderDetailsCell';

import { deleteSearch } from 'services/SearchApi';

import { asyncHandler } from 'util/asyncHandler';
import { history } from 'util/history';
import { classNames, isArray, isEmpty, isFunction, isNotEmpty, isString } from 'util/utils';

import { FILE_URLS, LAB_LINKS, TRACK_TYPES } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import TableGrouping from './TableGrouping';
import { FILTER_COLUMNS, FLOAT_COLUMNS, ROUND_COLUMNS, TOTAL_COLUMNS } from './table-config';
import { updateList, filterList, memoizedGetGroupTitle } from './table-utils';

import iconHistory from 'assets/svg/InventoryResult/history.svg';
import sortingLightSvg from 'assets/svg/InventoryResult/sorting-light.svg';
import iconCamera from 'assets/svg/camera.svg';
import iconCert from 'assets/svg/certi.svg';
import iconVideo from 'assets/svg/video.svg';

export { DISPLAY_TOTAL, FILTER_COLUMNS, LIMIT } from './TableBack';
export { canSortCommon, floatkeys, roundkeys } from './TableBack';

export const NoDataFound = ({ loading, length }) =>
  loading ? <NoDataShow message={<CircularProgress />} /> : length === 0 ? <NoDataShow /> : <></>;

const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
      fontWeight: type === 'cell' && ['dna'].includes(item.id) ? '600' : '',
      color: type === 'cell' && (['shpNm', 'lbNm', 'rptNo'].includes(item.id) || item.link) ? '#008cba' : '',
      textDecoration: type == 'cell' && item.id == 'vStnId' && 'underline',
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

const TrRow = React.memo((props) => {
  const { firstTdStyle, secondTdStyle, thirdTdStyle } = props.sticky;
  const row = props.row;
  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) return;
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // move row
      const r1 = props.data[dragIndex];
      if (props.data[dragIndex]?.sortingSequence) {
        const b = props.data[dragIndex]?.sortingSequence;
        props.data[dragIndex].sortingSequence = props.data[hoverIndex].sortingSequence;
        props.data[hoverIndex].sortingSequence = b;
      }
      let newlist = _cloneDeep(props.data);
      newlist = [...newlist.slice(0, dragIndex), ...newlist.slice(dragIndex + 1, newlist.length)];
      newlist = [...newlist.slice(0, hoverIndex), r1, ...newlist.slice(hoverIndex, newlist.length)];
      if (props.moverow) props.moverow(newlist);
      // move row
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  const opacity = isDragging ? 0 : 1;
  preview(drop(dropRef));
  drag(dragRef);

  return (
    <>
      {props.dragDrop && (
        <div className="tableSortingTd" ref={dragRef}>
          <img src={sortingLightSvg} />
        </div>
      )}
      <tr
        {...row.getRowProps()}
        ref={dropRef}
        style={{ opacity }}
        className={_find(props.checked, { id: row.original.id }) ? 'checked' : ''}
      >
        {row.cells.map((cell, index) => (
          <td
            key={index}
            {...cell.getCellProps(cellProps)}
            style={
              cell.column.id === 'action'
                ? firstTdStyle
                : cell.column.id === 'vStnId'
                  ? { ...secondTdStyle, ...cell.getCellProps(cellProps)?.style }
                  : cell.column.id === 'Details'
                    ? thirdTdStyle
                    : { ...cell.getCellProps(cellProps)?.style }
            }
            onClick={() => {
              if (cell?.column?.noCheck || props.nocheck) return;

              if (cell.column.link && typeof cell.column.link === 'string') {
                let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
                field = field.slice(0, field.indexOf('$'));
                const link = cell.column.link.replace('$' + field + '$', row.original[field]);
                window.open(link);
              } else if (['dna', 'shpNm', 'vStnId'].includes(cell.column?.id)) {
                props.openDrawer({ visible: row.original });
              } else if (!['quote', 'hours', 'Details', 'note', 'rptNo', 'lbNm'].includes(cell.column.id)) {
                let checked = [...(isArray(props?.checked) ? props?.checked : [])];
                const selectKey = row.original.selectionKey ? 'selectionKey' : 'stoneId';
                if (_find(checked, { [selectKey]: row.original[selectKey] })) {
                  checked = checked.filter((el) => el[selectKey] !== row.original[selectKey]);
                } else {
                  checked.push(row.original);
                }
                if (row.original.multipleSelect) {
                  checked = [...(isArray(props?.checked) ? props?.checked : [])];
                  checked = _find(checked, { selectionKey: row.original['selectionKey'] })
                    ? checked.filter((el) => el['selectionKey'] !== row.original['selectionKey'])
                    : [...checked, ...props.data.filter((el) => el['selectionKey'] === row.original['selectionKey'])];
                }
                if (isFunction(props.handleCheck)) props.handleCheck(filterList([...checked]));
              } else if (
                cell.column.id === 'lbNm' ||
                (cell.column.id === 'rptNo' && row.original.lbNm && row.original.rptNo)
              ) {
                LAB_LINKS[row.original.lbNm.toUpperCase()] &&
                  window.open(LAB_LINKS[row.original.lbNm.toUpperCase()].replace('***', row.original.rptNo));
              }
            }}
          >
            {isFunction(props.overrideColumns?.[cell.column.id]) ? (
              props.overrideColumns?.[cell.column.id]?.({ row, cell })
            ) : cell.column.id === 'Details' ? (
              <Resource
                row={row}
                sgiTab={props?.sgiRejectTab}
                setDetail={props.setDetail}
                setLoader={props.setLoader}
                extraDetails={props.extraDetails}
                inBlockDiamonds={props.inBlockDiamonds}
              />
            ) : (
              cell.render('Cell')
            )}
          </td>
        ))}
      </tr>
      {props.gobalInventory && props.index === props.inventoryIndex && (
        <td colSpan="10" className="tableInTableBoxWrapper">
          {!isEmpty(props?.gobalInventoryData?.[row.original.id]) ? (
            Object.entries(props?.gobalInventoryData).map(([, searchList]) => {
              const formattedList = formatDiamondList(searchList);
              return (
                isArray(formattedList) &&
                formattedList.map((item, index) => (
                  <div key={index} className="tableinSavedSearch">
                    <ListItemBody
                      key={index}
                      header={{
                        title: <ListItemTitle title={item.title} secondaryTitle={item?.subTitle} />,
                        info: (
                          <InlineList
                            skipEmpty
                            defaultStyle
                            separator="&nbsp;|&nbsp;"
                            list={[
                              !isEmpty(item?.account?.companyName) ? (
                                <div className="customerName">
                                  {item?.account?.companyName}
                                  <LiveStatus status={item?.account?.isActive} />
                                </div>
                              ) : null,
                              item?.user?.firstName,
                              item?.user?.mobile,
                              item?.user?.email,
                            ]}
                          />
                        ),
                      }}
                      body={
                        <InlineList
                          list={item?.properties}
                          parentClassName="savedSearchValue"
                          labelClassName="savedSearchLabel"
                          separator={<span className="separator"></span>}
                        />
                      }
                      entityName="Platform Detail Search"
                      onEdit={() => {
                        history.push(`${PREFIX_URL_WITH_SLASH}/inventory/single-stone/search`, {
                          searchId: props.row.original.searchId,
                          searchData: props.row.original.searchData,
                          searchKey: item.id,
                          platform: props.row.original.id,
                        });
                      }}
                      onDelete={() => (
                        deleteSearch(item?.id),
                        props.updateDetail(props.row.original.id, {
                          searchData: props.row.original.searchData.filter((item) => item.searchId != item?.id),
                          searchId: props.row.original.searchId.filter((item) => item != item?.id),
                        })
                      )}
                    />
                  </div>
                ))
              );
            })
          ) : (
            <NoDataShow message="Platform search not exist" />
          )}
        </td>
      )}
    </>
  );
});

const ActionCell = React.memo(({ row, bestBuyInitSts, ...props }) => {
  const checked = React.useMemo(() => {
    const selectionKey = row.original.selectionKey ? 'selectionKey' : 'stoneId';
    return !isEmpty(
      _find(props.checked, {
        [selectionKey]: row.original[selectionKey],
      }),
    );
  }, [props.checked, row.original]);

  const newRow = {
    ...row.original,
    wSts: row.original.wSts == "A" && props.unavailable?.has(row.original.id) ? "UN" : row.original.wSts
  }
  return (
    <div className="selectActionIcon">
      {!props.nostatus && <Status row={newRow} bestBuyInitSts={bestBuyInitSts} />}
      <div className="selectActionIconWrapper">{!props.noCheckBox && <Checkbox checked={checked} />}</div>
    </div>
  );
});

const ReactTable = React.memo((props) => {
  const {
    columns = [],
    data = [],
    styles = {},
    filterObj = {},
    allCheck,
    partialCheck,
    selectedFilterObj = {},
  } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  if (props?.loading) return null;

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th
                key={column.id ?? index}
                {...column.getHeaderProps({
                  ...headerProps,
                  className: classNames([
                    (FLOAT_COLUMNS.includes(column?.id) || ROUND_COLUMNS.includes(column?.id)) && 'number-header',
                  ]),
                })}
                style={
                  column.id === 'action'
                    ? styles.firstThStyle
                    : column.id === 'vStnId'
                      ? styles.secondThStyle
                      : column.id === 'Details'
                        ? styles.thirdThStyle
                        : {}
                }
                className={selectedFilterObj[column.id] && selectedFilterObj[column.id].length ? 'filterApply' : ''}
              >
                {column.id !== 'action' && (
                  <DiamondListingHead
                    {...props}
                    header={column.render('Header')}
                    column={column}
                    selectFilter={props.selectFilter}
                    sortClick={props.handleSort}
                    filterArray={filterObj}
                    selectedFilterArray={selectedFilterObj}
                  />
                )}
                {column.id === 'action' && !props.noheader && (
                  <div className="selectActionIcon">
                    <div className="selectActionIconWrapper">
                      {!props.nostatus && <Status />}
                      {!props.noCheckBox && (
                        <Checkbox
                          checked={allCheck}
                          indeterminate={partialCheck}
                          onChange={() => props.handleCheck(filterList(allCheck ? [] : data))}
                        />
                      )}
                    </div>
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        <DndProvider backend={HTML5Backend}>
          {!props.loading &&
            rows.map((row, index) => {
              prepareRow(row);
              const { isHeader, isHoldHeader, isMatchHeader, userGroupHold } = row.original;
              const filterIsEmpty = isEmpty(selectedFilterObj);
              return (
                <React.Fragment key={row.original.id ?? index}>
                  {filterIsEmpty && isHeader && isHoldHeader && (
                    <TableGrouping
                      row={row.original}
                      groupingTitle={row.original.blockCode}
                      checkbox={!isEmpty(selectedFilterObj) || !props.noCheckBox}
                      isChecked={props.isGroupChecked(row.original._groupKey_, data)}
                      onCheck={props.handleGroupCheck(row.original._groupKey_)}
                      onUncheck={props.handleGroupUncheck(row.original._groupKey_)}
                    />
                  )}
                  {filterIsEmpty && isHeader && isMatchHeader && (
                    <TableGrouping
                      row={row.original}
                      columns={columns}
                      groupingTitle={row.original.groupName}
                      totalDiamonds={row.original.totalDiamonds}
                      displayTotal={TOTAL_COLUMNS.filter(
                        (id) => isArray(columns) && !isEmpty(columns.find((col) => col?.id === id)),
                      )}
                      checkbox={!isEmpty(selectedFilterObj) || !props.noCheckBox}
                      isChecked={props.isGroupChecked(row.original._groupKey_, data)}
                      onCheck={props.handleGroupCheck(row.original._groupKey_)}
                      onUncheck={props.handleGroupUncheck(row.original._groupKey_)}
                    />
                  )}
                  {filterIsEmpty && isHeader && userGroupHold && (
                    <TableGrouping
                      row={row.original}
                      columns={columns}
                      groupingTitle={props.getGroupTitle(row.original, {
                        user: props.customerList ?? props.sortUser,
                        date: props.dateList ?? props.sortDate,
                        country: props.sortCountry,
                        salesPerson: props.sortSalesPerson,
                      })}
                      totalDiamonds={row.original.totalDiamonds}
                      displayTotal={TOTAL_COLUMNS.filter(
                        (id) => isArray(columns) && !isEmpty(columns.find((col) => col?.id === id)),
                      )}
                      checkbox={!isEmpty(selectedFilterObj) || !props.noCheckBox}
                      isChecked={props.isGroupChecked(row.original._groupKey_, data)}
                      onCheck={props.handleGroupCheck(row.original._groupKey_)}
                      onUncheck={props.handleGroupUncheck(row.original._groupKey_)}
                    />
                  )}
                  <TrRow
                    {...props}
                    row={row}
                    index={row.index}
                    openDrawer={props.openDrawer}
                    setDetail={props.setDetail}
                    sticky={{
                      firstTdStyle: styles.firstTdStyle,
                      secondTdStyle: styles.secondTdStyle,
                      thirdTdStyle: styles.thirdTdStyle,
                    }}
                    setLoader={props.setLoader}
                  />
                </React.Fragment>
              );
            })}
        </DndProvider>
      </tbody>
    </table>
  );
});

class Table extends React.Component {
  state = {
    visible: false,
    detail: null,
    loader: false,
    selectedFilterArray: {},

    data: [...(this.props.data ?? [])],
    columns: [...(this.props.columns ?? [])],
    filterObj: {},
    allCheck: false,
    partialCheck: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ selectedFilterArray: {} });
      if (isFunction(this.props.handleCheck)) this.props.handleCheck([]);
    }

    if (
      !this.props.nocheck &&
      !this.props.noCheckBox &&
      prevState.selectedFilterArray !== this.state.selectedFilterArray
    ) {
      if (isFunction(this.props.handleCheck)) this.props.handleCheck([]);
    }

    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: [...this.props.columns] ?? [] });
    }
  }

  handleSort = (id) => {
    let sort = _cloneDeep(this.props.sort);
    let found = false;
    _each(sort, (val) => {
      if (Object.prototype.hasOwnProperty.call(val, id)) found = val;
    });
    if (!found) sort.push({ [id]: 'ASC' });
    else {
      const index = sort.indexOf(found);
      if (found[id] === 'DESC') {
        sort = sort.filter((el) => !Object.prototype.hasOwnProperty.call(el, id));
      } else {
        found[id] = 'DESC';
        sort[index] = found;
      }
    }
    this.props.handleSort(sort);
  };

  selectFilter = (x, colId, remove = false) => {
    let selection = _cloneDeep(this.state.selectedFilterArray);
    if (!remove) {
      if (selection[colId]) selection[colId].push(x);
      else selection[colId] = [x];
    } else {
      selection[colId] = selection[colId].filter((el) => el !== x);
      if (!selection[colId].length) selection = _omit(selection, colId);
    }
    this.setState({ selectedFilterArray: selection });
  };

  getGroupTitle = (record, options) =>
    memoizedGetGroupTitle(record, { ...options, orderShow: this.props?.orderShow, stageShow: this.props?.stageShow });

  addDefaultSrc = () => this.setState({ detail: { i: '' } });

  handleGroupCheck = (groupKey) => () =>
    asyncHandler(() => {
      const { checked = [], data: list, handleCheck } = this.props;
      if (!isFunction(handleCheck) || !isArray(list) || isEmpty(groupKey)) return;
      const checkedIds = checked?.map((rec) => rec?.selectionKey)?.filter(isNotEmpty);
      const newChecked = list?.filter(
        (rec) => rec?._groupKey_ === groupKey && !checkedIds?.includes(rec?.selectionKey),
      );
      if (isEmpty(newChecked)) return;
      handleCheck(filterList([...checked, ...newChecked]));
    });

  handleGroupUncheck = (groupKey) => () =>
    asyncHandler(() => {
      const { checked = [], data: list, handleCheck } = this.props;
      if (!isFunction(handleCheck) || !isArray(list) || isEmpty(groupKey)) return;
      const newChecked = checked?.filter((rec) => rec?._groupKey_ !== groupKey);
      handleCheck(filterList([...newChecked]));
    });

  isGroupChecked = (groupKey, list) => {
    const { checked = [] } = this.props;
    if (!isArray(list) || !isArray(checked) || isEmpty(groupKey)) return false;
    const checkList = checked.filter((rec) => rec?._groupKey_ === groupKey); // all records in the group
    if (checkList?.length < 1) return false; // if checked < 1 uncheched
    const allList = list.filter((rec) => rec?._groupKey_ === groupKey); // checked records in the group
    if (checkList?.length === allList?.length) return true; // if same checked
    if (checkList?.length < allList?.length) return 'partial'; // if checked > 0 < total patial checked
  };

  actionColumn = {
    Header: '',
    width: '50px',
    accessor: 'action',
    id: 'action',
    Cell: ({ row }) => (
      <ActionCell
        nostatus={this.props.nostatus}
        noCheckBox={this.props.noCheckBox}
        checked={this.props.checked}
        bestBuyInitSts={this.props.bestBuyInitSts}
        row={row}
        unavailable={this.props.unavailableDiamonds}
      />
    ),
  };

  static getDerivedStateFromProps(props, state) {
    let styles = {};
    styles.posStick = { position: 'sticky' };
    styles.headerStick = { ...styles.posStick, zIndex: 2000 };
    styles.trStick = { ...styles.posStick, backgroundColor: '#fff', zIndex: 1000 };
    styles.firstLeft = -10;
    styles.firstWidth = props.nostatus ? 40 : 140;
    styles.secondLeft = styles.firstWidth + styles.firstLeft - 5;
    styles.secondWidth = 160;
    styles.thirdLeft = styles.firstWidth + styles.secondWidth + styles.firstLeft - 10;
    styles.firstThStyle = { left: styles.firstLeft + 'px', ...styles.headerStick, minWidth: styles.firstWidth + 'px' };
    styles.firstTdStyle = { left: styles.firstLeft + 'px', ...styles.trStick, minWidth: styles.firstWidth + 'px' };
    styles.secondThStyle = {
      left: styles.secondLeft + 'px',
      ...styles.headerStick,
      minWidth: styles.secondWidth + 'px',
    };
    styles.secondTdStyle = { left: styles.secondLeft + 'px', ...styles.trStick, minWidth: styles.secondWidth + 'px' };
    styles.thirdThStyle = { left: styles.thirdLeft + 'px', ...styles.headerStick };
    styles.thirdTdStyle = { left: styles.thirdLeft + 'px', ...styles.trStick };

    if (!isEmpty(state.styles) && JSON.stringify(state.styles) === JSON.stringify(styles)) styles = state.styles;

    const inBlockDiamonds = props?.inBlockDiamonds || [];

    const unavailableDiamonds = new Set(inBlockDiamonds.flatMap(x => x.blockDetails).map(x => x.diamond))


    const selectedFilter = state.selectedFilterArray || {};

    let data = isArray(props.data) ? props.data : [];
    if (isArray(data)) {
      Object.entries(selectedFilter).forEach(([key, value]) => {
        if (isArray(value)) data = data.filter((record) => value.includes(record?.[key]));
      });
    }

    updateList(data, { inBlockList: inBlockDiamonds, ...props });

    const allCheck =
      !props.nocheck &&
      !props.noCheckBox &&
      !isEmpty(data) &&
      data?.length === props.checked?.length &&
      JSON.stringify(props.checked.map((record) => record?.selectionKey ?? record?.stoneId).sort()) ===
      JSON.stringify(data.map((record) => record?.selectionKey ?? record?.stoneId).sort());

    const partialCheck = !allCheck && props.checked?.length > 0;

    const filterObj = {};
    if (isArray(props.data) && props.FilterOption !== false) {
      FILTER_COLUMNS.forEach((column) => {
        filterObj[column] = _uniq(props.data.map((record) => record?.[column])).filter((el) => el && el);
      });
    }

    return { styles, data, inBlockDiamonds, selectedFilter, allCheck, partialCheck, filterObj, unavailableDiamonds };
  }

  openDrawer = (e) => this.setState(e);
  setDetail = (detail) => this.setState({ detail });
  setLoader = (loader) => this.setState({ loader });

  render() {
    const { columns } = this.state;
    if (!this.props.nocheck && columns.findIndex((column) => column?.id === 'action') < 0) {
      columns.unshift(this.actionColumn);
    }

    return (
      <>
        {(this.state.loader || this.props.loader) && <CircularProgress className="fixLoader" />}
        <ReactTable
          {...this.props}
          columns={columns}
          data={this.state.data}
          styles={this.state.styles}
          allCheck={this.state.allCheck}
          partialCheck={this.state.partialCheck}
          selectedFilterObj={this.state.selectedFilterArray}
          filterObj={this.state.filterObj}
          getGroupTitle={this.getGroupTitle}
          handleGroupCheck={this.handleGroupCheck}
          handleGroupUncheck={this.handleGroupUncheck}
          handleSort={this.handleSort}
          isGroupChecked={this.isGroupChecked}
          selectFilter={this.selectFilter}
          openDrawer={this.openDrawer}
          setDetail={this.setDetail}
          setLoader={this.setLoader}
        />
        <NoDataFound loading={this.props.loading} length={this.props?.data?.length} />
        <Drawer
          onClose={() => this.setState({ visible: false })}
          wrapClassName="diamondDetailPopup"
          visible={Boolean(this.state.visible)}
          destroyOnClose
        >
          <DiamondDetail
            tabPrintExcel={this.props?.tabPrintExcel}
            columns={this.state.columns}
            data={this.state.visible}
            inDiamondPopup
          />
        </Drawer>
        <Drawer
          onClose={() => this.setState({ detail: null })}
          visible={this.state.detail}
          wrapClassName="diamondListinSidebar onlyImageSlider"
          destroyOnClose
        >
          {this.state?.detail?.i && (
            <DiamondSliderImage
              image1={FILE_URLS.img.replace('***', this.state.detail.i?.mfgStnId)}
              image2={FILE_URLS.lightBlack.replace('***', this.state.detail.i?.mfgStnId)}
            />
          )}
          {this.state?.detail?.v && (
            <IFrame
              video
              tempSrc={iconVideo}
              src={FILE_URLS.videoFile.split('***').join(this.state.detail.v?.mfgStnId)}
            />
          )}
          {this.state?.detail?.c && (
            <Embed
              src={FILE_URLS.certFile.replace('***', this.state.detail.c?.rptNo)}
              height="100%"
              width="100%"
              hideOnError
              checkHeaders
            >
              <NotAvailable className="not-found" src={iconCert} type="Certificate" />
            </Embed>
          )}
        </Drawer>
      </>
    );
  }
}

const Resource = React.memo((props) => {
  const { row, setDetail, inBlockDiamonds = [], sgiTab, setLoader } = props;

  const extraDetails = React.useMemo(() => {
    const extraDetails = props?.extraDetails ?? [];
    return isString(extraDetails) ? extraDetails.replace(' ', '').split(',') : extraDetails;
  }, [props?.extraDetails]);

  const clickHandler = React.useCallback(
    (detailKey) => () => {
      setLoader(false);
      setDetail({ [detailKey]: row.original });
    },
    [setLoader, setDetail, row.original],
  );

  const offerCount = React.useMemo(() => {
    if (!(extraDetails === 'offerHistoryData' || extraDetails.includes('offerHistoryData'))) return;
    return (
      row.original.tracks?.[0]?.data ?? row.original.tracks?.find((track) => track?._id === TRACK_TYPES.Offer)?.data
    );
  }, [extraDetails, row.original.tracks]);

  const gotoOfferHistory = React.useCallback(() => {
    window.open(`${PREFIX_URL_WITH_SLASH}/inventory/single-stone/offer-history?id=${row.original.id}`);
  }, [row.original.id]);

  const detailParamList = React.useMemo(() => {
    const array = isString(extraDetails) ? extraDetails.split(',') : isArray(extraDetails) ? extraDetails : [];
    return array.map((str) => `${str}`.toUpperCase());
  }, [extraDetails]);

  return (
    <div className="d-flex width-max-content tableSmallImage">
      <img title="View Image" src={iconCamera} width="15px" alt="" onClick={clickHandler('i')} />
      <img title="View Video" src={iconVideo} width="15px" alt="" onClick={clickHandler('v')} />
      <img title="View Certificate" src={iconCert} width="15px" alt="" onClick={clickHandler('c')} />
      {(detailParamList.includes('MEMO') || row.original.wSts === 'M' || row.original.bestBuyMemo) &&
        !['Order', 'Offer', 'OrderPending'].includes(extraDetails) &&
        !sgiTab && (
          <>
            <MemoDetailsCell row={row} extraDetails={extraDetails} inBlockDiamonds={inBlockDiamonds} />
          </>
        )}
      {(detailParamList.includes('HOLD') || row.original.wSts === 'H') &&
        !['Order', 'Offer', 'OrderPending'].includes(extraDetails) &&
        !sgiTab && <HoldDetailsCell row={row} extraDetails={extraDetails} inBlockDiamonds={inBlockDiamonds} />}
      {detailParamList.includes('ORDER') && <OrderDetailsCell row={row} />}
      {detailParamList.includes('OFFER') && <OfferDetailsCell row={row} />}
      {detailParamList.includes('PRINT_MEMO') && <PrintMemo row={row} />}
      {offerCount && (
        <div className="position-relative">
          <div>
            <img title="View Offer history" src={iconHistory} alt="" width="15px" onClick={gotoOfferHistory} />
            <div className="offerCount">{offerCount}</div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Table;
