import React from 'react';
import { connect } from 'react-redux';

import { Checkbox } from 'antd';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import { showNotification } from 'services/NotificationService';
import * as SellerAPI from 'services/SellerApi';
import * as UserAPI from 'services/UserApi';

import { history } from 'util/history';
import { isArray, isEmpty, isFunction } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import OpenNotification from 'constants/OpenNotifications';

import { TabActions } from 'appRedux/reducers/TabIssueReducer/TabReducer';
import { UserActions } from 'appRedux/reducers/User';

import BottomStickyButton from '../../BottomStickButton';

export const user = {
  usertype: [{ value: 'Sub User' }],
};

class User extends React.Component {
  state = {
    users: [],
    sellers: [],
  };

  getSalesPerson = async () => {
    const [err, res] = await SellerAPI.getSellerList();
    const success = !err && res?.code === 'OK';

    if (success && isArray(res.data)) {
      this.setState({
        sellers: res.data.map(({ id, firstName, lastName }) => ({
          key: id,
          value: `${firstName}${lastName !== '_' ? ' ' + lastName : ''}`,
        })),
      });
    }
  };

  fields = [
    {
      name: 'users',
      label: 'User Details',
      type: 'array',
      fields: [
        { name: 'firstName', type: 'text', label: 'First Name', initialValue: '', required: true },
        { name: 'lastName', type: 'text', label: 'Last Name', initialValue: '' },
        {
          name: 'type',
          type: 'select',
          label: 'Selete User Type',
          options: () =>
            Object.entries(USERS_TYPE)
              .filter((item) => item[0] == 'PRIMARY' || item[0] == 'SECONDARY' || item[0] == 'API_USER')
              .map(([value, key]) => ({ key, value: value.replace('_', ' ') })),
          initialValue: isEmpty(this.props?.user?.editUser) ? USERS_TYPE.PRIMARY : undefined,
          required: true,
        },

        {
          name: 'email',
          type: 'text',
          label: 'Email',
          initialValue: isEmpty(this.props?.user?.editUser) ? this.props?.account?.['emails']?.[0] : '',
          required: true,
        },
        {
          name: 'mobile',
          type: 'text',
          label: 'Mobile No',
          initialValue: isEmpty(this.props?.user?.editUser) ? this.props?.account?.['mobiles']?.[0] : '',
          required: true,
        },
        { type: 'show' },
        {
          name: 'username',
          type: 'text',
          label: 'Username',
          initialValue: isEmpty(this.props?.user?.editUser) ? this.props?.account?.['emails']?.[0] : '',
          required: true,
        },
        {
          name: 'password',
          type: 'password',
          label: 'Password',
          initialValue: '',
          required: isEmpty(this.props?.user?.editUser) ? false : true,
        },
        {
          name: 'confirm_password',
          type: 'password',
          label: 'Confirm Password',
          initialValue: '',
          required: isEmpty(this.props?.user?.editUser) ? false : true,
        },
        { name: 'whatsapp', type: 'text', label: 'Whatsapp', initialValue: '' },
        { name: 'skype', type: 'text', label: 'Skype', initialValue: '' },
        { name: 'wechat', type: 'text', label: 'Wechat', initialValue: '' },
        {
          name: 'isEmailVerified',
          type: 'bool',
          label: 'Email Verified',
          initialValue: false,
          required: false,
        },
        // {
        //   name: 'seller',
        //   type: 'select',
        //   label: 'Selete Seller',
        //   options: this.state.sellers,
        //   initialValue: undefined,
        //   required: false,
        // },
      ],
    },
  ];

  getInitialValues = () => {
    let data = {};
    if (!isEmpty(this.props?.user?.editUser)) {
      data = { users: [this.props?.user?.editUser] };
    } else {
      data = { users: [...(this.state.users || 0)] };
    }

    return Object.fromEntries(
      this.fields.map(({ type, fields, name, initialValue }) =>
        ['array'].includes(type) && isEmpty(data?.[name])
          ? [name, [Object.fromEntries(fields.map(({ name, initialValue }) => [name, initialValue]))]]
          : [name, isEmpty(data?.[name]) ? initialValue : data[name]],
      ),
    );
  };

  validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required('Please enter firstname'),
        lastName: Yup.string().trim(),
        type: Yup.number()
          .oneOf([USERS_TYPE.PRIMARY, USERS_TYPE.SECONDARY, USERS_TYPE.API_USER], 'Invalid Type')
          .required('Please select type'),
        email: Yup.string().trim().required('Please enter email'),
        mobile: Yup.string()
          .matches(/^[0-9]{7,15}$/, 'Phone number length should between 7-15 and contains only number')
          .required('Please enter mobile'),
        username: Yup.string().required('Please enter username'),
        password: Yup.string()?.nullable(),
        confirm_password: Yup.string()
          .test('passwords-match', 'Confirm Password must be same as password', function (value) {
            return this.parent.password === value;
          })
          ?.nullable(),
      }),
    ),
  });

  validationEditSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required('Firstname is required'),
        lastName: Yup.string().trim().required('Lastname is required'),
        type: Yup.number()
          .oneOf([USERS_TYPE.PRIMARY, USERS_TYPE.SECONDARY, USERS_TYPE.API_USER], 'Invalid Type')
          .required('Type is required'),
        email: Yup.string().trim().required('Email is required'),
        mobile: Yup.string()
          .matches(/^[0-9]{7,15}$/, 'Mobile number length should between 7-15 and contains only number')
          .required('Mobile is required'),
        username: Yup.string().required('Username is required'),
        confirm_password: Yup.string().test(
          'passwords-match',
          'Confirm Password must be same as password',
          function (value) {
            return this.parent.password === value;
          },
        ),
      }),
    ),
  });

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account } = this.props;

    values.users = values.users.map((user) => {
      user.password = isEmpty(this.props?.user?.editUser)
        ? !isEmpty(user.password)
          ? user.password
          : user.email
        : user.password;
      user.confirm_password = isEmpty(this.props?.user?.editUser)
        ? !isEmpty(user.confirm_password)
          ? user.confirm_password
          : user.email
        : user.confirm_password;
      return {
        account: account?.id,
        ...user,
      };
    });

    const users = values.users.map((item) => {
      item.type = Number(item.type);
      return {
        id: item.id,
        account: item.account,
        firstName: item.firstName,
        lastName: item.lastName,
        type: item.type,
        email: item.email,
        mobile: item.mobile,
        username: item.username,
        password: item.password,
        whatsapp: item.whatsapp,
        skype: item.skype,
        wechat: item.wechat,
        seller: item.seller,
        isEmailVerified: item?.isEmailVerified,
      };
    });

    setSubmitting(true);
    const [err, res] = await (isEmpty(this.props?.user?.editUser)
      ? UserAPI.createMany({ ...values })
      : UserAPI.updateUserDetail(users[0]?.id, users[0]));
    setSubmitting(false);

    if (res?.code === 'OK' && isEmpty(res?.data?.failed)) {
      !err && showNotification(res);
      // if (!isEmpty(this.props?.user?.editUser) && isFunction(this.props?.setEditUser)) this.props.setEditUser();
      this.props.getUsersList(account?.id);
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        isFunction(this.props?.onCancel) && this.props.onCancel();
        history.push(`${PREFIX_URL_WITH_SLASH}/client/kyc/basic`, { fromUserTab: true });
      }
      const editUser = this.props?.user?.editUser;
      if (!isEmpty(editUser) && res?.data?.[0].type == USERS_TYPE.PRIMARY) {
        this.accountStoreUpdate(account.id, res?.data?.[0]);
      } else if (editUser.type == USERS_TYPE.PRIMARY && res?.data?.[0].type != USERS_TYPE.PRIMARY) {
        this.accountStoreUpdate(account.id);
      } else if (!isEmpty(res?.data?.created)) {
        res.data.created.map((item) => {
          if (item.type == USERS_TYPE.PRIMARY) {
            this.accountStoreUpdate(account.id, item);
          }
        });
      }
    } else if (!isEmpty(res?.data?.failed)) {
      res.data.failed.map((item) => {
        OpenNotification({ type: 'error', title: item?.error?.message });
      });
    }
  };

  accountStoreUpdate = (accountId, user = null) => {
    let list = this.props?.clientData?.clientList;
    list = list?.map((item) => {
      if (accountId == item?.id && user) {
        item.user = user;
      } else if (accountId == item?.id && !user) {
        item.user = {};
      }
      return item;
    });
    this.props.setClientList({ ...{ clientList: list } });
  };

  cancelForm = () => {
    if (!isEmpty(this.props?.user?.editUser) && isFunction(this.props?.setEditUser)) this.props.setEditUser();
    this.props.onCancel();
  };

  componentDidMount() {
    this.getSalesPerson();
  }

  render() {
    const initialValues = this.getInitialValues();

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={isEmpty(this.props?.user?.editUser) ? this.validationSchema : this.validationEditSchema}
        validateOnChange
        onSubmit={this.submitForm}
      >
        {({ handleChange, handleBlur, setFieldValue, setFieldTouched, submitForm, touched, errors, values }) => (
          <Form>
            <div className="kycFormMainBlock">
              <Heading className="smallTitle p-0 mb-20" title="User Details"></Heading>
              {this.fields.map(
                (field) =>
                  field.type === 'array' &&
                  values[field.name].map((item, index) => (
                    <React.Fragment key={index}>
                      <FieldArray
                        name={field.name}
                        render={(fieldArrayHelpers) => (
                          <>
                            <div className="kycBlockWrapper formMainSubBlock mb-20 pb-0">
                              {field.fields.map(({ name, type, label, options, required, props }) => {
                                const namePrefix = `${field.name}.${index}.`;
                                const htmlName = `${namePrefix}${name}`;

                                if (
                                  !isEmpty(this.props?.user?.editUser) &&
                                  (name == 'password' || name == 'confirm_password')
                                )
                                  return;

                                let selectValue = [];
                                if (isFunction(options)) {
                                  switch (name) {
                                    case 'stateId':
                                      selectValue = options()?.[values?.[field.name]?.[index]?.['countryId']] || [];
                                      break;

                                    case 'cityId':
                                      selectValue = options()?.[values?.[field.name]?.[index]?.['stateId']] || [];
                                      break;

                                    default:
                                      selectValue = options();
                                      break;
                                  }
                                }

                                return type === 'show' ? (
                                  <div key={index} className="from-group"></div>
                                ) : type === 'select' ? (
                                  <SelectOption
                                    {...{ name: htmlName, ...props }}
                                    label={required ? label + ' *' : label}
                                    placeholder={label}
                                    value={
                                      isEmpty(values[field.name]?.[index]?.[name])
                                        ? undefined
                                        : `${values[field.name]?.[index]?.[name]}`
                                    }
                                    selectValue={name === 'seller' ? this.state.sellers : selectValue}
                                    onChange={(value) => {
                                      if ([`countryId`].includes(name)) {
                                        setFieldValue(`${namePrefix}stateId`, undefined);
                                        setFieldValue(`${namePrefix}cityId`, undefined);
                                        this.getStates(value);
                                      }
                                      if ([`stateId`].includes(name)) {
                                        setFieldValue(`${namePrefix}cityId`, undefined);
                                        this.getCities(value);
                                      }
                                      setFieldValue(htmlName, value);
                                    }}
                                    onBlur={() => setFieldTouched(htmlName, true)}
                                    error={touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]}
                                    key={htmlName}
                                    showSearch
                                  />
                                ) : type === 'bool' ? (
                                  <div className="checkBoxForm">
                                    <label>{required ? label + ' *' : label}</label>
                                    <Checkbox
                                      {...{ name: htmlName, label, ...props }}
                                      onChange={() => setFieldValue(htmlName, !values[field.name]?.[index]?.[name])}
                                      onBlur={() => setFieldTouched(htmlName, true)}
                                      error={
                                        touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]
                                      }
                                      checked={values[field.name]?.[index]?.[name]}
                                      key={htmlName}
                                    />
                                  </div>
                                ) : (
                                  <InputBlock
                                    {...{ name: htmlName, type, ...props }}
                                    placeholder={label}
                                    label={required ? label + ' *' : label}
                                    onKeyPress={(evt) => {
                                      if (
                                        name == 'mobile' &&
                                        evt.which != 8 &&
                                        evt.which != 0 &&
                                        (evt.which < 48 || evt.which > 57) &&
                                        evt.which != 45
                                      ) {
                                        evt.preventDefault();
                                      }
                                    }}
                                    // onChange={handleChange}
                                    onChange={(event) => {
                                      setFieldValue(
                                        htmlName,
                                        name == 'mobile' ? event.target.value.replace(/\D/g, '') : event.target.value,
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    error={touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]}
                                    value={values[field.name]?.[index]?.[name]}
                                    key={htmlName}
                                  />
                                );
                              })}
                            </div>
                            {/* {isEmpty(this.props?.user?.editUser) && (
                              <div className="kycNewBlockAdd">
                                <button
                                  className="commonButton"
                                  type="button"
                                  role="button"
                                  onClick={() => fieldArrayHelpers.push(initialValues[field.name][0])}
                                >
                                  Add
                                </button>
                                {values?.['users']?.length > 1 && (
                                  <button
                                    className="commonButton ml-5"
                                    type="button"
                                    role="button"
                                    onClick={() => values[field.name]?.length > 1 && fieldArrayHelpers.remove(index)}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            )} */}
                          </>
                        )}
                      />
                    </React.Fragment>
                  )),
              )}
            </div>
            <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                Save & Continue
              </button>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: false }, submitForm)}
              >
                Save
              </button>
              <button className="commonOutine" type="reset" onClick={this.cancelForm}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(
  ({ user, tab }) => ({
    user,
    clientData: tab.clientList,
  }),
  {
    getUsersList: UserActions.getUsersList,
    setClientList: TabActions.setClientList,
  },
)(User);
