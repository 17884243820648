export const PERMISSION = [
  {
    module: 'dashboard',
    title: 'Dashboard',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'searchDiamond',
    title: 'Search Diamond',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },

  {
    module: 'quickSearch',
    title: 'Quick Search',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'fancySearch',
    title: 'Fancy Search',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'mySavedSearch',
    title: 'My Saved Search',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'myDemand',
    title: 'My Demand',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'searchResult',
    title: 'Search Result',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'compare',
    title: 'Compare',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'showSelected',
    title: 'Show Selected',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'matchPair',
    title: 'Match Pair',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'iAmLucky',
    title: 'I Am Lucky',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'parcelList',
    title: 'Parcel List',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'newGoods',
    title: 'New Goods',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'bestOfFineStar',
    title: 'Best Of FineStar',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },

  {
    module: 'upcomingDiamonds',
    title: 'Upcoming Diamonds',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },

  {
    module: 'cart',
    title: 'Cart',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'reminder',
    title: 'Reminder',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'watchlist',
    title: 'Watchlist',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'comment',
    title: 'Comment',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'enquiry',
    title: 'Enquiry',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'order',
    title: 'Order',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'shipment',
    title: 'Shipment',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'transport',
    title: 'Transport',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'purchase',
    title: 'Purchase',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'hospitality',
    title: 'Hospitality',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'offlineStock',
    title: 'Offline Stock',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'pricecalculator',
    title: 'Price Calculator',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'stoneoftheday',
    title: 'Stone Of The Day',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'appointment',
    title: 'Appointment',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'diamondHistory',
    title: 'Diamond History',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'inventory',
    title: 'Inventory',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'pricing',
    title: 'Pricing',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'RoughImage',
    title: 'Rough Image',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'XRayZip',
    title: 'X Ray Zip ',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'printPdf',
    title: 'Print Pdf',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'excelDownload',
    title: 'Excel Download ',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
  {
    module: 'mail',
    title: 'Mail ',
    permissions: {
      view: false,
      insert: false,
      update: false,
      delete: false,
      uploadExcel: false,
      downloadExcel: false,
      mailExcel: false,
      printPDF: false,
    },
  },
];

export const SETTING_PAGES = [
  { action: 'cart', label: 'Cart' },
  { action: 'watchlist', label: 'Watchlist' },
  { action: 'comment', label: 'My Comment' },
  { action: 'enquiry', label: 'My Enquiry' },
  { action: 'demand', label: 'My Demand' },
  { action: 'demand-expiry', label: 'Demand Expiry' },
  { action: 'reminder', label: 'Reminder' },
  { action: 'compare', label: 'Compare' },
  { action: 'mySavedSearch', label: 'my Saved Search' },
  { action: 'diamondSearch', label: 'Diamond Search' },
  { action: 'searchResult', label: 'Search Result' },
  { action: 'showSelected', label: 'Show Selected' },
  { action: 'newGoods', label: 'New Goods' },
  { action: 'upcommingDiamond', label: 'Upcomming Diamond' },
  { action: 'searchReminder', label: 'Search Reminder' },
  { action: 'exclusive', label: 'Exclusive Stone' },
  { action: 'feature-pair', label: 'Feature Pair' },
  { action: 'feature-stone', label: 'Feature Stone' },
  { action: 'stone-of-day', label: 'Best Buy' },
  { action: 'best', label: 'Best' },
  { action: 'other', label: 'Other' },
  { action: 'offer', label: 'Offer' },
  { action: 'order-place', label: 'Order Place' },
  { action: 'Appointment', label: 'Appointment' },
  { action: 'shipment', label: 'Shipment' },
  // { action: 'order-reject', label: 'Order Reject' },
  // { action: 'order-accept', label: 'Order Accept' },
];

export const GENERAL_SETTINGS = { label: 'Place-Order' };

export const OFFLINE_SETTINGS = { label: 'Offline-Stock' };

export const EMAIL_RECEIVER_SETTINGS = { label: 'email-receiver' };

export const SETTING_TYPE = {
  OTHER_SETTING: 1,
  NOTIFICATION_SETTING: 2,
  ORDER_SETTINGS: 3,
  OFFLINE_SETTINGS: 4,
  EMAIL_RECEIVER_SETTINGS: 5,
  HOLD_SETTINGS: 22,
};
