import Axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
// import { LoaderService } from 'services/LoaderService';

import { BASE_URL } from '../constants/Common';
// import { isEmpty } from './utils';

const axios = Axios.create({
  baseURL: BASE_URL,
  adapter: cacheAdapterEnhancer(Axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

axios.interceptors.request.use(
  (config) => {
    // LoaderService.startLoading(isEmpty(config?.loader) || config?.loader);
    return config;
  },
  (error) => {
    // setTimeout(() => LoaderService.stopLoading(true), 240);
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (config) => {
    // setTimeout(() => LoaderService.stopLoading(!(isEmpty(config?.loader) || config?.loader)), 960);
    return config;
  },
  (error) => {
    // setTimeout(() => LoaderService.stopLoading(true), 480);
    return Promise.reject(error);
  },
);

export default axios;
