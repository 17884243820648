import { callApiAsync, withUrlParams } from 'services/util';

import { API_ROUTES } from 'constants/CommonApi';
import { store } from 'appRedux/store/index';

function updateRequest(request) {
  return { request: { ...request, labGrown: store.getState().commonData?.labGrown || 'N' } };
}

export const getSettings = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Setting.view, request });
};

export const updateSettings = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Setting.upsert, request });
};

export const getProjectSettings = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.projectSettings.view, request });
};

export const getSgiRawList = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.SGIStatus.check, request });
};

export const compareSgiStock = () => {
  return callApiAsync(API_ROUTES.SGIStatus.compareStock);
};

export const getSgiStatusList = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.SGIStatus.getSGIData, request });
};

export const getStoneReportNo = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.InventorySearch.paginate, ...updateRequest(request) });
};

export const printPdf = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Inventory.print, request });
};

export const exportExcel = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Inventory.exportExcel, request });
};

export const getCountries = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Country.paginate, request });
};

export const getStates = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.State.paginate, request });
};

export const getCities = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.City.paginate, request });
};

export const getBroker = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Broker.list, request });
};

export const getUserTerms = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.UserTerms.Request, request });
};

export const getOfferHistory = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.OfferHistory.paginate, request });
};

export const deleteDiamondTrack = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.DiamondTrack.delete, request });
};

export const getMatchPair = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.similarStone.paginate, request });
};

export const shippingDetail = (id = null) => {
  return callApiAsync({ ...withUrlParams(API_ROUTES.Shipping.detail, [id]) });
};

export const matchPairList = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.MatchPair.Paginate, request });
};

export const dashboardDetails = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Dashboard.Details, request });
};

export const forgotPassword = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.Auth.forgot, request });
};

export const changePassword = (request = {}) => {
  return callApiAsync({ ...API_ROUTES.password.change, request });
};
