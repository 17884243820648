import React, { Component } from 'react';

import CommonModal from 'containers/CommonModal';
import InputBlock from 'containers/InputBlock';
import TextArea from 'containers/TextArea';

import { sendEmailAttachment, sendXRayEmailAttachment } from 'services/DiamondApi';

import { formatInlineList, isEmpty, isNotEmpty, isString } from 'util/utils';
import { Notify } from 'util/notify';

import './action.less';
import { LoaderService } from 'services/LoaderService';
import { getLoginUser } from 'services/commonFunc';

class SendEmailPopup extends Component {
  state = {
    subject: this.props?.xRay ? 'X-Ray Detail' : 'Diamond Details',
    cc: '',
    email: '',
    message: `Dear Sir/Madam,
Greetings of the day from MyDiamonds Team.
Please, have a look at below ${this.props?.xRay ? 'Xray' : 'stock'} file.`,
    subjectInvalid: false,
    emailInvalid: false,
    ccInvalid: false,
  };

  handleEmailChange = (e) =>
    this.setState({ email: e.target.value }, () => {
      const { email } = this.state;
      const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const emailList = isString(email) ? formatInlineList(email).split(',') : [];
      const result = emailList.filter((email) => !regEx.test(String(email).toLowerCase()));
      this.setState({ emailInvalid: !isEmpty(result) }, () => {
        if (this.state.emailInvalid) return;
        this.setState({ email: formatInlineList(email, { allowAppend: true }) });
      });
    });

  handleSubjectChange = (e) =>
    this.setState({
      subject: e.target.value,
      subjectInvalid: e.target.value.trim() === '',
    });

  handleCCChange = (e) =>
    this.setState({ cc: e.target.value }, () => {
      const { cc } = this.state;
      const regEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const emailList = isString(cc) ? formatInlineList(cc).split(',') : [];
      const result = emailList.filter((email) => !regEx.test(String(email).toLowerCase()));
      this.setState({ ccInvalid: !isEmpty(result) }, () => {
        if (this.state.ccInvalid) return;
        this.setState({ cc: formatInlineList(cc, { allowAppend: true }) });
      });
    });

  handleOk = async () => {
    const email = this.state.email.split(',').filter(isNotEmpty);
    const cc = this.state.cc.split(',').filter(isNotEmpty);
    if (isEmpty(email)) return this.setState({ emailInvalid: true });

    if (this.state.emailInvalid === false && this.state.subjectInvalid === false) {
      const emailRequestBody = {
        ...this.state,
        email,
        cc,
        id: this.props.ids,
        certFile: this.props.certFile,
        isHa: this.props.isHa,
        img: this.props.img,
        isPlt: this.props.isPlt,
        isStock: this.props.isStock,
        videoFile: this.props.videoFile,
        assetFile: this.props.assetFile,
      };

      Object.entries(emailRequestBody).forEach(([key, value]) => {
        if (value === false) delete emailRequestBody[key];
      });

      if (this.props.xRay) {
        LoaderService.startLoading(true);
        const [err, res] = await sendXRayEmailAttachment({ ...this.state, email, cc }, this.props.path);
        LoaderService.stopLoading(true);

        if (!err) {
          Notify.success({ message: res?.message });
          this.props.onDetailClose();
        }
        return;
      }

      const { request, paginate } = this.props;
      const filterIsEmpty = isEmpty(this.props.filter);
      const checkedIsEmpty = isEmpty(this.props.checked);
      const usePaginateFunction = Boolean(filterIsEmpty && checkedIsEmpty && paginate);
      ['page', 'limit'].forEach((key) => delete request?.[key]);

      const filter = (() => {
        if (usePaginateFunction) return undefined;
        let filter = {};
        if (!filterIsEmpty) filter = { ...this.props.filter };
        if (!checkedIsEmpty) filter = { ...filter, vStnId: this.props.checked?.map((d) => d.vStnId), isFcCol: false };
        return filter;
      })();

      LoaderService.startLoading(true);

      const [err, res] = await (usePaginateFunction
        ? paginate({ ...request, isExcel: true, sendEmail: true, email: emailRequestBody })
        : sendEmailAttachment({ filter, sendEmail: true, email: emailRequestBody }));

      LoaderService.stopLoading(true);

      if (!err) {
        Notify.success({ message: res?.message });
        this.props.onCancel();
      }
    }
  };

  handleMySelfEmail = () => {
    const user = getLoginUser();
    const cc = this.state.cc.split(',').filter(isNotEmpty);
    !cc.includes(user.email) && cc.push(user.email);
    this.setState({ cc: cc.join(',') });
  };

  render() {
    return (
      <CommonModal
        visible={this.props.sendEmail}
        handleOk={() => this.handleOk()}
        handleCancel={() => {
          this.setState({
            cc: '',
            email: '',
            subjectInvalid: false,
            emailInvalid: false,
            ccInvalid: false,
          });
          this.props.onCancel();
        }}
        title="Send Email"
        footerShow
        submitTitle="Send Email"
        cancelTitle="Cancel"
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue1">
            <InputBlock
              label="Subject *"
              placeholder="Subject"
              value={this.state.subject}
              onChange={(e) => this.handleSubjectChange(e)}
              error={this.state.subjectInvalid ? ' Please enter email subject' : ''}
            />
          </div>

          <div>
            <InputBlock
              label="Email *"
              placeholder="Email"
              autoFocus
              value={this.state.email}
              onChange={(e) => this.handleEmailChange(e)}
              error={this.state.emailInvalid ? 'Please enter valid email' : ''}
            />
          </div>

          <div className="searchPopupTwoValue1">
            <InputBlock
              label={
                <>
                  Mail CC <a onClick={this.handleMySelfEmail}> | My Self</a>
                </>
              }
              placeholder="Mail CC"
              value={this.state.cc}
              onChange={(e) => this.handleCCChange(e)}
              error={this.state.ccInvalid ? 'Please enter valid cc email' : ''}
            />
          </div>
          <div className="searchPopupTwoValue1">
            <TextArea
              label="Message"
              placeholder="Message"
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.target.value })}
            />
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default SendEmailPopup;
