import Cookies from 'js-cookie';

import Storage from 'services/storage';

import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_DEFAULT_PASSWORD_SET,
  USERNAME_PASSWORD,
  VERIFY_OTP,
  REMOVE_USERNAME_PASSWORD,
} from 'constants/ActionTypes';

const initialState = {
  token: Cookies.get('token'),
  initURL: '',
  authUser: Storage.get('user'),
  defaultPassword: null,
  userData: {},
  isVerified: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_URL:
      return { ...state, initURL: action.payload };

    case SIGNOUT_USER_SUCCESS:
      return { ...initialState, token: null, authUser: null, initURL: '' };

    case USER_DATA:
      return { ...state, authUser: action.payload };

    case USER_TOKEN_SET:
      return { ...state, token: action.payload };

    case USER_DEFAULT_PASSWORD_SET:
      return { ...state, defaultPassword: action.payload };

    case USERNAME_PASSWORD:
      return { ...state, userData: action.payload };

    case VERIFY_OTP:
      return { ...state, isVerified: action.payload };

    case REMOVE_USERNAME_PASSWORD:
      return { ...state, userData: {} };

    default:
      return state;
  }
};
