import { TabActions } from 'appRedux/reducers/TabIssueReducer/TabReducer';
import { store } from 'appRedux/store';

import * as AuthActions from './actions/Auth';
import * as CommonActions from './actions/Common';

const actionsList = [
  CommonActions.fetchStart,
  AuthActions.signoutUserSuccess,
  TabActions.resetList,
  CommonActions.fetchSuccess,
];

export const resetStore = () => actionsList.forEach((action) => store.dispatch(action()));
