import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CURRENT_PAGE_DATA,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SWITCH_STONE_TYPE,
} from '../../constants/ActionTypes';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';

function labGrownInit() {
  const selection = localStorage.getItem(`${LOCAL_STORAGE_VAR}-labGrown`) || 'N';

  if (selection == 'Y') {
    document.querySelector(':root').style.setProperty('--theme-color', '#2980b9');
    document.querySelector(':root').style.setProperty('--theme-light-color', '#c7ecee');
  }
  return { labGrown: selection }
}

const INIT_STATE = {
  error: '',
  loading: false,
  message: '',
  ...labGrownInit()
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', message: '', loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: '', message: action.payload, loading: false };
    }
    case SWITCH_STONE_TYPE:
      window.location.reload();
      return {
        ...state,
        labGrown: action.payload,
      };
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: '' };
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: '', message: '' };
    }
    case CURRENT_PAGE_DATA: {
      return { ...state, pageData: action.payload };
    }

    default:
      return state;
  }
};
