const initialState = {
  isActive: true,
  isApproved: 'all',
};

export const accountActionTypes = {
  SET_ACCOUNT_FILTER: 'SET_ACCOUNT_FILTER',
  RESET_ACCOUNT_FILTER: 'RESET_ACCOUNT_FILTER',
};

export const AccountFilterActions = {
  setAccountFilter: (filters) => ({
    type: accountActionTypes.SET_ACCOUNT_FILTER,
    payload: filters,
  }),
  resetFilter: () => ({
    type: accountActionTypes.RESET_ACCOUNT_FILTER,
  }),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case accountActionTypes.SET_ACCOUNT_FILTER:
      return { ...state, ...action.payload };

    case accountActionTypes.RESET_ACCOUNT_FILTER:
      return { ...initialState };

    default:
      return state;
  }
};
