import React from 'react';

import loginMp4 from 'assets/images/login.mp4';

function LoginRightSection() {
  return (
    <div className="login-right-section">
      <div className="" style={{ width: '100%', height: '100%' }}>
        <video
          // width="100%"
          height="100%"
          src={loginMp4}
          disablePictureInPicture
          disableRemotePlayback
          autoPlay
          preload
          muted
          loop
        />
      </div>
    </div>
  );
}

export default LoginRightSection;
