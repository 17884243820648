import React from 'react';

import { history } from 'util/history';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import logoImage from 'assets/images/logo-sidebar.png';

function SidebarLogo() {
  const goToMainPage = React.useCallback(() => {
    history.push(PREFIX_URL_WITH_SLASH);
  }, []);

  return (
    <div className="gx-layout-sider-header pointer" onClick={goToMainPage}>
      <div className="gx-linebar">
        <img src={logoImage} alt="" />
      </div>
    </div>
  );
}

export default SidebarLogo;
