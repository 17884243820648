import React from 'react';

import { Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import DatePickerInput from 'containers/DatePickerInput';
import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import * as BannerAPI from 'services/BannerApi';
import { showNotification } from 'services/NotificationService';

import { isEmpty, isObject } from 'util/utils';

import * as AppConsts from 'constants/Common';

const initialValues = {
  type: '',
  eventName: '',
  description: '',
  url: '',
  bannerType: AppConsts.BANNER_TYPES_IMAGE.Text,
  activity: '',
  fromdateTime: '',
  toDateTime: '',
  images: '',
  mobileImage: '',
  isActive: true,
};

const validationSchema = Yup.object().shape({
  type: Yup.number().oneOf(Object.values(AppConsts.BANNER_TYPES), 'Invalid Type').required('Type is required'),
  eventName: Yup.string().trim().required('Title is required').typeError('Title must be a string'),
  activity: Yup.string().trim().required('Activity is required').typeError('Activity must be a string'),
  fromdateTime: Yup.date().required('Start Date is required'),
  toDateTime: Yup.date()
    .min(Yup.ref('fromdateTime'), 'End date must be grater than Start Date')
    .required('End Date is required'),
  images: Yup.array().required('Background Image is required'),
});

function SliderForm({ update, closeForm }) {
  const saveContinue = React.useRef(false);

  const getInitialValues = React.useCallback(() => {
    if (!isEmpty(update)) {
      update.fromdateTime = update?.fromdateTime && moment(update?.fromdateTime);
      update.toDateTime = update?.toDateTime && moment(update?.toDateTime);
    }

    return Object.fromEntries(
      Object.entries(initialValues).map(([key, value]) => [key, isEmpty(update?.[key]) ? value : update?.[key]]),
    );
  }, [update]);

  const submitForm = React.useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const [err, res] = await (!isEmpty(update?.id)
        ? BannerAPI.updateBanner(update?.id, { ...update, ...values })
        : BannerAPI.storeBanner(values));
      setSubmitting(false);

      if (!err) {
        showNotification(res);
        if (isEmpty(update?.id)) resetForm();
        if (!saveContinue.current) closeForm();
      }
    },
    [update, closeForm],
  );

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ handleChange, handleBlur, setValues, setFieldTouched, touched, errors, values }) => (
        <Form>
          <div className="d-flex flex-wrap homeSettingWrapper">
            <SelectOption
              name="type"
              label="Type *"
              placeholder="Select type"
              value={isEmpty(values?.type) ? undefined : `${values?.type}`}
              error={touched?.type && errors?.type}
              onBlur={() => setFieldTouched('type')}
              onDropdownVisibleChange={(open) => !open && setFieldTouched('type')}
              onChange={(type) => setValues((values) => ({ ...values, type }))}
              options={
                isObject(AppConsts.BANNER_TYPES)
                  ? Object.entries(AppConsts.BANNER_TYPES).map(([value, key]) => ({ key, value }))
                  : []
              }
            />
            <InputBlock
              name="eventName"
              label="Title *"
              placeholder="Title"
              value={values.eventName}
              error={touched?.eventName && errors?.eventName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <DatePickerInput
              label="Start Date *"
              value={values.fromdateTime}
              onOpenChange={(open) => !open && setFieldTouched('fromdateTime')}
              onChange={(fromdateTime) => setValues((values) => ({ ...values, fromdateTime }))}
              error={touched?.fromdateTime && errors?.fromdateTime}
              showTime
            />
            <DatePickerInput
              label="End Date *"
              value={values.toDateTime}
              onOpenChange={(open) => !open && setFieldTouched('toDateTime')}
              onChange={(toDateTime) => setValues((values) => ({ ...values, toDateTime }))}
              error={touched?.toDateTime && errors?.toDateTime}
              showTime
            />{' '}
            <InputBlock
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.activity}
              name="activity"
              label="Activity *"
              placeholder="Activity"
              error={touched?.activity && errors?.activity}
            />
            <InputBlock
              name="url"
              label="URL"
              placeholder="URL"
              value={values.url}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched?.url && errors?.url}
            />
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched?.description && errors?.description}
            />
            <div className="d-flex from-group">
              <FileUpload
                name="mobileImage"
                fullWidth="width-auto"
                label="Upload Mobile Image"
                size="JPG,JPEG,PNG,GIF file allowed"
                isValidate
                accept="JPG,JPEG,PNG,GIF"
                files={[values?.mobileImage]}
                onBlur={handleBlur}
                onChange={(mobileImage) => setValues((values) => ({ ...values, mobileImage }))}
                error={touched?.mobileImage && errors?.mobileImage}
              />
            </div>
            <div className="d-flex from-group width-100 mt-10">
              <FileUpload
                name="images"
                fullWidth="width-auto"
                label="Upload Images"
                isValidate
                multiple
                accept="JPG,JPEG,PNG,GIF"
                size="JPG,JPEG,PNG,GIF file allowed"
                files={values?.images}
                onChange={(images) => setValues((values) => ({ ...values, images }))}
                onBlur={handleBlur}
                error={touched?.images && errors?.images}
              />
            </div>
            <div className="from-group mt-20">
              <Checkbox
                name="isActive"
                checked={values.isActive}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched?.isActive && errors?.isActive}
              >
                Is Active
              </Checkbox>
            </div>
            <div className="width-100 d-flex mt-40">
              <button className="commonButton mr-10" type="submit" value="Submit">
                {isEmpty(update?.id) ? 'Save' : 'Update'}
              </button>
              <button className="commonOutine" type="reset" onClick={closeForm}>
                Back
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SliderForm;
