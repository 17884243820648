export const Messages = {
  // Inventory place order
  DiamondSelectRequire: 'Please Select Diamond.',
  MoreThanOneStone: 'Please select minimum two diamonds to compare.',
  CommentRequire: 'Please Enter Note.',
  CompanySelect: 'Please Select Company Name.',
  FilterSelect: 'Please Select Filter.',

  //MemoPopupModal
  userAccountSelect: 'Please Enter Party.',
  userSelect: 'Please Enter buyer.',
  sellerSelect: 'Please Select Salesman.',
  shippingCountrySelect: 'Please Select Shipping Country.',
  shippingCitySelect: 'Please Select Shipping City.',
  stageSelect: 'Please Select Stage.',
  assistantSellerSelect: 'Please Select Assistant Salesman.',
  shipModeSelect: 'Please Select ShipMode.',
  brokerSelect: 'Please Enter Broker.',

  // HoldPopUp
  clientNameSelect: 'Please Select ClientName.',
  typeSelect: 'Please Select Type.',
  holdTime: 'Min Hold Time: 1 | Max Hold Time: 72',

  //confirm stone
  billTypeSelect: 'Please Select Bill Type.',
  sourceOfSaleSelect: 'Please Select Source Of Sale.',
  courierNameSelect: 'Please Select Courier Name.',
  invoiceDateSelect: 'Please Select Invoice Date',

  //offer message
  sameClientSelect: 'Please Select Similar Customer Diamond.',
  reminderDateSelect: 'Please Select Office Date.',
  validTillDateSelect: 'Please Select Hold Till Date.',

  MemoAndHoldStone: 'Sorry! Unable to perform action at this time. The selected diamond(s) is in hold/memo.',

  SelectFilter: 'Please select any 2 criteria.',
  unHoldDiamond: 'You can not UnHold this diamond.',
  unMemoDiamond: 'You can not UnMemo this diamond.',

  Error: {
    Required: {
      Company: 'Please Select Company Name.',
      Stones: 'Please Select Diamond.',
      MoreThanOneStone: 'Please select minimum two diamonds to compare.',
      MemoAndHoldStone: 'This action is not allowed.',
      MemoAndHoldOther: 'Please select the same party.',
      diamondAction: 'Operation is not allowed.',
    },
    Exists: {
      Stones: 'Diamond already exists',
    },
    NotFound: {
      Stones: 'No diamonds found',
    },
  },
};

export default Messages;
