import React from 'react';

import { startCase } from 'lodash';

import InlineList from 'components/List/InlineList';

import { getUserName, isEmpty } from 'util/utils';

const ClientName = ({ user }) => {
  const list = React.useMemo(() => {
    const companyName = (() => {
      const name = user?.account?.companyName ?? user?.companyName;
      return !isEmpty(name) ? <a className="tableLink">{name}</a> : undefined;
    })();
    const userName = startCase(getUserName(user));
    return [companyName, userName];
  }, [user]);

  return <InlineList skipEmpty separator=" | " list={list} />;
};

export default React.memo(ClientName);
