import React, { Component } from 'react';

import * as BannerAPI from 'services/BannerApi';

import BannerAdd from './form';
import BannerList from './list';

class Banner extends Component {
  state = { list: true, detailL: {} };

  toggle = () => this.setState({ list: !this.state.list, detail: {} });

  editDetails = async (editId) => {
    const [, res] = await BannerAPI.findBanner(editId);
    if (res?.code === 'OK') {
      this.setState({ detail: res.data, list: !this.state.list });
    }
  };

  render() {
    return this.state.list ? (
      <BannerList onClick={this.toggle} editDetails={this.editDetails} />
    ) : (
      <BannerAdd closeForm={this.toggle} update={this.state.detail} />
    );
  }
}

export default Banner;
