import axios from 'axios';
import { memoize } from 'lodash';

import { LoaderService } from 'services/LoaderService';
import { asyncHandler } from 'util/asyncHandler';
import { logWarn } from 'util/utils';

export class MediaService {
  static async getHeaders(path) {
    try {
      const response = await axios.head(path);
      return response?.headers;
    } catch (error) {
      logWarn(error);
      return null;
    }
  }
  static memoizedGetHeaders = memoize(this.getHeaders, (arg) => `${arg}`);

  static async getBlobUrl(path) {
    try {
      const response = await axios.get(path, { responseType: 'blob' });
      return window.URL.createObjectURL(response?.data);
    } catch (error) {
      logWarn(error);
    }
  }

  static async viewFile(path) {
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await this.getBlobUrl(path);
      if (!blobUrl) return;
      const anchor = document.createElement('a');
      void ((anchor.style.display = 'none'), (anchor.href = blobUrl), (anchor.target = '_blank'));
      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
    LoaderService.stopLoading();
    return response;
  }

  static async downloadFile(path, name) {
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await this.getBlobUrl(path);
      if (!blobUrl) return;
      const anchor = document.createElement('a');
      void ((anchor.style.display = 'none'),
      (anchor.href = blobUrl),
      (anchor.download = name ?? path.split('/').pop()));
      void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
    });
    LoaderService.stopLoading();
    return response;
  }

  static async printFile(path) {
    LoaderService.startLoading();
    const response = await asyncHandler(async () => {
      const blobUrl = await this.getBlobUrl(path);
      if (!blobUrl) return;
      const iframe = document.querySelector('#PrintFrame') ?? document.createElement('iframe');
      if (iframe?.src) window.URL.revokeObjectURL(iframe.src);
      void ((iframe.style.display = 'none'), (iframe.src = blobUrl), (iframe.height = '100%'), (iframe.width = '100%'));
      void ((iframe.id = 'PrintFrame'), document.body.appendChild(iframe), iframe.contentWindow.print());
    });
    LoaderService.stopLoading();
    return response;
  }
}

window.__MediaService = MediaService;
