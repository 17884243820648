import React from 'react';
import { connect } from 'react-redux';

import { Button, Form, Input } from 'antd';
import _ from 'lodash';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import { userGetOTP, userSignIn } from '../appRedux/actions/Auth';
import { PROJECT_DETAILS } from '../constants/Common';
import OpenNotification from '../constants/OpenNotifications';

const FormItem = Form.Item;

class OTPVerification extends React.Component {
  componentDidMount() {
    if (_.isEmpty(this.props.userData)) {
      this.props.history.push(PREFIX_URL_WITH_SLASH + '/signin');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const obj = {
        ...this.props.userData,
        ...values,
      };
      if (!err) {
        this.props.userSignIn(obj, (cb) => {
          if (cb.error) {
            OpenNotification({
              type: 'error',
              title: cb.error,
            });
          }
          if (
            (cb && !cb.error && this.props.token !== null) ||
            (this.props.defaultPassword && this.props.defaultPassword.userId)
          ) {
            this.props.history.push('/');
          }
        });
      }
    });
  };

  resendOTP = () => {
    const values = this.props.userData;
    this.props.userGetOTP(values, (cb) => {});
  };

  componentDidUpdate() {
    // if (
    //     this.props.token !== null ||
    //     (this.props.defaultPassword && this.props.defaultPassword.userId) &&
    //     this.props.isVerified
    // ) {
    //     this.props.history.push("/");
    // }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="adma-login-wrap">
        <div className="adma-login-container">
          <div className="loginpage">
            <div className="adma-login-main-content">
              {/* <div className="adma-app-logo-wid">
                    <h1>Sign In</h1>
                    <p>
                      By Signing Up, you can avail full features of our services.
                    </p>
                  </div> */}
              {/* <div className="adma-app-logo signinlogo">
                    <img
                      alt="example"
                      src={require("assets/images/logo-white.svg")}
                    />
                  </div> */}
              <div className="adma-app-login-content">
                <div className="adma-logo-content-bg">
                  {/* <h1>Finestar</h1> */}
                  <img src={PROJECT_DETAILS.LOGO_1} />
                  <h2 className="login-title">
                    <span>Enter your OTP</span>
                  </h2>
                </div>

                <Form onSubmit={this.handleSubmit} className="adma-signin-form adma-form-row0">
                  <FormItem>
                    {getFieldDecorator('otp', {
                      rules: [
                        {
                          required: true,
                          message: 'The input is not valid otp!',
                        },
                      ],
                    })(<Input type="password" placeholder="OTP" />)}
                  </FormItem>
                  <div className="submit-btn">
                    <FormItem>
                      <Button type="primary" className="gx-mb-0" htmlType="submit">
                        Verify
                      </Button>

                      <Button type="primary" className="gx-mb-0" onClick={() => this.resendOTP('')}>
                        Re-send
                      </Button>

                      <Button type="primary" className="gx-mb-0" onClick={() => (window.location.href = '/')}>
                        Cancel
                      </Button>
                    </FormItem>
                  </div>
                </Form>
              </div>
            </div>
            <div className="login-right-section">
              {/* <div className="media">
                <div className="media-left">
                  <img src={require('assets/images/price-guarantee.svg')} alt="price-guarantee" />
                </div>
                <div className="media-body">
                  <h3 className="serives-name">Best Prices Guarantee</h3>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalOTPForm = Form.create()(OTPVerification);

const mapStateToProps = ({ auth }) => {
  const { defaultPassword, token, userData, isVerified } = auth;
  return { defaultPassword, token, userData, isVerified };
};

export default connect(mapStateToProps, { userGetOTP, userSignIn })(WrappedNormalOTPForm);
