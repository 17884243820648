import React from 'react';

import CommonModal from 'containers/CommonModal';

import { Share } from 'util/Share';
import * as Hooks from 'util/hooks';
import { Notify } from 'util/notify';
import { isArray, isEmpty } from 'util/utils';

import { Messages } from 'constants/Messages';
import { FORNT_SIDE_URL } from 'constants/PageUrl';

import SendEmailPopup from './SendEmailPopup';

import './action.less';

const ShareAction = (props) => {
  const { onClose, unCheckedDiamond } = props;
  const { filter = {}, checked = [], btnName } = props;

  const [sendEmail, setSendEmail] = Hooks.useBoolean();

  const handleSendEmail = React.useCallback(() => {
    if (isArray(checked) && !isEmpty(checked)) return setSendEmail.true();
    return Notify.error({ message: Messages.DiamondSelectRequire });
  }, [checked, setSendEmail]);

  const cancelSendEmail = React.useCallback(() => {
    setSendEmail.false();
    onClose();
    unCheckedDiamond();
  }, [onClose, setSendEmail, unCheckedDiamond]);

  const WhatsAppShare = React.useCallback(() => {
    if (!isArray(checked) || isEmpty(checked)) return Notify.error({ message: Messages.DiamondSelectRequire });

    if (checked.length === 1) {
      const stoneId = checked?.[0]?.diamondId ?? checked?.[0]?.id ?? checked?.[0]?.selectionKey;
      const link = `${FORNT_SIDE_URL.diamondDetail}/${stoneId}${btnName === 'drshare' ? '?direct-share' : ''}`;
      Share.whatsApp({ text: link });
    } else if (checked.length > 1) {
      let text = `MyDiamonds: Diamonds Detail\n\n`;
      checked.forEach(
        (element) =>
          void (text += `${FORNT_SIDE_URL.diamondDetail}/${
            element?.diamondId ?? element?.id ?? element?.selectionKey
          }\n\n`),
      );
      Share.whatsApp({ text });
    }
    onClose();
    unCheckedDiamond();
  }, [btnName, checked, onClose, unCheckedDiamond]);

  const SkypeShare = React.useCallback(() => {
    if (!isArray(checked) || isEmpty(checked)) return Notify.error({ message: Messages.DiamondSelectRequire });
    const stoneId = checked?.[0]?.diamondId ?? checked?.[0]?.id ?? checked?.[0]?.selectionKey;

    if (checked.length === 1) {
      const link = `${FORNT_SIDE_URL.diamondDetail}/${stoneId}${btnName === 'drshare' ? '?direct-share' : ''}`;
      Share.skype({ text: link });
    } else if (checked.length >= 2) {
      let text = `MyDiamonds: Diamonds Detail\n\n`;
      checked.forEach(
        (element) =>
          void (text += `${FORNT_SIDE_URL.diamondDetail}/${
            element?.diamondId ?? element?.id ?? element?.selectionKey
          }\n\n`),
      );
      Share.skype({ text });
    }

    onClose();
    unCheckedDiamond();
  }, [btnName, checked, onClose, unCheckedDiamond]);

  const Wechat = React.useCallback(() => {
    if (!isArray(checked) || isEmpty(checked)) return Notify.error({ message: Messages.DiamondSelectRequire });
    const stoneId = checked?.[0]?.diamondId ?? checked?.[0]?.id ?? checked?.[0]?.selectionKey;

    if (checked.length === 1) {
      const path = `${FORNT_SIDE_URL.diamondDetail}/${stoneId}`;
      window.open(`https://web.wechat.com/send?text=${path}${btnName === 'drshare' ? '?direct-share' : ''}`);
    } else if (checked.length >= 2) {
      let msg = 'MyDiamonds: Diamonds Detail%0A%0A';

      checked.forEach((element) => {
        const path = `${FORNT_SIDE_URL.diamondDetail}/${element?.diamondId ?? element?.id ?? element?.selectionKey}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.wechat.com/send?text=${msg}`);
    }
    onClose();
    unCheckedDiamond();
  }, [btnName, checked, onClose, unCheckedDiamond]);

  return (
    <>
      <CommonModal title="Share Stone" handleCancel={onClose} visible={true} className="xs-size">
        <div>
          <div className="searchPopupCommon">
            <div className="commonModalButton">
              <button className="fillButton" onClick={handleSendEmail}>
                Email
              </button>
              <button onClick={WhatsAppShare} className="fillButton ml-5">
                Whats App
              </button>
              <button onClick={SkypeShare} className="fillButton ml-5">
                Skype
              </button>
              <button onClick={Wechat} className="fillButton ml-5">
                WeChat
              </button>
            </div>
          </div>
        </div>
      </CommonModal>
      <SendEmailPopup
        filter={filter}
        checked={checked}
        sendEmail={sendEmail}
        onCancel={cancelSendEmail}
        ids={checked.map((item) => item['id'])}
        request={props.requestUrl?.request}
        paginate={props.requestUrl?.urlMethod}
        certFile={false}
        hAFile={false}
        img={false}
        isPlt={false}
        isReal={false}
        assetFile={false}
        isStock={false}
        videoFile={false}
      />
    </>
  );
};

export default ShareAction;
