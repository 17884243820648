import React from 'react';

import Modal from 'containers/CommonModal';

import { formatDate, returnIfNotEmpty } from 'util/utils';

import eyeGray from 'assets/svg/InventoryResult/view.svg';

function OrderDetailsCell({ row }) {
  const [detailsVisible, setDetailsVisibility] = React.useState(false);

  const hideDetails = React.useCallback(() => setDetailsVisibility(false), [setDetailsVisibility]);

  const details = React.useMemo(() => {
    // $.data.list[0]..['seller','broker','invoiceDate','billType','terms','bankRate','comment']
    const { user, seller, broker, createdAt, billType, terms, bankRate, comments, comment } = row?.original;
    return { user, seller, broker, createdAt, billType, terms, bankRate, comments, comment };
  }, [row?.original]);

  return (
    <div className="modal-details">
      <span title="View Order Details" onClick={setDetailsVisibility.true}>
        <img src={eyeGray} alt="View Order Details" style={{ display: 'inline', height: '15px', width: '15px' }} />
      </span>
      <Modal
        visible={detailsVisible}
        onCancel={hideDetails}
        title="Order Details"
        footer={null}
        destroyOnClose
        noDefaults
        centered
      >
        <div className="detailShow d-flex flex-wrap">
          <div className="detail separator">
            <span>Party: </span>
            <span>{returnIfNotEmpty(details.user?.account?.companyName, '-')}</span>
          </div>
          <div className="detail separator">
            <span>Buyer: </span>
            <span>
              {returnIfNotEmpty([details?.user?.firstName, details?.user?.lastName].filter(Boolean).join(' '), '-')}
            </span>
          </div>
          <div className="detail separator">
            <span>Seller: </span>
            <span>
              {returnIfNotEmpty([details?.seller?.firstName, details?.seller?.lastName].filter(Boolean).join(' '), '-')}
            </span>
          </div>
          <div className="detail separator">
            <span>Broker: </span>
            <span>{returnIfNotEmpty([details?.broker?.companyName].filter(Boolean).join(' '), '-')}</span>
          </div>
          <div className="detail separator">
            <span>Invoice Date: </span>
            <span>{returnIfNotEmpty(formatDate(details?.createdAt), '-')}</span>
          </div>
          <div className="detail separator">
            <span>Bill Type: </span>
            <span>{returnIfNotEmpty(formatDate(details?.billType), '-')}</span>
          </div>
          <div className="detail separator">
            <span>Terms: </span>
            <span>{returnIfNotEmpty(formatDate(details?.terms), '-')}</span>
          </div>
          <div className="detail">
            <span>Bank Rate: </span>
            <span>{returnIfNotEmpty(formatDate(details?.bankRate), '-')}</span>
          </div>
          <div className="detail width-100">
            <span>Comment: </span>
            <span>{returnIfNotEmpty(details?.comment, '-')}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

OrderDetailsCell.propTypes = {};

export default React.memo(OrderDetailsCell);
