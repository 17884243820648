import { UAParser } from 'ua-parser-js';

import { isNumber, isString } from './utils';
import { logInfo, objectToQueryString } from './utils';

/**
 * Share class
 * Cross-platform third-party sharing
 * @note: Keep all methods static (so thay can be accessed directly)
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export class Share {
  static get _isMobile() {
    const isMobile = (() => {
      const uaOS = new UAParser(navigator?.userAgent).getOS();
      return ['ios', 'android'].includes(`${uaOS?.name}`.toLowerCase());
    })();

    // using > 1 instead of > 0 touch points to rule out reponsive mode in browsers
    const maxTouchPoints = navigator?.maxTouchPoints ?? navigator?.msMaxTouchPoints;
    const isTouch = isNumber(maxTouchPoints) ? maxTouchPoints > 1 : 'ontouchstart' in window;

    return isMobile && isTouch;
  }

  static _transformInput(input) {
    const phone = (() => {
      const _phone = input?.phone;
      const _output = isNumber(_phone) ? _phone : isString(_phone) ? Number(_phone.replace(/\D/g, '')) : undefined;
      return isNumber(_output) ? `${_output}` : '';
    })();

    const text = (() => {
      const _text = input?.text;
      const _output = isString(_text) ? window.encodeURIComponent(_text) : undefined;
      return isString(_output) ? _output : '';
    })();

    return { phone, text };
  }

  static whatsApp(params = {}) {
    try {
      const { phone, text } = this._transformInput(params);
      this._isMobile
        ? window.open(`https://api.whatsapp.com/send${objectToQueryString({ phone, text })}`)
        : window.open(`https://web.whatsapp.com/send${objectToQueryString({ phone, text })}`);
    } catch (error) {
      logInfo({ 'util.Share.whatsApp.error': error });
    }
  }

  static skype(params = {}) {
    try {
      const { text } = this._transformInput(params);
      // this._isMobile
      //   ? window.open(`skype:?chat&topic=${text}`)
      //   : window.open(`https://web.skype.com/share${objectToQueryString({ url: text })}`);
      window.open(`https://web.skype.com/share${objectToQueryString({ url: text })}`);
    } catch (error) {
      logInfo({ 'util.Share.skype.error': error });
    }
  }

  static wechat(params = {}) {
    try {
      const { text } = this._transformInput(params);
      // this._isMobile
      //   ? window.open(`weixin://dl/posts?${text}`)
      //   : window.open(`https://web.wechat.com/send${objectToQueryString({ text })}`);
      window.open(`https://web.wechat.com/send${objectToQueryString({ text })}`);
    } catch (error) {
      logInfo({ 'util.Share.wechat.error': error });
    }
  }
}
