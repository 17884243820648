import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';
import { store } from 'appRedux/store/index';
import Storage from './storage';

export const fetchSearch = (request = {}, config) =>
  UtilService.callApiAsync({
    ...API_ROUTES.Diamond.search,
    request: {
      ...request,
      account: request?.account || Storage.get('user')?.account?.id,
      isAppendMasters: true,
      labGrown: store.getState().commonData?.labGrown,
    },
    ...(!isEmpty(config) && { config }),
  });

export const deleteSearch = (id, config) =>
  UtilService.callApiAsync({ ...API_ROUTES.Diamond.delete, request: { id }, ...(!isEmpty(config) && { config }) });

export const getSearchIds = async (filters, config) =>
  UtilService.callApiAsync({
    ...API_ROUTES.Diamond.paginate,
    request: { filters: [filters], isNotReturnTotal: true, isReturnCountOnly: true },
    ...(!isEmpty(config) && { config }),
  });
