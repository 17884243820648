import React from 'react';

import { debounce } from 'lodash';

import CircularProgress from 'components/CircularProgress';

import { MediaService } from 'services/MediaService';

import { useBoolean } from 'util/hooks';
import { catchError, isEmpty, isString, logWarn } from 'util/utils';

function Embed(props) {
  const { tag = 'iframe', name, title, clickToOpen, hideOnError, checkHeaders, loadBlob, children, ..._props } = props;
  const [loading, setLoading] = useBoolean(false);
  const [error, setError] = useBoolean(false);
  const [show, setShow] = useBoolean(false);
  const [src, setSrc] = React.useState();

  const ref = React.useRef();

  const checkFileHeaders = React.useCallback(async () => {
    setLoading.true();
    const headers = await MediaService.getHeaders(_props.src);
    console.log(headers)
    void (headers && !isEmpty(headers) ? (setError.false(), setShow.true()) : (setError.true(), setShow.false()));
    setLoading.false();
  }, [src, setError, setLoading, setShow]);

  const getSrc = React.useCallback(async () => {
    if (!loadBlob) return _props.src;
    setLoading.true();
    const blobUrl = await catchError(
      () => MediaService.getBlobUrl(_props.src),
      () => _props.src,
    );
    setSrc((src) => {
      try {
        window.URL.revokeObjectURL(src);
      } catch (error) {
        logWarn(error);
      }
      return blobUrl;
    });
    setLoading.false();
  }, [loadBlob, _props.src, setLoading]);

  React.useEffect(() => {
    if (checkHeaders) return;
    setTimeout(() => setShow.true(), 0);
    return () => setShow.false();
  }, [checkHeaders, setShow]);

  React.useEffect(() => {
    if (checkHeaders) checkFileHeaders();
  }, [checkFileHeaders, checkHeaders]);

  React.useEffect(() => {
    getSrc();
  }, [getSrc]);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (isString(ref.current?.src)) ref.current.src = src ?? ref.current.src;
    }, 0);
  }, [src]);

  const handleClick = React.useCallback(() => {
    if (clickToOpen) window.open(src, '_blank');
  }, [clickToOpen, src]);

  const component = React.useMemo(() => {
    return error
      ? children ?? <div className="no-msg">{name} Not available</div>
      : React.createElement(tag, {
        ref,
        onload: debounce(() => !checkHeaders && setLoading.false(), 320),
        onerror: debounce(() => !checkHeaders && (setError.true(), setLoading.false()), 320),
        style: { border: 'none' },
        title,
        ..._props,
      });
  }, [error, children, name, tag, title, _props, checkHeaders, setLoading, setError]);

  if (hideOnError && error) return children;
  if (!show && loading) return <CircularProgress />;
  if (!show) return null;

  return (
    <>
      {loading && <CircularProgress />}
      {clickToOpen ? (
        <div className="embed-wrapper">
          <div className="embed-overlay" title={title} onClick={handleClick} />
          {component}
        </div>
      ) : (
        component
      )}
    </>
  );
}

export default React.memo(Embed);
