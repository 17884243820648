import React from 'react';

import classNames from 'util/classNames';

const BottomStickyButton = ({ children, className, ...rest }) => (
  <div className={classNames(['sidebarAction', className])} {...rest}>
    {children}
  </div>
);

export default BottomStickyButton;
