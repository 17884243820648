import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { compose } from 'redux';

import { Menu, Dropdown, Drawer } from 'antd';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';

import ClientSidebar from 'components/ShortCuts/ClientDrawer';
import AddKYC from 'components/ShortCuts/KYC';
import UserDrawer from 'components/ShortCuts/UserDrawer';

import Storage from 'services/storage';
import { switchStoneType } from 'appRedux/actions/Common';
import { store } from 'appRedux/store';
import { USERS_TYPE } from 'constants/Common';
import { MENU } from 'constants/Menu';
import * as ThemeSetting from 'constants/ThemeSetting';

import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';

import { ReactComponent as Account } from 'assets/svg/dashboard/account.svg';
import { ReactComponent as Media } from 'assets/svg/dashboard/media.svg';
import { ReactComponent as Cabin } from 'assets/svg/dashboard/cabin.svg';
import { ReactComponent as Stocksummary } from 'assets/svg/dashboard/stock-summary.svg';
import { ReactComponent as Appointment } from 'assets/svg/dashboard/appointment.svg';
import { ReactComponent as ParcelMaster } from 'assets/svg/dashboard/parcel-master.svg';
import { ReactComponent as Notification } from 'assets/svg/dashboard/notification.svg';
import { ReactComponent as Reports } from 'assets/svg/dashboard/reports.svg';
import { ReactComponent as Pricing } from 'assets/svg/dashboard/pricing.svg';
import { ReactComponent as Client } from 'assets/svg/dashboard/addClient.svg';
import { ReactComponent as AddUser } from 'assets/svg/dashboard/addUser.svg';
import { ReactComponent as Dashboard } from 'assets/svg/dashboard/dashboard.svg';
import { ReactComponent as Diamond } from 'assets/svg/dashboard/diamond.svg';
import { ReactComponent as Enquiry } from 'assets/svg/dashboard/enquiry.svg';
import { ReactComponent as Feedback } from 'assets/svg/dashboard/feedback.svg';
import { ReactComponent as Master } from 'assets/svg/dashboard/master.svg';
import { ReactComponent as Price } from 'assets/svg/dashboard/price.svg';
import { ReactComponent as Setting } from 'assets/svg/dashboard/setting.svg';
import { ReactComponent as Stock } from 'assets/svg/dashboard/stock.svg';
import { ReactComponent as Todo } from 'assets/svg/dashboard/todo.svg';
import { ReactComponent as Transactions } from 'assets/svg/dashboard/transactions.svg';
import { ReactComponent as User } from 'assets/svg/dashboard/user.svg';
import { ReactComponent as Switch } from 'assets/svg/dashboard/sync.svg';

const Icons = {
  Dashboard: <Dashboard />,
  Master: <Master />,
  Account: <Account />,
  User: <User />,
  Diamond: <Diamond />,
  Stock: <Stock />,
  Setting: <Setting />,
  Enquiry: <Enquiry />,
  Price: <Price />,
  Feedback: <Feedback />,
  Media: <Media />,
  Cabin: <Cabin />,
  Stocksummary: <Stocksummary />,
  Appointment: <Appointment />,
  ParcelMaster: <ParcelMaster />,
  Transactions: <Transactions />,
  Notification: <Notification />,
  Reports: <Reports />,
  Pricing: <Pricing />,
  Todo: <Todo />,
  Switch: <Switch />
};

class SidebarContent extends Component {
  state = { collapsed: false, client: false, user: false, sales: false, purchase: false, kyc: false };

  openClientDrawer = () => this.setState({ client: true });
  openUserDrawer = () => this.setState({ user: true });
  openSalesDrawer = () => this.setState({ sales: true });
  openPurchaseDrawer = () => this.setState({ purchase: true });
  openKYCDrawer = () => this.setState({ kyc: true });
  closeKYCDrawer = () => this.setState({ kyc: false });
  closeDrawers = () => this.setState({ client: false, user: false, sales: false, purchase: false, kyc: false });

  render() {
    const { navStyle, auth } = this.props;
    const { pathname } = this.props.location;
    const loginUserType = auth?.authUser?.type;
    const themeType = ThemeSetting.THEME_TYPE_LITE;
    const userPermissions = {};

    let PermissionData = loginUserType != USERS_TYPE.SUPER_ADMIN ? Storage.get('permission') : undefined;
    userPermissions[loginUserType] = [];
    const MenuData = [];
    let is_allow_all_permission = false;
    if (!PermissionData || !PermissionData.length) {
      is_allow_all_permission = true;
      PermissionData = MENU;
    } else {
      PermissionData.forEach((s) => {
        MENU.forEach((x) => {
          if (!s.id && s.module && x.name.toLowerCase() === s.module.toLowerCase()) {
            s.id = x.id;
          }
          if (s.permissions && s.permissions.view) {
            if (x.children && x.children.length) {
              x.children.forEach((val) => {
                if (val && !s.id && val.name.toLowerCase() === s.module.toLowerCase()) {
                  s.id = val.id;
                }
                if (s.id && val.id === s.id) {
                  PermissionData.push({
                    id: x.id,
                    module: x.name,
                    is_allow_all_parent: true,
                  });
                }
              });
            }
          }
        });
      });
    }
    PermissionData.forEach((s) => {
      if ((s.permissions && s.permissions.view) || is_allow_all_permission || s.is_allow_all_parent) {
        MenuData.push({ name: s.module, id: s.id });
        userPermissions[loginUserType].push(s.id);
        if (is_allow_all_permission) {
          if (s.children && s.children.length) {
            s.children.forEach((child) => {
              MenuData.push({ name: child.module, id: child.id });
              userPermissions[loginUserType].push(child.id);
            });
          }
        }
      }
    });

    userPermissions[loginUserType] = _uniqWith(userPermissions[loginUserType], _isEqual);

    const shortcutsMenuItem = (
      <Menu className="shortCutMenu">
        <h2>Add</h2>
        <Menu.Divider />
        <Menu.Item key="1" onClick={this.openKYCDrawer}>
          <Client onClick={this.closeKYCDrawer} />
          Clients / KYC
        </Menu.Item>
        {loginUserType != USERS_TYPE.SELLER && (
          <Menu.Item key="2" onClick={this.openUserDrawer}>
            <AddUser />
            User
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <React.Fragment>
        <div className="gx-sidebar-content">
          <SidebarLogo />
          <ScrollArea
            speed={0.8}
            className="settingLeftScroll area sideBarScroll"
            contentClassName="content"
            smoothScrolling
          >
            <Menu mode="inline" theme={themeType === ThemeSetting.THEME_TYPE_LITE ? 'lite' : 'dark'}>
              {MENU.map((menu) =>
                menu.children && menu.children.length
                  ? userPermissions[loginUserType].indexOf(menu.id) > -1 && (
                    <Menu.SubMenu
                      className={
                        navStyle === ThemeSetting.NAV_STYLE_NO_HEADER_MINI_SIDEBAR ? 'gx-no-header-submenu-popup' : ''
                      }
                      key={menu.name}
                      title={
                        <span className="sub-menu-dropdown">
                          {Icons?.[menu?.svg]}
                          <span className="vertical-menu">{menu.name}</span>
                        </span>
                      }
                    >
                      {menu.children.map(
                        (m) =>
                          userPermissions[loginUserType].indexOf(m.id) > -1 && (
                            <Menu.Item key={m.path && m.path.replace('/', '')}>
                              <NavLink to={m.path}>{m.name}</NavLink>
                            </Menu.Item>
                          ),
                      )}
                    </Menu.SubMenu>
                  )
                  : userPermissions[loginUserType].indexOf(menu.id) > -1 && (
                    <Menu.Item
                      className={pathname?.includes(menu.path) ? 'ant-menu-item-selected' : ''}
                      key={menu.path && menu.path.replace('/', '')}
                    >
                      <NavLink to={menu.path}>
                        {Icons?.[menu?.svg]}
                        <span className="vertical-menu">{menu.name}</span>
                      </NavLink>
                    </Menu.Item>
                  ),
              )}
            </Menu>
          </ScrollArea>
        </div>
        <div className="gx-sidebar-content">
          <Menu mode="inline">
            <Menu.Item
              key={'lgd'}
            >
              <a style={{ cursor: "pointer" }} onClick={() => this.props.switchStoneType()}>
                {Icons?.Switch}
                <span className="vertical-menu">
                  {this.props.commonData.labGrown == 'Y' ? 'Lab-Grown' : 'Natural'}
                </span>
              </a>
            </Menu.Item>
            <Dropdown
              overlay={shortcutsMenuItem}
              trigger={['click']}
              overlayClassName="shortCutPopup"
              placement="bottomCenter"
            >
              <Menu.Item>
                <svg
                  id="Capa_1"
                  enableBackground="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z" />
                    <path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    <path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    <path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                    <path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                  </g>
                </svg>
                <span className="vertical-menu">Shortcuts</span>
              </Menu.Item>
            </Dropdown>
          </Menu>
        </div>
        <UserProfile />
        <Drawer
          onClose={this.closeDrawers}
          visible={this.state.client}
          wrapClassName="diamondListinSidebar lg-size shortCutForm"
          destroyOnClose
        >
          <ClientSidebar onClose={this.closeDrawers} />
        </Drawer>
        <Drawer
          onClose={this.closeDrawers}
          visible={this.state.user}
          wrapClassName="diamondListinSidebar lg-size shortCutForm"
          destroyOnClose
        >
          <UserDrawer closeDrawer={this.closeDrawers} />
        </Drawer>
        <Drawer
          onClose={this.closeDrawers}
          visible={this.state.kyc}
          wrapClassName="diamondListinSidebar xl-size shortCutForm"
          destroyOnClose
        >
          <AddKYC selectedIndex={0} onCancel={this.closeDrawers} addAccount />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(({ auth, settings, commonData, }) => ({
    themeType: settings.themeType,
    auth: { ...auth },
    commonData,
  }), { switchStoneType }),
)(SidebarContent);
