import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import UtilService from 'services/util';

import CommonApi from 'constants/CommonApi';
import '../styles/thankyou.less';

class EmailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      alreadyVerified: false,
    };
  }

  componentDidMount() {
    this.emailVerifyCheck();
  }
  emailVerifyCheck = async () => {
    const self = this;
    const emailHash = _.last(window.location.pathname.split('verify-email/'));
    const obj = CommonApi.VerifyEmail.verify;
    obj.request = { emailHash };
    const [, res] = await UtilService.callApiAsync(obj);
    if (res.code === 'OK') {
      self.setState({ success: true });
      if (res.message == 'Email is already verified.') self.setState({ alreadyVerified: true });
    } else {
      self.props.history.push('/signin');
    }
  };
  render() {
    return (
      <>
        {this.state.success && (
          <div>
            <div className="thankYouWrapper">
              <div className="thankYouInnerBox">
                <div className="thankYouMainBox">
                  <div className="thankYouImgBlock">
                    <img src={require('../assets/svg/ThankYou/1.svg')} alt="" />
                  </div>
                  <div className="thankYouPageContent">
                    {this.state.alreadyVerified ? (
                      <h2>Your email is already verified!</h2>
                    ) : (
                      <>
                        <h2>Thank you, your email address is verified.</h2>
                        <p>
                          Your MyDiamonds account is under review. Our support team will process your application soon
                          and get in touch with you if needed. You will receive a confirmation email post verification.
                        </p>
                      </>
                    )}

                    <button
                      onClick={() => {
                        this.props.history.push('/signin');
                      }}
                      className="commonButton"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(EmailVerify);
