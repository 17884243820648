import React from 'react';

import { COMMON_URL } from 'constants/CommonUrl';
import './fileUploaded.less';

const FileUploaded = (props) => {
  const { file } = props;
  let path = '';
  let fName = 'File';
  if (file) {
    if (file.name) {
      fName = file.name.split('/').pop();
      const ext = file.name.split('.').pop();
      if (ext === 'pdf' || ext === 'PDF') {
        path = require('assets/images/pdf.png');
      } else if (ext.toLowerCase() === 'mkv' || ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'wmv') {
        path = require('../../assets/images/video.svg');
      } else {
        path = COMMON_URL.BASE_URL + file.absolutePath;
      }
    } else {
      const ext = file.split('.').pop();
      if (ext.toLowerCase() === 'mkv' || ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'wmv') {
        path = require('assets/images/video.svg');
      } else {
        path = COMMON_URL.BASE_URL + file;
      }
      fName = file.split('/').pop();
    }
  }

  return (
    <>
      <div className={`fileUploadedBlockItem ${props.size}`}>
        <div className="fileUplodImg">
          <img src={path} alt="fileAdd" />
        </div>
        <div className="fileUplodContent">
          <p>{fName}</p>
          {!props?.closeFalse && (
            <div className="deleteIcon" onClick={props?.onDelete}>
              <img src={require('../../assets/svg/close-color.svg')} alt="Remove file" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default FileUploaded;
