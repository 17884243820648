import React from 'react';

import Modal from 'containers/CommonModal';

import { viewDetails } from 'services/DiamondBlockApi';

import * as Hooks from 'util/hooks';
import { isNotEmpty, returnIfNotEmpty } from 'util/utils';

import { TRANSACTION } from 'constants/Common';

import eyeGray from 'assets/svg/InventoryResult/view.svg';

function MemoDetailsCell({ row, inBlockDiamonds }) {
  const [detailsVisible, setDetailsVisibility] = Hooks.useBoolean();
  const [details, setDetails] = React.useState({});

  const getDetail = React.useCallback(async () => {
    const diamondId = row?.original?.id ?? row?.original?.diamondId;
    const [, res] = await viewDetails({ id: diamondId, blockType: TRANSACTION.MEMO });
    if (res?.code == 'OK') {
      const data = res?.data?.[0] ?? row?.original;
      const details = {
        user: data?.user,
        seller: data?.seller,
        broker: data?.broker,
        validTillDate: data?.validTillDate,
        comment: data?.remarks,
        sgiError: data?.sgiError,
      };
      setDetails(details);
      return setDetailsVisibility.true();
    } else {
      return setDetailsVisibility.false();
    }
  }, [row?.original]);

  // const details = React.useMemo(() => {
  //   const foundInBlock = inBlockDiamonds?.find(
  //     (record) => record?._id?.diamond === row?.original?.id && record?._id?.blockType === 2,
  //   );
  //   const data = foundInBlock?.blockDetails?.[0] ?? row?.original;

  //   return {
  //     user: data?.user,
  //     seller: data?.seller,
  //     broker: data?.broker,
  //     comment: data?.comment ?? data?.remarks,
  //     sgiError: data?.sgiError?.[0]?.Message,
  //     vStnId: data?.vStnId,
  //   };
  // }, [inBlockDiamonds, row?.original]);

  return (
    <div className="modal-details">
      <span title="View Memo Details" onClick={getDetail}>
        <img src={eyeGray} alt="View Memo Details" style={{ display: 'inline', height: '15px', width: '15px' }} />
      </span>
      <Modal
        visible={detailsVisible}
        onCancel={setDetailsVisibility.false}
        title="Memo Details"
        footer={null}
        destroyOnClose
        noDefaults
        centered
      >
        <div className="detailShow d-flex flex-wrap">
          <div className="detail separator">
            <span>Party: </span>
            <span>{returnIfNotEmpty(details.user?.account?.companyName, '-')}</span>
          </div>
          <div className="detail separator">
            <span>Buyer: </span>
            <span>
              {details?.user?.firstName || details?.user?.lastName
                ? returnIfNotEmpty(
                    [details?.user?.firstName, details?.user?.lastName].filter(isNotEmpty).join(' '),
                    '-',
                  )
                : returnIfNotEmpty(details?.user?.name, '-')}
            </span>
          </div>
          <div className="detail separator">
            <span>Seller: </span>
            <span>
              {returnIfNotEmpty(
                [details?.seller?.firstName, details?.seller?.lastName].filter(isNotEmpty).join(' '),
                '-',
              )}
            </span>
          </div>
          <div className="detail separator">
            <span>Broker: </span>
            <span>{returnIfNotEmpty([details?.broker?.companyName].filter(isNotEmpty).join(' '), '-')}</span>
          </div>
          {/* <div className="detail separator">
            <span>Comment: </span>
            <span>{returnIfNotEmpty(details?.comment, '-')}</span>
          </div> */}
          <div className="detail">
            <span>Message : </span>
            <span>{returnIfNotEmpty(details?.sgiErrorr, '-')}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

MemoDetailsCell.propTypes = {};

export default React.memo(MemoDetailsCell);
