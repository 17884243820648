import React from 'react';

import videoJs from 'video.js';

import { catchError, classNames, logWarn, typeOf } from 'util/utils';

import 'video.js/dist/video-js.css';

/**
 * VideoPlayer
 * Common Wrapper for VideoJs player
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export const VideoPlayer = React.memo((props) => {
  const { className, src, type, ...forwardProps } = props;
  return (
    <video style={{ width: "100%", height: "100%" }} src={src} type={type} controls></video>
  );
});

VideoPlayer.displayName = 'VideoPlayer';
