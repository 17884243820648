import React from 'react';

import { Dropdown, Tooltip } from 'antd';

import { classNames, isArray } from 'util/utils';

import { diamondParameters } from 'constants/Common';

import SortingFilterDropdown from './OverlayDropdownBack';

import { FILTER_COLUMNS, FLOAT_COLUMNS, ROUND_COLUMNS } from '../Inventory/table-config';

import shortinglistdownWhiteSvg from 'assets/svg/InventoryResult/shortinglistdown.svg';
import shortinglistdownSvg from 'assets/svg/InventoryResult/shortinglistdown.svg';
import shortinglistupWhiteSvg from 'assets/svg/InventoryResult/shortinglistup.svg';
import shortinglistupSvg from 'assets/svg/InventoryResult/shortinglistup.svg';
import closeSvg from 'assets/svg/close.svg';
import filterSvg from 'assets/svg/filter.svg';
import sortingSvg from 'assets/svg/sorting.svg';

function ListTableHeaderBack({ column, filters, noFilter, sortBy = [], setSortBy, toggleSortBy }) {
  const canFilter = React.useMemo(() => !noFilter && FILTER_COLUMNS.includes(column?.id), [column?.id, noFilter]);
  const isFiltered = React.useMemo(
    () => canFilter && isArray(filters) && filters.find((filter) => filter?.id === column?.id),
    [canFilter, filters, column],
  );

  const sortAsc = React.useMemo(() => column?.isSortedDesc === false, [column?.isSortedDesc]);
  const sortedColumnIndex = React.useMemo(() => column?.sortedIndex, [column?.sortedIndex]);
  const tooltipTitle = React.useMemo(() => {
    return (
      column?.desc ?? diamondParameters?.[column?.id]?.desc ?? diamondParameters?.[column?.id]?.name ?? column?.Header
    );
  }, [column]);

  const overlay = React.useMemo(
    () =>
      column?.isSorted || (!noFilter && FILTER_COLUMNS.includes(column?.id)) ? (
        <SortingFilterDropdown
          setSortBy={setSortBy}
          sortBy={sortBy}
          toggleSortBy={toggleSortBy}
          noFilter={noFilter}
          column={column}
        />
      ) : (
        <></>
      ),
    [noFilter, column, setSortBy, sortBy, toggleSortBy],
  );

  const ignoreClick = React.useCallback((e) => void (e.preventDefault(), e.stopPropagation()), []);
  const clearSortBy = React.useCallback((e) => void (ignoreClick(e), column.clearSortBy()), [ignoreClick, column]);

  return (
    <Dropdown
      overlayClassName={classNames(['tabelDropdownClass'])}
      key={`filterOverlayDropdown${column.id}`}
      overlay={overlay}
      trigger={['click']}
    >
      <div
        onClick={ignoreClick}
        className={classNames(['tableHeaderMain', isFiltered && 'filtered', !column.isSorted && 'shortingBoxShow'])}
      >
        {column?.sort && (
          <span className="listIcon">
            <img src={sortingSvg} alt="icon" />
          </span>
        )}
        {canFilter && (
          <span className="listIcon">
            <img src={filterSvg} alt="icon" />
          </span>
        )}
        {tooltipTitle ? (
          <Tooltip key={`headerDesc${tooltipTitle}`} title={tooltipTitle} placement="bottom">
            <span className="taleHeadTitle">{column.render('Header')}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{column.render('Header')}</span>
        )}
        {column?.isSorted ? (
          <div className="selectShortingMainBlock">
            <div className="selectShorting" {...column.getSortByToggleProps()}>
              <span className="selectShortingCount" style={!column.isSorted ? { margin: 0 } : {}}>
                {sortedColumnIndex + 1}
              </span>
              {column.isSorted && (
                <span className="selectShortingArrow">
                  <img src={sortAsc ? shortinglistupWhiteSvg : shortinglistdownWhiteSvg} />
                </span>
              )}
            </div>
            <div className="shortingClose" onClick={clearSortBy}>
              <img src={closeSvg} alt="icon" />
            </div>
          </div>
        ) : (
          column.sort && (
            <div className="shortingRound" {...column.getSortByToggleProps()}>
              <div className="shortingRoundInner">
                <img src={shortinglistupSvg} alt="icon" />
                <img src={shortinglistdownSvg} alt="icon" />
              </div>
            </div>
          )
        )}
      </div>
    </Dropdown>
  );
}

export default React.memo(ListTableHeaderBack);
