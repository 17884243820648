import { getZipPath } from 'services/DiamondApi';
import { MediaService } from 'services/MediaService';

import { Notify } from 'util/notify';
import { isString } from 'util/utils';

import { BASE_URL } from 'constants/Common';

import { LoaderService } from './LoaderService';

export class StoneMediaService {
  static async downloadZip(id, name) {
    LoaderService.startLoading();
    const [err, res] = await getZipPath({ diamonds: [id], zip: true });
    LoaderService.stopLoading();
    if (!err && isString(res?.data)) return MediaService.downloadFile(`${BASE_URL}/${res?.data?.[0]}`, name);
    Notify.error({ message: res?.message ?? 'Something went wrong' });
    return [true, err];
  }

  static async downloadVideo(data, name, options) {
    options = { returnUrl: false, ...options };
    const { id, memoDetail } = data;

    const request = memoDetail ? { orderDiamond: [memoDetail?.id ?? id] } : { diamonds: [id] };

    LoaderService.startLoading();
    const [err, res] = await getZipPath({ ...request, zip: false, mp4Video: true });
    LoaderService.stopLoading();

    return (res?.data || []).map(x => {
      if (!err && isString(x)) {
        name = x.split('/').pop() ?? name;
        if (options.returnUrl) return [false, `${BASE_URL}/${x}`];
        return MediaService.downloadFile(`${BASE_URL}/${x}`, name);
      }
    });
    Notify.error({ message: res?.message ?? 'Something went wrong' });
    return [true, err];
  }
}
