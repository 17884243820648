import React from 'react';

import { memoize } from 'lodash';

import CircularProgress from 'components/CircularProgress';

import { getMatchPair } from 'services/CommonApi';

import { useLoading } from 'util/hooks';
import { isArray, isEmpty } from 'util/utils';

import { FILE_URLS } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import './simiarstone.less';

function SimilarStone(props) {
  const [list, setList] = React.useState([]);
  const [loading, startLoading, stopLoading] = useLoading();

  const getMatchingStoneList = React.useCallback(async () => {
    if (isEmpty(props.id)) return;
    startLoading();
    const [err, res] = await getMatchPair(
      { diamonds: props.data?.memoNo ? props.data?.diamondId : props?.id },
      { notify: false },
    );
    const data = res?.data?.[0]?.data;
    if (!err && isArray(data) && !isEmpty(data)) {
      const filteredList = data.filter((stone) => stone?.id !== props?.id);
      if (!isEmpty(filteredList)) setList(filteredList);
    }
    stopLoading();
  }, [props.data.diamondId, props.data.memoNo, props.id, startLoading, stopLoading]);

  const handleClick = React.useMemo(
    () => memoize((vStnId) => () => window.open(PREFIX_URL_WITH_SLASH + '/diamond-details' + '/' + vStnId, '_blank')),
    [],
  );

  React.useEffect(() => {
    getMatchingStoneList();
  }, [getMatchingStoneList]);

  return (
    <div className="similarStoneWrapper">
      {loading && <CircularProgress />}
      {isArray(list) && !isEmpty(list) ? (
        list.map((stone) => (
          <div key={stone?.vStnId} className="similarStoneItem" onClick={handleClick(stone?.vStnId)}>
            <img src={FILE_URLS.img.replace('***', stone?.mfgStnId)} alt="No Image Found" />
            <span>
              {stone?.vStnId}<br></br>
              {stone?.shpNm} {stone?.crt} {stone?.colNm} {stone?.clrNm} {stone?.cutNm} {stone?.polNm}
              {stone?.symNm} {stone?.fluNm}
            </span>
          </div>
        ))
      ) : (
        <p className="no-msg">No similar stones found.</p>
      )}
    </div>
  );
}

export default React.memo(SimilarStone);
