import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useTable } from 'react-table';

import { Popconfirm } from 'antd';
import { DateTime } from 'luxon';

import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';

import { LIMIT, NoDataFound } from 'components/Inventory/Table';
import ListFooter from 'components/List/ListFooter';
import Pagination from 'components/List/Pagination';

import * as BannerAPI from 'services/BannerApi';
import { showNotification } from 'services/NotificationService';

import { isArray } from 'util/utils';

import { BANNER_TYPES, BANNER_TYPES_IMAGE, BASE_URL } from 'constants/Common';

const bannerTypes = Object.fromEntries(Object.entries(BANNER_TYPES).map((entries) => entries.reverse()));

class SliderList extends Component {
  state = {
    page: 1,
    limit: LIMIT,
    data: [],
    count: 0,
  };

  componentDidMount() {
    this.getList();
  }

  getList = async () => {
    this.setState({ loading: true });
    const { page, limit } = this.state;
    const [, res] = await BannerAPI.getBannerList({ page, limit, filter: { bannerType: BANNER_TYPES_IMAGE.Text } });

    const list = res?.data?.list;
    if (res.code === 'OK' && isArray(list)) {
      this.setState({ data: list, count: res.data.count, loading: false });
    }
    this.setState({ loading: false });
  };

  updateBannerDetail = async ({ id, ...request }) => {
    const [, res] = await BannerAPI.updateBanner(id, { ...request });
    if (res?.code === 'OK') {
      showNotification(res);
      this.getList();
    }
  };

  deleteBannerDetail = async (id) => {
    const [, res] = await BannerAPI.deleteBanner(id);
    showNotification(res);

    if (res?.code === 'OK') this.getList();
  };

  render() {
    const columns = [
      { Header: 'SR Number', width: '50px', accessor: 'srno', Cell: ({ row }) => ++row.id },
      {
        Header: 'Image',
        Cell: ({ row }) => (
          <div className="imageBlockWrapper">
            <img src={BASE_URL + row.original.images?.[0]} alt="" />
          </div>
        ),
      },
      {
        Header: 'Type',
        Cell: ({ row }) => (
          <div>
            <span>{bannerTypes[row.original.type]}</span>
          </div>
        ),
      },
      { Header: 'Title', accessor: 'eventName' },
      {
        Header: 'From Date',
        accessor: 'fromdateTime',
        Cell: ({ row }) => (
          <div>
            <span>{DateTime.fromISO(row.original.fromdateTime).toLocaleString(DateTime.DATETIME_SHORT)}</span>
          </div>
        ),
      },
      {
        Header: 'To Date',
        accessor: 'toDateTime',
        Cell: ({ row }) => (
          <div>
            <span>{DateTime.fromISO(row.original.toDateTime).toLocaleString(DateTime.DATETIME_SHORT)}</span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <div
            onClick={() =>
              this.updateBannerDetail({
                id: row.original.id,
                isActive: !row.original.isActive,
                name: row.original.name,
              })
            }
          >
            <StatusBlock
              status={row.original.isActive ? 'active' : 'InActive'}
              title={row.original.isActive ? 'Active' : 'InActive'}
            />
          </div>
        ),
      },
      { Header: 'Description', accessor: 'description' },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton j-flex-start">
            <div className="actionAction" title="Edit" onClick={() => this.props.editDetails(row.original.id)}>
              <img src={require('assets/svg/edit.svg')} />
            </div>
            <Popconfirm
              placement="left"
              title={`Are you sure you want to delete this Banner?`}
              okText={`Delete`}
              onConfirm={() => this.deleteBannerDetail(row.original.id)}
            >
              <div className="actionAction" title="Delete">
                <img src={require('assets/svg/delet.svg')} />
              </div>
            </Popconfirm>
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i} {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => (
                      <td key={i} width="100px" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <>
        <PlusIcon onClick={this.props.onClick} />
        <div className="contactDirectoryWrapper">
          <div className="contactListMain">
            <div className="contactList">
              <Table columns={columns} data={this.state.data} />
              <NoDataFound length={this.state.data?.length} />
            </div>
          </div>
        </div>
        <ListFooter homeSetting flush>
          <span style={{ margin: 'auto' }}>
            <Pagination
              page={this.state?.page}
              limit={this.state?.limit}
              count={this.state?.count}
              length={this.state?.data.length}
              onPaginationChange={(page, limit) => this.setState({ page, limit }, () => this.getList())}
            />
          </span>
        </ListFooter>
      </>
    );
  }
}

export default withRouter(SliderList);
