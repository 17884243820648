import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, NavLink, Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import CommonBackButton from 'containers/CommonBackButton';

import HomeSettings from 'components/Settings/HomeSettings';
import Master from 'components/Settings/Master';
import SubMaster from 'components/Settings/SubMaster';

import Storage from 'services/storage';
import { handleLogout } from 'services/util';

import classNames from 'util/classNames';
import { useCompositeState } from 'util/hooks';
// import Configuration from '../Configuration';
// import ContactDirectory from '../ContactDirectory';
// import MenuAccess from '../MenuAccess';
// import Notifications from '../NotificationsSetting';
import { isEmpty, isNotEmpty } from 'util/utils';

import { USERS_TYPE } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH as PREFIX_URL } from 'constants/CommonUrl';

import ChangePassword from './ChangePassword';
import DiscountSettings from './DiscountSettings';

import EditProfile from '../EditProfile';

export const SettingsRoutes = {
  root: `${PREFIX_URL}/setting`,
  configuration: `${PREFIX_URL}/setting/configuration`,
  notifications: `${PREFIX_URL}/setting/notifications`,
  profile: `${PREFIX_URL}/setting/profile`,
  contactDirectory: `${PREFIX_URL}/setting/contact-directory`,
  permission: `${PREFIX_URL}/setting/permission`,
  homeSetting: `${PREFIX_URL}/setting/home-setting`,
  submaster: `${PREFIX_URL}/setting/submaster`,
  master: `${PREFIX_URL}/setting/master`,
  changePassword: `${PREFIX_URL}/setting/change-password`,
  discountsettings: `${PREFIX_URL}/setting/discount-settings`,
};

function Settings({ location }) {
  const [user, setUser] = useCompositeState(Storage.get('user'));
  const path = React.useMemo(() => location.pathname, [location.pathname]);

  const userEventCallback = React.useCallback(
    (event) => {
      const user = event?.detail?.user;
      if (isEmpty(user)) return;
      setUser(user);
    },
    [setUser],
  );

  React.useEffect(() => {
    window.addEventListener('storage', userEventCallback);
    return () => {
      window.removeEventListener('storage', userEventCallback);
    };
  }, [userEventCallback]);

  const userType = user?.type == USERS_TYPE.SUPER_ADMIN;

  // let permission = getPermittedModuleList(MAINMODULE.SETTING, MODULES.SETTING.MY_SETTING.NAME);

  return (
    <div className="settingMainWrapper documentFileWrapper fullPageOpen">
      <ScrollArea className="settingLeftScroll" contentClassName="content" smoothScrolling>
        <div className="settingLeftBlock">
          <CommonBackButton link={`${PREFIX_URL}/dashboard`} />
          <h2 className="seetingMainTitle">Settings</h2>
          {userType && (
            <ul>
              <li>
                <NavLink to={SettingsRoutes.master}>Master</NavLink>
                <NavLink to={SettingsRoutes.submaster}>SubMaster</NavLink>
                {/* <NavLink to={SettingsRoutes.configuration}>Configuration</NavLink> */}
              </li>
            </ul>
          )}
          <div className="settingSpereter"></div>
          <ul>
            <h3 className="settingSubTitle">{[user?.firstName, user?.lastName].filter(isNotEmpty).join(' ')}</h3>
            <li>
              <NavLink to={SettingsRoutes.changePassword}>Change Password</NavLink>
            </li>
            {/* <li>
              <NavLink to={SettingsRoutes.profile}>My Settings</NavLink>
            </li> */}
            {/* <li>
              <NavLink to={SettingsRoutes.contactDirectory}>Contact Directory</NavLink>
            </li> */}
            {/* <li>
              <NavLink to={SettingsRoutes.notifications}>Notification</NavLink>
            </li> */}
            {userType && (
              <>
                <li>
                  <NavLink to={SettingsRoutes.homeSetting}>Home Settings</NavLink>
                </li>
                <li>
                  <NavLink to={SettingsRoutes.discountsettings}>Discount Settings</NavLink>
                </li>
              </>
            )}
            <li>
              <a onClick={() => handleLogout()}>Log out</a>
            </li>
          </ul>
          {/* <div className="settingSpereter"></div> */}
          {/* <ul>
            <h3 className="settingSubTitle">My Apps</h3>
            <li>
              <NavLink to={SettingsRoutes.permission}>Menu Permission</NavLink>
            </li>
            <li>
              <a to="/">Slack Notifications</a>
            </li>
            <li>
              <a to="/">Apps</a>
            </li>
          </ul> */}
        </div>
      </ScrollArea>
      <div className="settingRightBlock">
        <div
          className={classNames([
            `settingRightInneer`,
            [SettingsRoutes.master, SettingsRoutes.submaster].includes(path) && 'p-0',
          ])}
        >
          <Switch>
            {/* <Route path={SettingsRoutes.configuration} component={Configuration} /> */}
            {/* <Route path={SettingsRoutes.notifications} component={Notifications} /> */}
            <Route path={SettingsRoutes.profile} component={EditProfile} />
            {/* <Route path={SettingsRoutes.permission} component={MenuAccess} /> */}
            {/* <Route path={SettingsRoutes.contactDirectory} component={ContactDirectory} /> */}
            <Route path={SettingsRoutes.homeSetting} component={HomeSettings} />
            <Route path={SettingsRoutes.discountsettings} component={DiscountSettings} />
            <Route path={SettingsRoutes.master} component={Master} />
            <Route path={SettingsRoutes.submaster} component={SubMaster} />
            <Route path={SettingsRoutes.changePassword} component={ChangePassword} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default connect(({ auth }) => ({
  user: auth?.authUser,
}))(Settings);
