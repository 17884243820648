export const STAGE_KEYS = {
  S: 'sSts',
  W: 'wSts',
  SGI: 'sgiSts',
  WEB: 'webSts',
  INIT: 'initSts',
};

export const INIT_FILTER = {
  sSts: undefined,
  wSts: undefined,
  sgiSts: undefined,
  webSts: undefined,
  initSts: undefined,
};

export const STAGE_FILTERS = {
  // Available
  AVAILABLE: { ...INIT_FILTER, wSts: 'A' },
  NOT_AVAILABLE: { ...INIT_FILTER, wSts: 'NA' },
  // Best Buy
  BEST_BUY: { ...INIT_FILTER, sgiSts: { nin: ['I'] }, webSts: { nin: ['I'] }, wSts: 'D' },
  // Best Buy Memo
  BEST_BUY__MEMO: { ...INIT_FILTER, sgiSts: 'I', webSts: 'I', wSts: 'D' },
  BEST_BUY__SGI__MEMO: { ...INIT_FILTER, sgiSts: 'I', webSts: { nin: ['I'] }, wSts: 'D' },
  // Best Buy Hold
  HOLD__BEST_BUY: { ...INIT_FILTER, isBestBuy: ['Y', true], sSts: { nin: ['Y'] }, wSts: 'H' },
  SGI__HOLD__BEST_BUY: { ...INIT_FILTER, isBestBuy: ['Y', true], sSts: ['Y'], wSts: 'H' },
  // Hold
  HOLD: { ...INIT_FILTER, sSts: { nin: ['Y'] }, wSts: 'H' },
  SGI__HOLD: { ...INIT_FILTER, sSts: ['Y'], wSts: 'H' },
  HOLD__ORDER_PENDING: { ...INIT_FILTER, wSts: 'C' },
  // Memo
  MEMO: { ...INIT_FILTER, sgiSts: 'I', webSts: 'I', wSts: 'M' },
  SGI__MEMO: { ...INIT_FILTER, sgiSts: 'I', webSts: { nin: ['I'] }, wSts: 'M' },
  // Others
  BID: { ...INIT_FILTER, wSts: 'B' }, // Bid
  SOLD: { ...INIT_FILTER, wSts: 'O' }, // Sold
  SHOW: { ...INIT_FILTER, wSts: 'S' }, // Show
  UPCOMING: { ...INIT_FILTER, wSts: 'U' }, // Upcoming
};

export const STAGE_OPTIONS = [
  { label: 'All', color: 'transparent', key: 'ALL' },
  { label: 'Available', color: '#6bc950', key: 'AVAILABLE' },
  { label: 'Not Available', color: '#ff960b', key: 'NOT_AVAILABLE' },

  { label: 'Best Buy', color: '#ffc0cb', key: 'BEST_BUY' },
  { label: 'Best Buy/On Memo', color: '#ffc0cb', key: 'BEST_BUY__MEMO' },
  { label: 'Best Buy/SGI memo', color: '#ffc0cb', key: 'BEST_BUY__SGI__MEMO' },

  { label: 'Hold', color: '#db1c1c', key: 'HOLD' },
  { label: 'SGI Hold', color: '#db1c1c', key: 'SGI__HOLD' },
  { label: 'Hold/Order Pending', color: '#1c4e7f', key: 'HOLD__ORDER_PENDING' },
  { label: 'Hold/Best Buy', color: '#db1c1c', key: 'HOLD__BEST_BUY', hideEntry: true },
  { label: 'SGI Hold/Best Buy', color: '#db1c1c', key: 'SGI__HOLD__BEST_BUY', hideEntry: true },

  { label: 'On Memo', color: '#307bea', key: 'MEMO' },
  { label: 'SGI Memo', color: '#307bea', key: 'SGI__MEMO' },

  { label: 'Bid', color: '#ffc0cb', key: 'BID', hideEntry: true },
  { label: 'Sold', color: '#d98e21', key: 'SOLD', hideEntry: true },
  { label: 'Show', color: '#ff77ff', key: 'SHOW', hideEntry: true },
  { label: 'Upcoming', color: '#d2b48c', key: 'UPCOMING', hideEntry: true },
];
