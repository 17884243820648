import React, { Component } from 'react';

// import InputBlock from 'containers/InputBlock';
// import InputWithUpload from 'containers/InputWithUpload';
// import FileUploaded from 'containers/FileUploaded';
import { isEmpty, map, keys, findIndex, replace } from 'lodash';
// import { bussinessTypeArray } from '../../../../constants/Common';
import moment from 'moment';

import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
// import { DatePicker } from 'antd';
// import InputTag from 'containers/InputTag';
import TextArea from 'containers/TextArea';

import Storage from 'services/storage';

import { isArray, isFunction } from 'util/utils';

import { LEDGER_TYPE } from 'constants/Common';
import { approveStatus } from 'constants/Common';

import { sortArrayByKey } from '../../../../util/utils';
import BottomStickyButton from '../../BottomStickButton';

class BusinessDetail extends Component {
  state = {
    formData: {},
    errors: {},
    accountId: this.props?.account?.id,
    account: this.props.account,
    saveContinue: false,
  };

  masters = undefined;
  kycStatus = { NatureOrganization: undefined, businessTypes: undefined };

  getKycMaster = () => {
    this.masters = Storage.get('master');
    this.kycStatus = {
      NatureOrganization: this.masters?.NATURE_OF_ORG?.map((item) => ({ ...item, id: item.id[0] })),
      businessTypes: this.masters?.BUSINESS_TYPE?.map((item) => ({ ...item, id: item.id[0] })),
      personalDoc: this.masters?.DOC_TYPE_PERSONAL?.map((item) => ({ ...item, id: item.id[0] })),
      businessDoc: this.masters?.DOC_TYPE_BUSINESS?.map((item) => ({ ...item, id: item.id[0] })),
    };
  };

  handleInputChange = (key, value) => {
    if (key === 'founded' && value.isAfter(moment().endOf('year'))) return;
    if (key === 'age' && value.length > 3) return;
    const formData = this.state.formData;
    formData[key] = value;

    this.setState({ formData: formData });
  };

  handleKycChange = (name, value, key) => {
    const formData = this.state.formData;
    const kyc = [...formData.kyc];
    if (name === 'path' && isEmpty(value)) return;
    kyc[key][name] = value;

    this.setState({ formData: { ...formData, kyc } });
  };

  handleDelete = (key) => {
    const formData = this.state.formData;
    formData[key] = {};
    this.setState({ formData: formData });
  };

  handleValidation = () => {
    // const formData = this.state.formData;
    const formIsValid = true;
    const errors = {};
    // let data = { ...(isObject(formData) && formData) };

    // if (!data['businessType']) {
    //   formIsValid = false;
    //   errors['businessType'] = 'Please select business type.';
    // }
    // if (!data['natureOfOrg']?.length) {
    //   formIsValid = false;
    //   errors['natureOfOrg'] = 'Please select nature of organization.';
    // }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = async (e) => {
    if (isFunction(e?.preventDefault)) e.preventDefault();
    if (this.handleValidation()) {
      const formData = this.state.formData;
      map(keys(formData), (key) => {
        const value = formData[key];
        if (key === 'regCert' || key === 'panCert' || key === 'gstCert') {
          const num = replace(key, 'Cert', 'No');
          const obj = {
            documentType: key,
            path: value.absolutePath,
            detail: { [num]: formData[num] || '' },
          };
          if (!formData.kyc || !formData.kyc.length || formData.kyc.length === 0) {
            formData.kyc = [obj];
          } else {
            const indx = findIndex(formData.kyc, (k) => k.documentType === key);
            if (indx >= 0) {
              formData.kyc[indx] = obj;
            } else {
              formData.kyc.push(obj);
            }
          }
          delete formData[num];
          delete formData[key];
        }

        if (key === 'founded') {
          formData[key] = moment(value).format('YYYY');
        }

        if (!formData[key] || isEmpty(formData[key])) {
          delete formData[key];
        }
      });

      const data = (({ natureOfOrg, businessType, ledgerType, kyc }) => ({
        natureOfOrg,
        businessType,
        ledgerType,
        kyc,
      }))(formData);

      data.id = this.props?.account?.id;
      data.ledgerType = data?.ledgerType?.join(',');

      data.isApproved = data?.kyc?.filter((item) => item.status == '3').length
        ? 3
        : data?.kyc?.filter((item) => item.status == '1').length
        ? 1
        : data?.kyc?.filter((item) => item.status == '2').length == data?.kyc?.length
        ? 2
        : 3;

      if (data?.kyc)
        data.kyc = data?.kyc?.map((item) => {
          item.absolutePath = item?.path ? item.path.substring(item?.path.indexOf('/images/image')) : '';
          item.status = item.status ? item.status : Number(1);
          return item;
        });

      const [err, res] = await this.props.updateAccount(formData.id, data);
      if (!err && res?.code === 'OK') {
        if (this.state.saveContinue) this.props.updateSubTabIndex();
        else {
          this.props.onCancel();
        }
      }
      // this.setState({ formData: {} });
    }
  };

  componentDidMount() {
    this.getInitialValues();
    this.getKycMaster();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.id !== this.props.account.id) {
      this.getInitialValues();
      this.getKycMaster();
    }
  }

  initialValues = {
    natureOfOrg: undefined,
    businessType: undefined,
    ledgerType: undefined,
    kyc: [],
  };

  getInitialValues = () => {
    const account = { ...this.props.account };
    if (!account) return this.setState({ formData: this.initialValues });
    account.businessType = account.businessType?.id;
    account.ledgerType = account?.ledgerType?.split(',');
    if (isEmpty(account?.kyc)) {
      account.kyc = [
        { status: '1', type: 'personal' },
        { status: '1', type: 'business' },
      ];
    } else {
      const personal = account.kyc.find((item) => item.type == 'personal');
      const business = account.kyc.find((item) => item.type == 'business');
      account.kyc = [
        isEmpty(personal) ? { status: '1', type: 'personal' } : personal,
        isEmpty(business) ? { status: '1', type: 'business' } : business,
      ];
    }

    this.setState({
      accountId: account?.id,
      formData: Object.fromEntries(
        Object.entries(this.initialValues).map(([key, value]) => [
          key,
          isEmpty(account?.[key]) ? value : account?.[key],
        ]),
      ),
    });
  };

  render() {
    const { formData, errors } = this.state;

    return (
      <div {...this.props}>
        <div className="kycFormMainBlock">
          <div className="kycBlockWrapper mb-20">
            <SelectOption
              placeholder="Business Type"
              selectValue={this.kycStatus.businessTypes}
              onChange={(e) => {
                this.handleInputChange('businessType', e);
              }}
              label="Business Type"
              value={
                !isEmpty(formData?.businessType) &&
                this.kycStatus.businessTypes?.filter((item) => item.id == formData?.businessType).length
                  ? `${formData?.businessType}`
                  : undefined
              }
              valueKey="id"
              nameKey="name"
              error={errors?.businessType?.length > 0 && errors?.businessType}
            />
            <SelectOption
              className="multiSelect"
              selectValue={this.kycStatus.NatureOrganization}
              label="Nature of Organization"
              placeholder="Nature of Organization"
              mode="multiple"
              valueKey="id"
              nameKey="name"
              name="natureOfOrg"
              value={
                isArray(formData?.natureOfOrg) &&
                this.kycStatus.NatureOrganization?.filter((item) => formData?.natureOfOrg?.includes(item.id)).length > 0
                  ? formData?.natureOfOrg
                  : undefined
              }
              onChange={(e) => {
                this.handleInputChange('natureOfOrg', e);
              }}
              error={errors?.natureOfOrg?.length > 0 && errors?.natureOfOrg}
            />

            <SelectOption
              className="multiSelect"
              selectValue={LEDGER_TYPE}
              label="Ledger Type"
              placeholder="Ledger Type"
              mode="multiple"
              name="ledgerType"
              value={
                isArray(formData?.ledgerType) &&
                LEDGER_TYPE?.filter((item) => formData?.ledgerType?.includes(item.key)).length > 0
                  ? formData?.ledgerType
                  : undefined
              }
              onChange={(e) => {
                this.handleInputChange('ledgerType', e);
              }}
              error={errors?.ledgerType?.length > 0 && errors?.ledgerType}
            />
          </div>
          {formData?.kyc?.map((doc, i) => {
            const statusList = i == 0 ? this.kycStatus.personalDoc : this.kycStatus.businessDoc;
            const kycLabel = i == 0 ? 'Personal' : 'Business';

            return (
              <div key={i} className="mb-20">
                <div className="formSubTitle">{kycLabel} Document</div>
                <div className="formMainSubBlock">
                  <div className="d-flex flex-wrap mb-10 kycBlockWrapper">
                    <SelectOption
                      placeholder="Document Type"
                      label="Document Type"
                      selectValue={statusList}
                      onChange={(e) => this.handleKycChange('docType', e, i)}
                      value={doc?.docType}
                      valueKey="id"
                      nameKey="name"
                    />
                    <SelectOption
                      placeholder="Status"
                      label="Approve Status"
                      selectValue={approveStatus}
                      onChange={(e) => this.handleKycChange('status', e, i)}
                      value={doc?.status ? `${doc?.status}` : '1'}
                    />
                    <InputBlock
                      placeholder="Document Info"
                      label="Document Info"
                      type="text"
                      value={doc?.info}
                      onChange={(e) => {
                        this.handleKycChange('info', e.target.value, i);
                      }}
                    />
                    <TextArea
                      label="Remark"
                      onChange={(e) => {
                        this.handleKycChange('remark', e.target.value, i);
                      }}
                      value={doc?.remark}
                    />

                    <div className="fileUploadedBlock d-flex from-group">
                      <FileUpload
                        name="mobileImage"
                        label="Upload Mobile Image"
                        isValidate
                        accept="JPG,JPEG,PNG,GIF"
                        size="JPG,JPEG,PNG,GIF file allowed"
                        files={doc?.absolutePath && [doc?.absolutePath]}
                        onChange={(image) => this.handleKycChange('path', image, i)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="kycBlockWrapper mb-20">
            <div className="from-group inputBlock">
              <label label="" placeholder="">
                Establishment Year
              </label>
              <DatePicker
                mode="year"
                format="YYYY"
                onPanelChange={(v) => this.handleInputChange('founded', moment(v))}
                onChange={(date) => this.handleInputChange('founded', date)}
                value={formData.founded ? moment(formData.founded) : moment()}
                disabledDate={(d) => d.isAfter(moment().endOf('year'))}
              />
            </div>

            <InputBlock
              onChange={(e) => this.handleInputChange('age', e.target.value)}
              value={formData.age || ''}
              type="number"
              label="Age of Firm"
              placeholder="Age of Firm"
            />
            <InputTag label="Trade Body Membership" placeholder="Trade Body Membership" />
          </div>
          <div className="formSubTitle">Minimum Two with Uploads</div>
          <div className="formMainSubBlock">
            <div className="d-flex flex-wrap mb-10">
              <div className="formSubBlockItem">
                <InputWithUpload
                  label="Businees Registration No"
                  placeholder="Businees Registration No"
                  onChange={(e) => this.handleInputChange('regNo', e.target.value)}
                  value={formData.regNo}
                  onUpload={(e) => this.handleInputChange('regCert', e)}
                  uploade
                  disabled={!isEmpty(formData.regCert) ? true : false}
                />
              </div>
              <div className="formSubBlockItem">
                <InputWithUpload
                  label="Pan No./Tac Id No"
                  onChange={(e) => this.handleInputChange('panNo', e.target.value)}
                  value={formData.panNo}
                  onUpload={(e) => this.handleInputChange('panCert', e)}
                  placeholder="Pan No./Tac Id No"
                  uploade
                  disabled={!isEmpty(formData.panCert) ? true : false}
                />
              </div>
              <div className="formSubBlockItem">
                <InputWithUpload
                  label="GSTIn No"
                  onChange={(e) => this.handleInputChange('gstNo', e.target.value)}
                  value={formData.gstNo}
                  onUpload={(e) => this.handleInputChange('gstCert', e)}
                  placeholder="GSTIn No"
                  uploade
                  disabled={!isEmpty(formData.gstCert) ? true : false}
                />
              </div>
            </div>
            <div className="fileUploadedBlock">
              {!isEmpty(formData.regCert) && (
                <FileUploaded onDelete={() => this.handleDelete('regCert')} file={formData.regCert} />
              )}
              {!isEmpty(formData.panCert) && (
                <FileUploaded onDelete={() => this.handleDelete('panCert')} file={formData.panCert} />
              )}
              {!isEmpty(formData.gstCert) && (
                <FileUploaded onDelete={() => this.handleDelete('gstCert')} file={formData.gstCert} />
              )}
            </div>
          </div> */}
        </div>
        <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
          <button
            className="commonButton"
            type="button"
            onClick={(e) => {
              this.setState({ saveContinue: true }, () => this.handleSubmit(e));
            }}
          >
            Save & Continue
          </button>
          <button
            className="commonButton"
            type="button"
            onClick={(e) => {
              this.setState({ saveContinue: false }, () => this.handleSubmit(e));
            }}
          >
            Save
          </button>
          <button className="commonOutine" type="reset" onClick={this.props.onCancel}>
            Cancel
          </button>
        </BottomStickyButton>
      </div>
    );
  }
}

export default BusinessDetail;
