import UtilService from 'services/util';

import ConstantApi from 'constants/CommonApi';

export const getVersionList = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Version.paginate,
    request,
  });
};

export const storeVersion = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Version.create,
    request,
  });
};

export const findVersion = (id, request = {}) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Version.find, [id]),
    request,
  });
};

export const updateVersion = (request = {}) => {
  return UtilService.callApiAsync({
    ...ConstantApi.Version.update,
    request,
  });
};

export const deleteVersion = (id) => {
  return UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Version.delete, [id]),
  });
};
