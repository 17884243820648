import React, { Component } from 'react';

import * as BannerAPI from 'services/BannerApi';

import SliderForm from './form';
import SliderList from './list';

class Slider extends Component {
  state = { list: true, detail: {} };

  toggle = () => this.setState({ list: !this.state.list, detail: {} });

  editDetails = async (editId) => {
    const [, res] = await BannerAPI.findBanner(editId);
    if (res?.code === 'OK') {
      this.setState({ detail: res.data, list: !this.state.list });
    }
  };

  render() {
    return this.state.list ? (
      <SliderList onClick={this.toggle} editDetails={this.editDetails} />
    ) : (
      <SliderForm closeForm={this.toggle} update={this.state.detail} />
    );
  }
}

export default Slider;
