import React from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';

import { getSettings, updateSettings } from 'services/CommonApi';
import { showNotification } from 'services/NotificationService';

import { isEmpty, isObject } from 'util/utils';

import { SETTING_TYPE } from 'constants/setting';

const defaultValues = {
  minHoldLimitHour: 0,
  maxHoldLimitHour: 0,
};

const validationSchema = Yup.object().shape({
  minHoldLimitHour: Yup.number().required('* Required').nullable(),
  maxHoldLimitHour: Yup.number().required('* Required').nullable(),
});

const HoldSetting = () => {
  const [setting, setSetting] = React.useState({});

  const initialValues = React.useMemo(() => {
    const data = Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [key, isEmpty(setting?.[key]) ? value : setting?.[key]]),
    );
    return data;
  }, [setting]);

  const getList = React.useCallback(async () => {
    const [, res] = await getSettings({ type: SETTING_TYPE.HOLD_SETTINGS });

    if (res.code === 'OK' && isObject(res?.data?.settings)) {
      setSetting(res?.data?.settings);
    }
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  const submitForm = React.useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    const [err, res] = await updateSettings({ type: SETTING_TYPE.HOLD_SETTINGS, settings: values });
    setSubmitting(false);
    !err && showNotification(res);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ handleChange, handleBlur, touched, errors, values }) => (
        <Form>
          <div className="d-flex flex-wrap homeSettingWrapper">
            <InputBlock
              type="number"
              name="minHoldLimitHour"
              label="Min Hold Limit Hour"
              placeholder="Min Hold Limit Hour"
              value={values.minHoldLimitHour}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.minHoldLimitHour && errors?.minHoldLimitHour}
            />

            <InputBlock
              name="maxHoldLimitHour"
              label="Max Hold Limit Hour"
              placeholder="Max Hold Limit Hour"
              type="number"
              value={values.maxHoldLimitHour}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.maxHoldLimitHour && errors?.maxHoldLimitHour}
            />
            <div className="width-100 d-flex mt-40">
              <button className="commonButton mr-10" type="submit" value="Submit">
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HoldSetting;
