import React from 'react';
import { TabPanel } from 'react-tabs';

import { find, groupBy } from 'lodash';
import QRCode from 'qrcode.react';

import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import Heading from 'containers/MainHeading';
import CustomTab from 'containers/Tab';

import CircularProgress from 'components/CircularProgress';
import DiamondDetailAction from 'components/DiamondDetail/Action';
import detailView from 'components/DiamondDetail/DetailViewAuth';
import Embed from 'components/DiamondDetail/Embed';
import ImageSlider from 'components/DiamondDetail/ImageSlider';
import NotAvailable from 'components/DiamondDetail/NoDataFoundComponent';
import SimilarStone from 'components/DiamondDetail/SimilarStone';
import DownloadAction from 'components/DiamondListing/Actions/DownloadAction';
import ShareAction from 'components/DiamondListing/Actions/ShareAction';
import ListFooter from 'components/List/ListFooter';
import { VideoPlayer } from 'components/MediaPlayer/VideoPlayer';

import { downloadPdfFile } from 'services/DiamondApi';
import { createDiamondTrack } from 'services/DiamondApi';
import { MediaService } from 'services/MediaService';
import { StoneMediaService } from 'services/StoneMediaService';
import { callApiAsync, withUrlParams } from 'services/util';

import { Notify } from 'util/notify';
import { formatDecimal, isEmpty, isFunction, isNotEmpty } from 'util/utils';

import { getMediaUrl, MEDIA_TYPES } from 'constants/Common';
import { API_ROUTES } from 'constants/CommonApi';
import { COMMON_URL, PREFIX_URL_WITH_SLASH as PREFIX_URL } from 'constants/CommonUrl';

import certSvg from 'assets/svg/certi.svg';
import copyLinkThemeSvg from 'assets/svg/copylink-theme.svg';
import copyLinkWhiteSvg from 'assets/svg/copylink-white.svg';
import directBuySvg from 'assets/svg/diamondDetail/direct-buy.svg';
import downloadZipSvg from 'assets/svg/diamondDetail/download-zip.svg';
import printSvg from 'assets/svg/diamondDetail/print.svg';
import shareSvg from 'assets/svg/diamondDetail/share.svg';
import downloadThemeSvg from 'assets/svg/download-theme.svg';
import downloadWhiteSvg from 'assets/svg/download-white.svg';
import videoSvg from 'assets/svg/video.svg';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'styles/qr.less';

export const DiamondDetailRoute = {
  root: `${PREFIX_URL}/diamond-details/:id?direct-share`,
};

const DIAMOND_MAIN_TABS = [{ index: 0, tabTitle: 'Details' }];

class DiamondDetail extends React.PureComponent {
  state = {
    tabIndex: 0,
    subIndex: 0,
    slideNo: 0,
    commentPopup: false,
    columns: [],
    sendEmail: false,
    visible: false,
    btnName: '',
    nav1: null,
    nav2: null,
    image: { main: true, light: true },
    hAImage: { arrowFile: true, hAFile: true },
    sliderImageCount: 2,
    hAImageCount: 2,
    islogin: true,
    videoDone: false,
    certiDone: false,
    videoError: false,
    certiError: false,
    id: null,
    loader: true,
    flags: {
      img: true,
      faceUp: true,
      videoFile: true,
      mp4Video: true,
      arrowFile: true,
      hAFile: true,
      certFile: true,
    },
  };

  get data() {
    const { data } = { ...this.state, ...this.props };
    return data;
  }

  get showMp4Video() {
    return true;
  }

  get DIAMOND_DETAIL_TABS() {
    return [
      { index: 0, tabTitle: 'Image' },
      { index: 1, tabTitle: 'Video' },
      this.showMp4Video ? { index: 2, tabTitle: 'MP4' } : {},
      { index: 3, tabTitle: 'H&A' },
      { index: 4, tabTitle: 'Certificate' },
    ].filter(isNotEmpty);
  }

   getList = (vStnId, mfgStnId, rptNo, showMp4Video) => [
    ['img', getMediaUrl(MEDIA_TYPES.JPG_STILL, mfgStnId), true],
    ['faceUp', getMediaUrl(MEDIA_TYPES.JPG_LIGHT_BLACK, mfgStnId), true],
    ['videoFile', getMediaUrl(MEDIA_TYPES.HTML_VIDEO_360, mfgStnId), true],
    ['mp4Video', getMediaUrl(MEDIA_TYPES.MP4_VIDEO_360, vStnId), showMp4Video],
    ['arrowFile', getMediaUrl(MEDIA_TYPES.JPG_HEART_BLACK, mfgStnId), true],
    ['hAFile', getMediaUrl(MEDIA_TYPES.JPG_ARROW_BLACK, mfgStnId), true],
    ['certFile', getMediaUrl(MEDIA_TYPES.PDF_LAB_CERT, rptNo), true],
  ];
  
   fetchEntries = async (list) => {
    return Promise.all(
      list.map(async ([key, url, show]) => {
        if (!show) return [key, false];
        const headers = await MediaService.getHeaders(url, true);
        return [key, Boolean(headers)];
      }),
    );
  };
  
  checkAllFiles = async () => {
    const { vStnId, mfgStnId, rptNo } = this.data;
    const list = this.getList(vStnId, mfgStnId, rptNo, this.showMp4Video);
    const entries = await this.fetchEntries(list);
    this.setState({ flags: Object.fromEntries(entries) });
  };
  componentDidMount = async () => {
    const id = window.location.pathname?.split?.('/').pop();
    if (!['diamond-details', 'diamond-detail'].includes(id) && !this.props.inDiamondPopup) {
      await this.getData(id);
    }
    const { vStnId, mfgStnId, rptNo } = this.data;
    const list = this.getList(vStnId, mfgStnId, rptNo, this.showMp4Video);
    const entries = await this.fetchEntries(list);
    const entriesObj = Object.fromEntries(entries);
    const { img, faceUp, videoFile } = entriesObj;
    this.setState({ loader: true });

    this.setState(
      {
        loader: false,
        nav1: this.slider1,
        nav2: this.slider2,
        subIndex: img || faceUp ? 0 : videoFile ? 1 : 2,
        columns: this.props.columns?.filter((el) => !find(['dna', 'action'], (name) => name == el.id)) ?? [],
      },
      async () => {
        await this.checkAllFiles();
      },
    );
  };

  getData = async (id) => {
    const [err, res] = await callApiAsync(withUrlParams(API_ROUTES.Diamond?.view, [id]));
    if (!err && res?.code === 'OK') this.setState({ data: res?.data, id: res?.data?.id });
  };

  handleInsertTrack = async (trackType) => {
    const request = {
      user: '',
      trackType,
      diamonds: {
        diamond: this.data.id,
        trackPricePerCarat: this.data.ctPr,
        trackAmount: this.data.amt,
      },
    };
    const [err, res] = await createDiamondTrack(request);

    if (!err && res?.code === 'OK') Notify.success({ message: res?.message });
  };

  directShare = () => {
    if (['A', 'D'].includes(this.data.wSts ?? this?.data?.wSts)) {
      this.setState({ btnName: 'drshare', visible: !this.state.visible });
    } else {
      Notify.info({ message: 'Direct share is only allowed on Available and Best Buy diamonds' });
    }
  };

  handleDownload = async () => {
    const data = this.data;
    const { mfgStnId, rptNo } = data;

    const tabIndex = `${this.state.subIndex}`;
    const slideIndex = `${this.state.slideNo}`;

    switch (tabIndex) {
      case '0':
        slideIndex === '0'
          ? MediaService.downloadFile(getMediaUrl(MEDIA_TYPES.JPG_STILL, mfgStnId), `${mfgStnId}-still.jpg`)
          : MediaService.downloadFile(
            getMediaUrl(MEDIA_TYPES.JPG_LIGHT_BLACK, mfgStnId),
            `${mfgStnId}-light-black.jpg`,
          );
        break;

      case '1':
      case '2':
        StoneMediaService.downloadVideo(data, `video-${mfgStnId}.mp4`);
        break;

      case '3':
        slideIndex === '0'
          ? MediaService.downloadFile(getMediaUrl(MEDIA_TYPES.JPG_HEART_BLACK, mfgStnId), `${mfgStnId}-heart.jpg`)
          : MediaService.downloadFile(getMediaUrl(MEDIA_TYPES.JPG_ARROW_BLACK, mfgStnId), `${mfgStnId}-arrow.jpg`);
        break;

      case '4':
        MediaService.downloadFile(getMediaUrl(MEDIA_TYPES.PDF_LAB_CERT, data?.rptNo), `certi-${rptNo}.pdf`);
        break;

      default:
        break;
    }
  };

  handleCopyLink = () => {
    const { vStnId, mfgStnId, rptNo } = this.data;
    const tabIndex = `${this.state.subIndex}`;
    const slideIndex = `${this.state.slideNo}`;
    let value = '';

    switch (tabIndex) {
      case '0':
        value =
          slideIndex === '0'
            ? getMediaUrl(MEDIA_TYPES.JPG_STILL, mfgStnId)
            : getMediaUrl(MEDIA_TYPES.JPG_LIGHT_BLACK, mfgStnId);
        break;

      case '1':
        value = getMediaUrl(MEDIA_TYPES.HTML_VIDEO_360, mfgStnId);
        break;

      case '2':
        value = getMediaUrl(MEDIA_TYPES.MP4_VIDEO_360, vStnId);
        break;

      case '3':
        value =
          slideIndex === '0'
            ? getMediaUrl(MEDIA_TYPES.JPG_HEART_BLACK, mfgStnId)
            : getMediaUrl(MEDIA_TYPES.JPG_ARROW_BLACK, mfgStnId);
        break;

      case '4':
        value = getMediaUrl(MEDIA_TYPES.PDF_LAB_CERT, rptNo);
        break;

      default:
        break;
    }

    navigator.clipboard.writeText(value);
    return Notify.success({ message: 'Link Copied' });
  };

  render() {
    const { vStnId, mfgStnId, rptNo } = this.data;
    const path = window.location.href?.split?.('?')?.pop?.();
    const flags = { ...this.data, ...this.state.flags };

    if (this.state.loader) return <CircularProgress className="fixLoader" />;

    return (
      <div className="diamondDetailWrapper">
        <div className="qrCodeOption">
          <QRCode size={50} value={`${COMMON_URL.FRONT_SITE_URL}/diamond-detail/${this.data.vStnId}`} level="L" />
          <span>QR Code</span>
        </div>
        <CustomTab
          onGetIndex={(tabIndex) => this.setState({ tabIndex })}
          selectedIndex={this.state.tabIndex}
          data={DIAMOND_MAIN_TABS}
          tabHeaderClass="customerMaintab diamondDetailTopTab"
          wantIndex
        >
          <div className="diamondDetailInner">
            <div className="diamondDetailBottomBox">
              <div className="diamondDetailBottomAction">
                {DiamondDetailRoute.root.includes(path) ? (
                  <div className="d-flex">
                    <DiamondDetailAction imagenull title="Buy Now" tooltip="Buy Now" placement="bottomLeft" />
                  </div>
                ) : this.state.islogin ? (
                  <div className="d-flex">
                    <DiamondDetailAction image={directBuySvg} tooltip="Direct Buy" onClick={this.directShare} />
                    <DiamondDetailAction
                      image={downloadZipSvg}
                      tooltip="Download"
                      placement="bottomLeft"
                      onClick={() => this.setState({ visible: !this.state.visible, btnName: 'download' })}
                      visible={this.state.visible}
                    />
                    <DiamondDetailAction
                      image={shareSvg}
                      tooltip="Share"
                      onClick={() => this.setState({ btnName: 'share', visible: !this.state.visible })}
                    />
                    <DiamondDetailAction
                      image={printSvg}
                      tooltip="Print"
                      onClick={() =>
                        downloadPdfFile([this.data], null, null, null, (loader) => this.setState({ loader }))
                      }
                    />
                    {this.state.visible && (
                      <>
                        {this.state.btnName == 'download' && (
                          <DownloadAction
                            {...this.data}
                            checked={[this.data]}
                            tabIndex={this.state.subIndex}
                            tabPrintExcel={this.props?.tabPrintExcel}
                            onClose={() => this.setState({ visible: !this.state.visible })}
                          />
                        )}
                        {(this.state.btnName == 'share' || this.state.btnName === 'drshare') && (
                          <ShareAction
                            {...this.data}
                            checked={[this.data]}
                            tabIndex={this.state.subIndex}
                            btnName={this.state.btnName}
                            unCheckedDiamond={() => { }}
                            onClose={() => this.setState({ visible: !this.state.visible })}
                          />
                        )}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <TabPanel className="diamondDetailMainTab">
              <div className="detailHalfWhite"></div>
              <div className="detailHalfGray"></div>
              <div className="diamondDetailLeft">
                <CustomTab
                  data={this.DIAMOND_DETAIL_TABS}
                  selectedIndex={this.state.subIndex}
                  tabHeaderClass="customerMaintab"
                  onGetIndex={(ind) => this.setState({ subIndex: ind })}
                  wantIndex
                >
                  <GridHeightContainer
                    className="diamondDetailInnerDetail"
                    subtractFrom=".diamondDetailBottomBox,.customerMaintab,.botoomStickyBar"
                    adjustment={-12}
                  >
                    <TabPanel>
                      <ImageSlider
                        list={[
                          { available: flags?.img, src: getMediaUrl(MEDIA_TYPES.JPG_STILL, mfgStnId) },
                          {
                            available: flags?.faceUp,
                            src: getMediaUrl(MEDIA_TYPES.JPG_LIGHT_BLACK, mfgStnId),
                          },
                        ]}
                        beforeChange={(_, slideNo) => this.setState({ slideNo })}
                      />
                    </TabPanel>
                    <TabPanel>
                      <div className="diamondDetailImageBox htmlViewImage">
                        {flags?.videoFile ? (
                          <Embed src={getMediaUrl(MEDIA_TYPES.HTML_VIDEO_360, mfgStnId)} width="100%">
                            <NotAvailable className="nodatFoundImage" src={videoSvg} type="Video" />
                          </Embed>
                        ) : (
                          <NotAvailable className="nodatFoundImage" src={videoSvg} type="Video" />
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      {this.showMp4Video && (
                        <div className="diamondDetailImageBox htmlViewImage">
                          {flags?.mp4Video ? (
                            <VideoPlayer
                              aspectRatio="4:3"
                              src={getMediaUrl(MEDIA_TYPES.MP4_VIDEO_360, vStnId)}
                              height="100%"
                              width="100%"
                              responsive
                              controls
                              autoplay
                              loop
                            />
                          ) : (
                            <NotAvailable className="nodatFoundImage" src={videoSvg} type="Video" />
                          )}
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <ImageSlider
                        list={[
                          {
                            available: flags?.hAFile,
                            src: getMediaUrl(MEDIA_TYPES.JPG_HEART_BLACK, mfgStnId),
                          },
                          {
                            available: flags?.arrowFile,
                            src: getMediaUrl(MEDIA_TYPES.JPG_ARROW_BLACK, mfgStnId),
                          },
                        ]}
                        beforeChange={(_, slideNo) => this.setState({ slideNo })}
                      />
                    </TabPanel>
                    <TabPanel>
                      <div className="diamondDetailImageBox">
                        {flags?.certFile ? (
                          <Embed src={getMediaUrl(MEDIA_TYPES.PDF_LAB_CERT, rptNo)} height="100%" width="100%" loadBlob>
                            <NotAvailable className="nodatFoundImage" src={certSvg} type="Certificate" />
                          </Embed>
                        ) : (
                          <NotAvailable className="nodatFoundImage" src={certSvg} type="Certificate" />
                        )}
                      </div>
                    </TabPanel>
                    <ListFooter>
                      <div
                        role="button"
                        className="diamondDetailSperateBlock commonOutine"
                        onClick={this.handleDownload}
                        style={{ marginRight: '0.5rem' }}
                      >
                        <img className="defualtImg" src={downloadThemeSvg} alt="" />
                        <img className="hoverImg" src={downloadWhiteSvg} alt="" />
                        <span>Download</span>
                      </div>
                      <div
                        className="diamondDetailSperateBlock commonOutine"
                        onClick={this.handleCopyLink}
                        style={{ marginLeft: '0.5rem' }}
                      >
                        <img className="defualtImg" src={copyLinkThemeSvg} alt="" />
                        <img className="hoverImg" src={copyLinkWhiteSvg} alt="" />
                        <span>Copy Link</span>
                      </div>
                    </ListFooter>
                  </GridHeightContainer>
                </CustomTab>
              </div>
              <div className="diamondDetailRight">
                <div className="diamondDetailMore">
                  <div className="diamondDetailMoreHalf">
                    {Object.values(detailView).map((section) => {
                      const grouppedByPart = groupBy(section?.data, 'part');

                      return (
                        <React.Fragment key={section}>
                          <h2 className="detailSmallTitle">{section?.name}</h2>
                          <div className="diamondDetailMoreHalf">
                            {Object.values(grouppedByPart).map((fields) => {
                              return fields.map((field) => {
                                return (
                                  <ul key={field?.key}>
                                    <li>
                                      <span>{field?.label}</span>
                                      <span>
                                        {isFunction(field?.render)
                                          ? field?.render(this.data?.[field?.key], this.data)
                                          : field?.isFormat
                                            ? formatDecimal(this.data?.[field?.key])
                                            : field?.key === 'colNm' && this.data?.isFcCol
                                              ? this.data?.fcColDesc
                                              : field?.key === 'cutNm'
                                                ? this.data?.shpNm === 'ROUND'
                                                  ? this.data?.[field?.key]
                                                  : '-'
                                                : this.data?.[field?.key]
                                                  ? this.data?.[field?.key]
                                                  : '-'}
                                      </span>
                                    </li>
                                  </ul>
                                );
                              });
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="similarStoneBlock">
                  <Heading className="popupInnerTitle">Similar Stone</Heading>
                  <SimilarStone id={this.data?.id ?? this.state.id} data={this.data} />
                </div>
              </div>
            </TabPanel>
          </div>
        </CustomTab>
      </div>
    );
  }
}

export default DiamondDetail;
