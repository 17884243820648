import React from 'react';
import { Link } from 'react-router-dom';

import { PREFIX_URL_WITH_SLASH as PREFIX_URL } from 'constants/CommonUrl';

const SingleStone = () => {
  const { pathname } = window.location;
  const path = React.useMemo(() => pathname, [pathname]);

  return (
    <div className="subTabInnerDropdown">
      <ul>
        <li className={path === `${PREFIX_URL}/inventory/single-stone/saved-search` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/saved-search`}>Saved Search</Link>
        </li>
        <li className={path === `${PREFIX_URL}/inventory/single-stone/demandss` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/demands`}>Demands</Link>
        </li>
        <li className={path === `${PREFIX_URL}/inventory/single-stone/match-pair` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/match-pair`}>Match Pair</Link>
        </li>
        <li className={path === `${PREFIX_URL}/inventory/single-stone/offer-history` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/offer-history`}>Offer History</Link>
        </li>
        {/* <li className={path === `${PREFIX_URL}/inventory/single-stone/calibrated` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/calibrated`}>Calibrated</Link>
        </li>
        <li className={path === `${PREFIX_URL}/inventory/single-stone/price-configuration` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/price-configuration`}>Price Configuration</Link>
        </li>
        <li className={path === `${PREFIX_URL}/inventory/single-stone/check-image` ? 'active' : ''}>
          <Link to={`${PREFIX_URL}/inventory/single-stone/check-image`}>Check Image</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default SingleStone;
