import React, { useState } from 'react';

import CommonModal from 'containers/CommonModal';

import { downloadZip } from 'services/DiamondApi';

import './action.less';
import CircularProgress from 'components/CircularProgress';

import { isFunction } from 'util/utils';

import OpenNotification from 'constants/OpenNotifications';

const DownloadAction = (props) => {
  const [isExcel, setIsExcel] = useState(true);
  const [isCert, setIsCert] = useState(`${props?.tabIndex}` === '3');
  const [isImg, setIsImg] = useState(`${props?.tabIndex}` === '0');
  const [isVideo, setIsVideo] = useState(`${props?.tabIndex}` === '1');
  const [isPlt, setIsPlt] = useState(`${props?.tabIndex}` === '2');
  const [isHa, setIsHa] = useState(`${props?.tabIndex}` === '2');
  const [isAsset, setIsAsset] = useState(false);
  const [isLightImg, setIsLightImg] = useState(`${props?.tabIndex}` === '0');

  const [loader, setLoader] = useState(false);

  const ShareOption = (props) => {
    return (
      <li
        onClick={() => {
          //handling one or more CHECKED TOGGLE
          props.onChange(!props.id);
        }}
        className={props.activeClass}
      >
        <div className="dropdownBottomItem">
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };

  const handleDownloadClick = () => {
    if (!isImg && !isCert && !isVideo && !isHa && !isPlt && !isAsset && !isLightImg)
      return OpenNotification({
        type: 'error',
        title: 'Please select atleast one option',
      });
    setLoader(true);
    let ids = props.checked.map((item) => item.vStnId);
    ids = ids.length ? ids : props.data.map((item) => item.vStnId);

    const obj = {
      filter: {
        vStnId: ids,
        isFcCol: false,
      },
      // isExcel: isExcel,
      img: isImg,
      certFile: isCert,
      mp4Video: isVideo,
      hAFile: isHa,
      arrowFile: isPlt,
      assetFile: isAsset,
      faceUp: isLightImg,
      zip:
        [isImg, isCert, isVideo, isHa, isPlt, isAsset, isLightImg].filter((value) => value).length > 1 ||
        ids.length > 1,
    };
    if (!props.checked.length && props.seachFilterTag && props.seachFilterTag.searchData) {
      obj.filter = props.seachFilterTag.searchData;
    }
    // if (props.tabPrintExcel == 'inOrder') {
    //   obj.orderDiamond = obj.filter;
    //   delete obj['filter'];
    // }
    downloadZip(obj, () => {
      setLoader(false);
      props.onClose();
      if (isFunction(props.unCheckedDiamond)) props.unCheckedDiamond();
    });
  };

  return (
    <>
      <CommonModal className="md-size" title="Download" handleCancel={props.onClose} visible={true}>
        <div>
          <div className="searchPopupCommon">
            <div className="shareOptionSet d-flex">
              <div className="shareOptionSetItem">
                <ul>
                  {/* {!props.isSearchTab && (
                    <ShareOption
                      id={isExcel}
                      onChange={setIsExcel}
                      activeClass={isExcel === true ? 'active' : ''}
                      title="Excel"
                    />
                  )} */}
                  <ShareOption
                    id={isCert}
                    onChange={setIsCert}
                    activeClass={isCert === true ? 'active' : ''}
                    title="Certificate"
                  />
                  <ShareOption
                    id={isImg}
                    onChange={setIsImg}
                    activeClass={isImg === true ? 'active' : ''}
                    title="Real Image"
                  />
                  <ShareOption id={isHa} onChange={setIsHa} activeClass={isHa === true ? 'active' : ''} title="Heart" />
                  <ShareOption
                    id={isPlt}
                    onChange={setIsPlt}
                    activeClass={isPlt === true ? 'active' : ''}
                    title="Arrow"
                  />
                  {/* <ShareOption
                    id={isAsset}
                    onChange={setIsAsset}
                    activeClass={isAsset === true ? 'active' : ''}
                    title="Asset Scope"
                  /> */}
                  <ShareOption
                    id={isVideo}
                    onChange={setIsVideo}
                    activeClass={isVideo === true ? 'active' : ''}
                    title="Video"
                  />
                  <ShareOption
                    id={isLightImg}
                    onChange={setIsLightImg}
                    activeClass={isLightImg === true ? 'active' : ''}
                    title="Light"
                  />
                </ul>
              </div>
            </div>
            <div className="commonModalButton mt-20">
              <button className="fillButton" onClick={() => handleDownloadClick()}>
                Download
              </button>
              <button onClick={() => props.onClose()} className="outLineButton ml-5">
                Cancel
              </button>
            </div>
          </div>
        </div>
        {loader && <CircularProgress className="fixLoader" />}
      </CommonModal>
    </>
  );
};
export default DownloadAction;
