import React from 'react';

const SortingRound = (props) => {
  return (
    <div className="shortingRound" onClick={props.onClick}>
      <div className="shortingRoundInner">
        <img src={require('../../assets/svg/InventoryResult/shortinglistup.svg')} alt="icon" />
        <img src={require('../../assets/svg/InventoryResult/shortinglistdown.svg')} alt="icon" />
      </div>
    </div>
  );
};
export default SortingRound;
