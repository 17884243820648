import React from 'react';
import { withRouter } from 'react-router-dom';
import { TabList, Tab, Tabs } from 'react-tabs';

import { Tooltip } from 'antd';
import $ from 'jquery';

import MainHeading from 'containers/MainHeading';
import Clientropdown from 'containers/Tab/ClientTab';
import ParcelDiamondCollection from 'containers/Tab/ParcelDiamondCollection';
import SingleStone from 'containers/Tab/SingleStone';
import SingleStoneCollection from 'containers/Tab/SingleStoneCollection';
import SingleStoneConfiguration from 'containers/Tab/SingleStoneConfiguration';

import { isArray, isEmpty, isFunction } from 'util/utils';

import leftArrowSvg from './left-arrow.svg';
import rightArrowSvg from './right-arrow.svg';

import infoSvg from '../../assets/svg/info.svg';
import './tab.less';

const handleRightClick = () => {
  const scrollLeft = $('.customerMaintab').scrollLeft();
  if (scrollLeft !== 700) {
    const pos = $('.customerMaintab').scrollLeft() + 300;
    $('.customerMaintab').animate({ scrollLeft: pos }, 500);
  }
};

const handleLeftClick = () => {
  const pos = $('.customerMaintab').scrollLeft() - 300;
  $('.customerMaintab').animate({ scrollLeft: pos }, 500);
};

function CustomTab(props) {
  const {
    children,
    data,
    Inventory,
    moreTab,
    pageTitle,
    selectedClassName,
    selectedIndex,
    showDrawer,
    tabHeaderClass,
    onGetIndex,
    ...rest
  } = props;

  return (
    <Tabs {...{ ...rest, selectedIndex }}>
      {moreTab && (
        <div className="leftArrow" onClick={handleLeftClick}>
          <img src={leftArrowSvg} alt="left" />
        </div>
      )}
      <TabList className={tabHeaderClass}>
        {Inventory && (
          <span className="d-flex">
            <MainHeading title={pageTitle} />
            <Tooltip title="list Info">
              <img src={infoSvg} alt="info" className="headerInfoIcon" onClick={showDrawer} />
            </Tooltip>
          </span>
        )}
        {props.sub
          ? isArray(data.subList) &&
            data.subList.map(
              (tab, index) =>
                !tab.hideTab &&
                (tab.subTabMenu ? (
                  <Tab key={index} className={[tab.index].includes(selectedIndex) ? 'react-tabs__tab--selected' : ''}>
                    {tab.tabTitle}
                    {tab.configuration && <SingleStoneConfiguration />}
                    {tab.collection && <SingleStoneCollection />}
                    {tab.parcelDiamondcollection && <ParcelDiamondCollection />}
                    {tab.singleStone && <SingleStone />}
                    {tab.ClientTab && <Clientropdown />}
                  </Tab>
                ) : (
                  <Tab
                    key={index}
                    onClick={() => {
                      !props.wantIndex
                        ? props.history.push(tab.url)
                        : isFunction(onGetIndex) && onGetIndex(isEmpty(tab.index) ? undefined : tab.index);
                    }}
                    selectedClassName={[tab.index].includes(selectedIndex) ? selectedClassName : ''}
                    className={[tab.index].includes(selectedIndex) ? 'react-tabs__tab--selected' : ''}
                  >
                    {tab.tabTitle}
                  </Tab>
                )),
            )
          : isArray(data) &&
            data.map(
              (tab, index) =>
                !tab.hideTab &&
                (tab.subTabMenu ? (
                  <Tab key={index} className={[tab.index].includes(selectedIndex) ? 'react-tabs__tab--selected' : ''}>
                    {tab.tabTitle}
                    {tab.configuration && <SingleStoneConfiguration />}
                    {tab.collection && <SingleStoneCollection />}
                    {tab.parcelDiamondcollection && <ParcelDiamondCollection />}
                    {tab.singleStone && <SingleStone />}
                    {tab.ClientTab && <Clientropdown />}
                  </Tab>
                ) : (
                  <Tab
                    key={index}
                    onClick={() => {
                      !props.wantIndex
                        ? props.history.push(tab.url)
                        : isFunction(onGetIndex) && onGetIndex(isEmpty(tab.index) ? undefined : tab.index);
                    }}
                    selectedClassName={[tab.index].includes(selectedIndex) ? selectedClassName : ''}
                    className={[tab.index].includes(selectedIndex) ? 'react-tabs__tab--selected' : ''}
                  >
                    {tab.tabTitle}
                  </Tab>
                )),
            )}
      </TabList>
      {moreTab && (
        <div className="rightArrow" onClick={handleRightClick}>
          <img src={rightArrowSvg} alt="right" />
        </div>
      )}
      {children}
    </Tabs>
  );
}

export default withRouter(React.memo(CustomTab));
