import React from 'react';

import { Tooltip } from 'antd';
import { each, compact, find, uniq, memoize } from 'lodash';

import ClientName from 'components/Client/ClientName';
import { FILTER_COLUMNS, FLOAT_COLUMNS, ROUND_COLUMNS } from 'components/Inventory/table-config';
import { formatValues } from 'components/Inventory/table-utils';

import { Storage } from 'services/storage';
import UtilService, { callApiAsync } from 'services/util';
import { store } from 'appRedux/store/index';
import {
  classNames,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatNumber,
  isArray,
  isEmpty,
  isFunction,
  isString,
  returnIfNotEmpty,
} from 'util/utils';

import { DIAMOND_BLOCK } from 'constants/Common';
import API_ROUTES from 'constants/CommonApi';
import { GRID_COLUMN } from 'constants/Inventory';
import OpenNotification from 'constants/OpenNotifications';

import closeBlackSvg from 'assets/svg/InventoryResult/close-black.svg';

export const searchFn = ({ isPlatformSearch = false, ...object }, callback) => {
  // remove empty keys
  each(object, (val, key) => {
    if (isArray(val) && val.length === 0) delete object[key];
    else if (isArray(val)) object[key] = val;
  });

  object = { ...object, labGrown: store.getState().commonData.labGrown };

  const config = {
    ...API_ROUTES.Diamond.paginate,
    request: { filters: [object], isNotReturnTotal: true, isReturnCountOnly: true },
    config: { loader: false },
  };

  UtilService.callApi(config, (err, res) => {
    if (res?.code === 'OK') {
      if (res?.data?.[0]?.count === 0 && !isPlatformSearch)
        return OpenNotification({ type: 'error', title: "No diamond's exist" });

      const searchIds = res.data.map((d) => d?.filter?.id);
      const NOSTONE = res?.data?.[0]?.count === 0 ? 'NOSTONE' : '';
      callback(false, { NOSTONE, searchIds });
    } else {
      callback(true, undefined);
    }
  });
};

export const getSearchIds = (params) => {
  return new Promise((resolve) => {
    searchFn(params, (err, res) => {
      resolve([err, { notFound: res?.NOSTONE === 'NOSTONE', list: res?.searchIds }]);
    });
  });
};

export function getClientList(cb) {
  const config = { ...API_ROUTES.Account.paginate };
  UtilService.callApi(config, (err, res) => {
    cb(res?.code === 'OK' ? res : undefined);
  });
}

export function handleInsertMemo(available, user, cb) {
  const config = { ...API_ROUTES.Memo.Insert };
  config.request = {
    user,
    blockType: DIAMOND_BLOCK.TYPE.MEMO,
    diamonds: available.map((x) => ({
      diamond: x.id,
      blockPricePerCarat: x.ctPr,
      blockAmount: x.amt,
      vnd: x.vnd,
      vStnId: x.vStnId,
    })),
  };
  UtilService.callApi(config, (err, res) => {
    if (res && res?.code === 'OK') {
      OpenNotification({ type: 'success', title: res.message });
      cb(true);
    } else {
      cb(false);
    }
  });
}

export const handleMatchPair = (filter, cb) => {
  const objData = {
    method: API_ROUTES.MatchPair.Insert.method,
    url: API_ROUTES.MatchPair.Insert.url,
    request: {
      filter,
      page: 1,
      limit: 1000,
    },
  };
  UtilService.callApi(objData, (err, res) => {
    if (res && res?.code === 'OK') {
      cb(res.data.filter.id);
    }
  });
};

export const handlePlaceOrder = (request, cb, removeDiamond, loadingFn) => {
  const requestConf = {
    ...API_ROUTES.DiamondConfirm.Request,
    request,
  };
  loadingFn(true);
  UtilService.callApi(requestConf, (err, res) => {
    if (res && res?.code === 'OK') {
      if (isFunction(removeDiamond)) removeDiamond();
      loadingFn(false);
      cb(true);
      OpenNotification({ type: 'success', title: res.message });
    } else {
      loadingFn(false);
      cb(false);
    }
  });
};

export const isMobile = () => window?.innerWidth <= 991;

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;

const ColumnFilter = React.memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = React.useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((value) => (
            <li key={`columnFilter_${id}${value}`}>
              {returnIfNotEmpty(value, '-')}
              <div className="closeFilter" onClick={() => toggleFilter(value)}>
                <img src={closeBlackSvg} alt="icon" />
              </div>
            </li>
          ))}
        </ul>
      )}
      {availableOptions && availableOptions.length > 0 && (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((option) => (
            <li key={`columnFilter_${id}${option}`} onClick={() => toggleFilter(option)}>
              {returnIfNotEmpty(option, '-')}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export async function fetchcolumn(cb, grid, options) {
  const LOCATION = { india: 'IND', beligium: 'BEL', newyork: 'NY', hongkong: 'HK', dubai: 'DXB' };
  const master = Storage.get('master');

  const fetchcolumnDefaultOptions = { shortDate: false };
  const { shortDate } = { ...fetchcolumnDefaultOptions, ...options };

  const COLUMNS_NOT_UPCOMING = ['amt', 'ctPr', 'rap', 'rapAvg', 'back'];
  const [, res] = await callApiAsync({
    ...API_ROUTES.Gridcolumn.list,
    request: {
      name: grid ?? (localStorage.getItem('pn-labGrown') === 'Y' 
        ? GRID_COLUMN.GRID_DIAMOND_LIST_ADMIN_LGD 
        : GRID_COLUMN.GRID_DIAMOND_LIST_ADMIN)
    },
  });

  // eslint-disable-next-line no-use-before-define
  if (isEmpty(res.data.columns)) memoizedFetchcolumnAsync.cache.clear();

  if (res?.code !== 'OK') return cb([]);

  const columnList = isArray(res?.data?.columns) ? res.data.columns.map((column) => ({ ...column })) : [];

  const columns = columnList
    .filter((column) => {
      if (column.isStatic) return false;
      if (!column.isActive) return false;
      if (!isString(column.field) || isEmpty(column.field)) return false;

      if (COLUMNS_NOT_UPCOMING.includes(column.field.trim()) && window.location.pathname.includes('upcoming'))
        return false;
      if (column.field.trim() === 'reminderDate' && window.location.pathname.includes('reminder')) return false;

      return true;
    })
    .map((column) => {
      column.field = column.field.trim();
      column.title = column.title ? column.title.trim() : column.title;

      if (column.field === 'remarks' && options === 'note') column.title = 'Notes';
      if (column.field === 'dna') column.link = null;

      const canFilter = FILTER_COLUMNS.includes(column.field);

      return {
        accessor: column.field,
        Header: column.title,
        id: column.field,
        desc: column.desc,
        link: column.link,
        sort: column.sort,
        Filter: canFilter ? ColumnFilter : <></>,
        filter: filterArrayIncludes,
        Cell: React.memo(({ row, cell }) => {
          if (column.field === 'dna') cell.value = row.original.vStnId;

          const client = React.useMemo(() => <ClientName user={cell.value} />, [cell.value]);

          const fullName = React.useMemo(() => {
            const description = find(master?.[column?.key] ?? [], { code: cell?.value })?.description ?? undefined;
            if (isEmpty(description)) return undefined;
            return (
              <Tooltip key={`${cell.row.id}${cell.column.id}`} title={description}>
                {cell.value}
              </Tooltip>
            );
          }, [cell.column.id, cell.row.id, cell.value]);

          const value = isEmpty(cell.value, { isEmpty: ['NONE'] })
            ? '-'
            : FLOAT_COLUMNS.includes(cell.column.id)
              ? formatDecimal(cell.value)
              : ROUND_COLUMNS.includes(cell.column.id)
                ? formatNumber(Math.round(cell.value))
                : column.isFlag && cell.value
                  ? isString(column.sLabel)
                    ? column.sLabel
                    : 'Y'
                  : column.isFlag && !cell.value
                    ? isString(column.nLabel)
                      ? column.nLabel
                      : '-'
                    : cell.value === true
                      ? 'Y'
                      : !cell.value
                        ? '-'
                        : fullName
                          ? fullName
                          : column.field === 'cutNm'
                            ? row.original.shpNm === 'ROUND'
                              ? cell.value
                              : '-'
                            : column.field === 'locNm'
                              ? LOCATION[cell.value.trim().toLowerCase()] || cell.value
                              : column.field === 'createdAt'
                                ? shortDate
                                  ? formatDate(cell.value)
                                  : formatDateTime(cell.value)
                                : column.field === 'user'
                                  ? client
                                  : cell.value;

          return (
            <div
              className={classNames([
                (FLOAT_COLUMNS.includes(column.field) || ROUND_COLUMNS.includes(column.field)) && 'numberValue',
              ])}
            >
              {value}
            </div>
          );
        }),
      };
    });

  cb(columns);
}

// async proxy for fetchcolumn
export const fetchcolumnAsync = (...args) => new Promise((resolve) => fetchcolumn(resolve, ...args));
export const memoizedFetchcolumnAsync = memoize(
  (...args) => new Promise((resolve) => fetchcolumn(resolve, ...args)),
  (...args) => JSON.stringify(args),
);

export const allSortObject = (columns) =>
  isArray(columns) &&
  Object.fromEntries(columns.filter(({ sort }) => sort).map(({ sort, Header }) => [`${sort}`, Header]));

export const filterColumns = (data) => {
  const filterArray = {};
  if (!isArray(data)) return filterArray;
  FILTER_COLUMNS.forEach((x) => {
    filterArray[x] = compact(uniq(data.map((r) => r?.diamond?.[x] || r[x])));
  });
  return filterArray;
};
