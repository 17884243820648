import moment from 'moment';

import Storage from 'services/storage';

import { CacheStore } from 'util/CacheStore';
import { isEmpty, isString } from 'util/utils';

import { COMMON_URL } from 'constants/CommonUrl';
import { DIAMOND_BID } from 'constants/Inventory';
import Messages from 'constants/Messages';
import OpenNotification from 'constants/OpenNotifications';

const dateTimeCache = new CacheStore('DateTime');

/**
 *Function used to get current login info
 */
export const getLoginUser = () => Storage.get(`user`);

export const getDate = (input, format = 'DD/MM/YYYY') => {
  const date = moment(input);
  if (!date.isValid()) return undefined;

  const cache = dateTimeCache.get(`format-${input}-${format}`);
  if (!isEmpty(cache)) return cache;

  const output = date.format(format);
  dateTimeCache.set(`format-${input}-${format}`, output);

  return output;
};

export const getDateTime = (dateTime, format = 'DD/MM/YYYY hh:mm A') => getDate(dateTime, format);

export const getDayDiff = (startDate, endDate) => {
  const stDate = moment(startDate);
  const enDate = moment(endDate);
  if (!stDate.isValid() || !enDate.isValid()) return undefined;

  const cache = dateTimeCache.get(`diff-${startDate}-${endDate}-day`);
  if (!isEmpty(cache)) return cache;

  const output = enDate.diff(stDate, 'days');
  dateTimeCache.set(`diff-${startDate}-${endDate}-day`, output);

  return output;
};

// Bid function
export const getBidStartTime = (bidType) => {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(11, 'hours');
  else return moment().startOf('day').add(15, 'hours');
};

export const getBidEndTime = (bidType) => {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(30, 'minutes');
  else return moment().startOf('day').add(10, 'hours').add(50, 'minutes');
};

export const getBidEndCounter = (bidType) => {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(30, 'minutes');
  else if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf('day').add(10, 'hours').add(50, 'minutes');
  else return moment().startOf('day').add(1, 'days').add(10, 'hours').add(50, 'minutes');
};

export const getBidType = () => {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
};

/**
 * Function used to validate clienti
 * @param {*} props
 */
export const validateClient = (props) => {
  if (!isEmpty(props?.client)) return true;
  OpenNotification({ type: 'error', title: Messages.CompanySelect });
  return false;
};

/**
 * Function used to validate selected item
 * @param {*} props
 */
export const validateCheckedItems = (props) => {
  if (!isEmpty(props?.checked)) return true;
  OpenNotification({ type: 'error', title: Messages.DiamondSelectRequire });
  return false;
};

export const downloadExcelFile = async (err, res, cb) => {
  if (!err && res && res.code === 'OK') {
    const server = COMMON_URL.EXCELFILE_URL;
    const filePath = server + res?.data?.data;
    window.open(filePath, 'Download');
    cb(true);
  } else {
    cb(false);
    OpenNotification({
      type: 'error',
      title: res?.message,
    });
  }
};
