import React, { Component } from 'react';

import { Formik, Form, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import InputBlock from 'containers/InputBlock';
import Heading from 'containers/MainHeading';
import SelectOption from 'containers/SelectOption';

import BottomStickyButton from 'components/ShortCuts/BottomStickButton';

import { isEmpty } from 'util/utils';

import BankConsts from 'constants/Bank.consts';
import CurrencyConsts from 'constants/Currency.consts';

class BankAccountDetail extends Component {
  static propTypes = {
    account: PropTypes.any,
    updateAccount: PropTypes.func,
  };

  bankDetails = {
    accounts: BankConsts.getBankAccountTypeOptions(),
    currency: CurrencyConsts.getCurrencyTypeOptions(),
  };

  fields = [
    {
      name: 'bank',
      label: 'Banks',
      type: 'array',
      fields: [
        { name: 'bankName', type: 'text', label: 'Bank Name *', placeholder: 'Bank Name', initialValue: '' },
        { name: 'branchName', type: 'text', label: 'Branch Name', placeholder: 'Branch Name', initialValue: '' },
        {
          name: 'currency',
          type: 'select',
          label: 'Currency *',
          placeholder: 'Currency',
          options: this.bankDetails.currency,
          initialValue: undefined,
        },
        { name: 'account', type: 'text', label: 'Account Name *', placeholder: 'Account Name', initialValue: '' },
        {
          name: 'acNo',
          type: 'text',
          label: 'Account Number *',
          placeholder: 'Account Number',
          initialValue: '',
        },
        {
          name: 'acType',
          type: 'select',
          label: 'Account Type *',
          placeholder: 'Account Type',
          options: this.bankDetails.accounts,
          initialValue: undefined,
        },
        { name: 'ifsc', type: 'text', label: 'IFSC Code *', placeholder: 'IFSC Code', initialValue: '' },
        { name: 'swiftCode', type: 'text', label: 'Swift Code *', placeholder: 'Swift Code', initialValue: '' },
      ],
    },
  ];

  validationSchema = Yup.object().shape({
    bank: Yup.array().of(
      Yup.object().shape(
        {
          bankName: Yup.string().trim().required('Please enter bank name'),
          branchName: Yup.string().trim(),
          currency: Yup.string()
            .oneOf(
              this.bankDetails.currency.map(({ key }) => key),
              'Invalid currency selected',
            )
            .required('Please select currency'),
          account: Yup.string().trim().required('Please enter account name'),
          acNo: Yup.string()
            .required('Please enter account number')
            .matches(/^[0-9]*$/, 'Invalid account number'),
          acType: Yup.string()
            .oneOf(
              this.bankDetails.accounts.map(({ key }) => key),
              'Invalid Account Type',
            )
            .required('Please select account type'),
          ifsc: Yup.mixed().when('swiftCode', {
            is: (swiftCode) => isEmpty(swiftCode),
            then: Yup.string().required('Please enter IFSC code or swift code'),
          }),
          swiftCode: Yup.mixed().when('ifsc', {
            is: (ifsc) => isEmpty(ifsc),
            then: Yup.string().required('Please enter IFSC code or swift code'),
          }),
        },
        ['ifsc', 'swiftCode'],
      ),
    ),
  });

  getInitialValues = () => {
    const { account } = this.props;

    return Object.fromEntries(
      this.fields.map(({ type, fields, name, initialValue }) =>
        ['array'].includes(type) && isEmpty(account?.[name])
          ? [name, [Object.fromEntries(fields.map(({ name, initialValue }) => [name, initialValue]))]]
          : [name, isEmpty(account?.[name]) ? initialValue : account[name]],
      ),
    );
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { account, updateAccount } = this.props;

    setSubmitting(true);
    const [err, res] = await updateAccount(account?.id, { bank: values.bank });
    setSubmitting(false);

    if (!err && res?.code === 'OK') {
      if (this.state.saveContinue) this.props.updateSubTabIndex();
      else {
        resetForm();
        this.props.onCancel();
      }
    }
  };

  render() {
    const initialValues = this.getInitialValues();

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={this.submitForm}
      >
        {({ handleChange, handleBlur, setFieldValue, setFieldTouched, submitForm, touched, errors, values }) => (
          <Form>
            <div className="kycFormMainBlock">
              <Heading className="smallTitle p-0 mb-20" title="Bank Details" />
              {this.fields.map(
                (field) =>
                  field.type === 'array' &&
                  values[field.name].map((item, index) => (
                    <React.Fragment key={index}>
                      <FieldArray
                        name={field.name}
                        render={(fieldArrayHelpers) => (
                          <>
                            <div className="kycBlockWrapper formMainSubBlock mb-20 pb-0">
                              {field.fields.map(({ name, type, label, placeholder, options, props }) => {
                                const htmlName = `${field.name}.${index}.${name}`;
                                return type === 'select' ? (
                                  <SelectOption
                                    {...{ name: htmlName, label, ...props }}
                                    placeholder={placeholder}
                                    value={values[field.name]?.[index]?.[name]}
                                    selectValue={options}
                                    onChange={(value) => setFieldValue(htmlName, value)}
                                    onBlur={() => setFieldTouched(htmlName, true)}
                                    error={touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]}
                                    key={htmlName}
                                  />
                                ) : (
                                  <InputBlock
                                    {...{ name: htmlName, label, type, ...props }}
                                    placeholder={placeholder}
                                    onKeyPress={(evt) => {
                                      if ((evt.which < 48 || evt.which > 57) && name === 'acNo') {
                                        evt.preventDefault();
                                      }
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched[field.name]?.[index]?.[name] && errors[field.name]?.[index]?.[name]}
                                    value={values[field.name]?.[index]?.[name]}
                                    key={htmlName}
                                  />
                                );
                              })}
                            </div>
                            <div className="kycNewBlockAdd">
                              <button
                                className="commonButton"
                                type="button"
                                role="button"
                                onClick={() => fieldArrayHelpers.push({})}
                              >
                                Add
                              </button>
                              {values?.[field.name]?.length > 1 && (
                                <button
                                  className="commonButton ml-5"
                                  type="button"
                                  role="button"
                                  onClick={() => values[field.name]?.length > 1 && fieldArrayHelpers.remove(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      />
                    </React.Fragment>
                  )),
              )}
            </div>
            <BottomStickyButton className={!this.props?.account?.id && 'disableBlock'}>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: true }, submitForm)}
              >
                Save & Continue
              </button>
              <button
                className="commonButton"
                type="button"
                onClick={() => this.setState({ saveContinue: false }, submitForm)}
              >
                Save
              </button>
              <button className="commonOutine" type="reset" value="reset" onClick={this.props.onCancel}>
                Cancel
              </button>
            </BottomStickyButton>
          </Form>
        )}
      </Formik>
    );
  }
}

export default BankAccountDetail;
