import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Form, Input } from 'antd';

import LoginRightSection from 'containers/LoginRightSection';

import CircularProgress from 'components/CircularProgress';

// import { useLoading } from 'util/hooks';
import { showNotification } from 'services/NotificationService';
import 'styles/signin/signin.less';
import { forgotPassword } from 'services/CommonApi';

import IntlMessages from 'util/IntlMessages';
import { history } from 'util/history';

import { PROJECT_DETAILS } from 'constants/Common';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

function ForgotPassword({ form }) {
  // const [loading, startLoading, stopLoading] = useLoading(false);
  const { getFieldDecorator } = React.useMemo(() => form, [form]);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = React.useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const [, values] = await new Promise((r) => form.validateFields((err, values) => r([err, values])));

        const [err, res] = await forgotPassword({ username: values.email });

        if (!err) {
          showNotification(res);
          form.resetFields();
          history.push('/');
        }
      } catch (error) {
        // OpenNotification({ type: 'error', title: ?.message });
      }
    },
    [form],
  );
  return (
    <div className="adma-login-wrap">
      {loading && <CircularProgress className="fixLoader" />}
      <div className="adma-login-container">
        <div className="loginpage forget-pwd">
          <div className="adma-login-main-content">
            <div className="adma-app-login-content">
              <div className="adma-logo-content-bg">
                <img
                  width="auto"
                  height="80px"
                  style={{ objectFit: 'contain', textAlign: 'left' }}
                  src={PROJECT_DETAILS.LOGO_1}
                  alt="P N Shah"
                />
                <h2 className="login-title">
                  Forgot Your Password ?
                  <span> Don't worry. Recovering the password is easy. Just tell us the email.</span>
                </h2>
              </div>
              <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
                {getFieldDecorator('email', {
                  rules: [
                    { type: 'email', message: 'The input is not valid E-mail!' },
                    { required: true, message: 'Please input your E-mail!' },
                  ],
                })(<Input type="email" placeholder="E-Mail Address" />)}
                <div className="submit-btn d-flex mt-20 justify-content-between">
                  <Button type="primary" className="adma-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                  <Link to={`${PREFIX_URL_WITH_SLASH}/signin`}>
                    <span className="gx-signup-form-forgot gx-link">Back to login</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <LoginRightSection />
        </div>
      </div>
    </div>
  );
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
