import React from 'react';

import { isEmpty } from 'util/utils';
import './title.less';

const MainHeading = React.memo(({ className, title, children, ...rest }) => {
  return (
    <h2 className={`mainHeadingTitle ${className}`} {...rest}>
      {isEmpty(children) ? title : children}
    </h2>
  );
});

export default MainHeading;
