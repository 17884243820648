import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Avatar, Popover, Modal, Row, Col, notification } from 'antd';

// import CircularProgress from 'components/CircularProgress';
import { SettingsRoutes } from 'routes/Settings';

import Storage from 'services/storage';
import UtilService from 'services/util';

import { isNotEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import OpenNotification from 'constants/OpenNotifications';

import { userSignOut } from 'appRedux/actions/Auth';

class UserProfile extends Component {
  state = {
    showModal: false,
    newpassword: '',
    currentpassword: '',
    confirmpassword: '',
    errors: { newpassword: '', confirmpassword: '', currentpassword: '' },
    loader: false,
    user: {},
  };

  cancel = () => {
    this.setState({ showModal: false, confirmpassword: '', newpassword: '', currentpassword: '', errors: {} });
  };

  componentDidMount() {
    this.setState({ user: Storage.get('user') });
    this.unregister = Storage.listen('user', ({ user }) => {
      this.setState({ user: { ...this.state.user, ...user } });
    });
  }

  handleValidation() {
    const values = {
      newpassword: this.state.newpassword,
      confirmpassword: this.state.confirmpassword,
      currentpassword: this.state.currentpassword,
    };
    const errors = {};
    let formIsValid = true;
    if (!values.newpassword) {
      formIsValid = false;
      errors['newpassword'] = 'Please enter new password';
    }
    if (values.confirmpassword && values.confirmpassword !== values.newpassword) {
      formIsValid = false;
      errors['confirmpassword'] = 'Password not matched';
    } else if (!values.confirmpassword) {
      formIsValid = false;
      errors['confirmpassword'] = 'Please enter confirm password';
    }
    this.setState({ errors });
    return formIsValid;
  }
  ok = () => {
    const blank = [];
    const invalid = [];
    const obj = ConstantApi.ResetPassword.paginate;
    obj.request = {
      ConfirmPassword: this.state.confirmpassword,
      currentPassword: this.state.currentpassword,
      newPassword: this.state.newpassword,
    };
    if (this.handleValidation()) {
      UtilService.callApi(obj, (err, data) => {
        if (data.code === 'OK') {
          OpenNotification({ type: 'success', title: data.message });
          this.setState({ newpassword: '', confirmpassword: '', currentpassword: '', showModal: false, errors: {} });
        } else {
          this.setState({ newpassword: '', confirmpassword: '', currentpassword: '' });
        }
      });
    } else {
      if (blank.length > 0) {
        const blankFields = blank.map((f, i) => <span key={i}>{f + (i !== blank.length - 1 ? ', ' : '')}</span>);
        notification.error({ message: 'Required fields', description: blankFields });
      }
      if (invalid.length > 0) {
        const blankFields = invalid.map((f, i) => <span key={i}>{f + (i !== invalid.length - 1 ? ', ' : '')}</span>);
        notification.error({ message: 'Invalid fields', description: blankFields });
      }
    }
  };

  handleOnChange = (title, e) => {
    if (title === 'newpassword') {
      this.setState({ newpassword: e.target.value });
    }
    if (title === 'confirmpassword') this.setState({ confirmpassword: e.target.value });
    if (title === 'currentpassword') this.setState({ currentpassword: e.target.value });
  };

  render() {
    const { newpassword, confirmpassword, currentpassword, user } = this.state;
    const userMenuOptions = (
      <ul className="gx-user-popover profileMenuHover">
        <a className="profileMenuProfile" href={PREFIX_URL_WITH_SLASH + '/setting/profile'}>
          <div className="userProfileLeft">
            <img src={require('assets/images/user.jpeg')} alt="user" />
          </div>
          <div>{[user?.firstName, user?.lastName].filter(isNotEmpty).join(' ')}</div>
        </a>
        <li>
          <a href={SettingsRoutes.root}>My Settings</a>
        </li>
        {/* <li>
          <a href={SettingsRoutes.notifications}>Notification</a>
        </li> */}
        {/* <li>
          <a>My Account</a>
        </li> */}
        {/* <li onClick={() => this.setState({ showModal: true })}>
          <a>Reset Password</a>
        </li> */}
        <li
          onClick={() => {
            this.setState({ loader: true });
            this.props.userSignOut();
          }}
        >
          <a>Logout</a>
        </li>
        {/* <div className="userProfileHr"></div>
        <li>
          <a>Help</a>
        </li>
        <li>
          <a href={PREFIX_URL_WITH_SLASH + '/document'} title="Documents">
            Documents
          </a>
        </li>
        <li>
          <a>Stock</a>
        </li>
        <div className="socialLinkMenuPopup">
          <span className="socialLinkTitle">Social Link</span>
          <span className="socialLinkMenu">
            <a>
              <img src={require('assets/svg/whatsapp.svg')} alt="icon" />
            </a>
            <a>
              <img src={require('assets/svg/whatsapp.svg')} alt="icon" />
            </a>
            <a>
              <img src={require('assets/svg/whatsapp.svg')} alt="icon" />
            </a>
          </span>
        </div> */}
      </ul>
    );

    return (
      <div>
        <Popover placement="topRight" overlayClassName="profilePopupOver" content={userMenuOptions} trigger="click">
          <div className="userProfile">
            <div className="userProfileLeft">
              <img src={require('assets/images/user.jpeg')} alt="user" />
            </div>
            {/* <div className="userProfileRight">
              <div className="userProfileText">Hi,</div>
              <div className="userProfileName">
                {user ? user.username : 'Loading'}
                <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /> {this.state.loader && <CircularProgress />}
              </div>
            </div> */}
          </div>
          {/* <Avatar
            src="https://via.placeholder.com/150x150"
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          />
          <span className="gx-avatar-name">
            {user ? user.username : "Loading"}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />{" "}
            {this.state.loader && <CircularProgress />}
          </span> */}
        </Popover>
        {this.state.showModal && (
          <Modal
            visible={this.state.showModal}
            title={'Reset Password'}
            okText={'Reset Password'}
            onOk={this.ok}
            onCancel={this.cancel}
          >
            <form autoComplete="off">
              <Row>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      Current Password{' '}
                      {currentpassword === '' ? (
                        <span className="required" style={{ color: 'red' }}>
                          *
                        </span>
                      ) : (
                        <span className="required" style={{ color: 'green' }}>
                          *
                        </span>
                      )}
                    </label>

                    <input
                      type="password"
                      id="currentpassword"
                      value={currentpassword ? currentpassword : ''}
                      onChange={(e) => {
                        this.handleOnChange('currentpassword', e);
                      }}
                    />

                    <span style={{ color: 'red' }}>{this.state.errors['currentpassword']}</span>
                  </div>
                </Col>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      New Password{' '}
                      {newpassword === '' ? (
                        <span className="required" style={{ color: 'red' }}>
                          *
                        </span>
                      ) : (
                        <span className="required" style={{ color: 'green' }}>
                          *
                        </span>
                      )}
                    </label>

                    <input
                      type="password"
                      id="newpassword"
                      value={newpassword ? newpassword : ''}
                      onChange={(e) => {
                        this.handleOnChange('newpassword', e);
                      }}
                    />
                    <span style={{ color: 'red' }}>{this.state.errors['newpassword']}</span>
                  </div>
                </Col>
                <Col lg={24} className="reset-password-box mb-20">
                  <div className="form-control">
                    <label className="d-block">
                      Confirm Password{' '}
                      {confirmpassword === '' ? (
                        <span className="required" style={{ color: 'red' }}>
                          *
                        </span>
                      ) : (
                        <span className="required" style={{ color: 'green' }}>
                          *
                        </span>
                      )}
                    </label>

                    <input
                      type="password"
                      id="confirmpassword"
                      value={confirmpassword ? confirmpassword : ''}
                      onChange={(e) => {
                        this.handleOnChange('confirmpassword', e);
                      }}
                    />

                    <span style={{ color: 'red' }}>{this.state.errors['confirmpassword']}</span>
                  </div>
                </Col>
              </Row>
            </form>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  ({ auth }) => ({
    user: auth.authUser,
  }),
  { userSignOut },
)(UserProfile);
