import forOwn from 'lodash/forOwn';
import memoize from 'lodash/memoize';
import { DateTime, Duration } from 'luxon';

import { _clone } from 'util/clone';
import * as REGEX from 'util/regex';

import { LOCALE, CURRENCY, PRECISION, TIMEZONE_IANA, LUXON_FORMAT } from 'constants/Common';

export const runInDevelopment = (callback) =>
  [undefined, '', 'development'].includes(process.env.REACT_APP_ENV) && callback();

export const logInfo = (...args) => runInDevelopment(() => console.info(...args)); // eslint-disable-line no-console
export const logWarn = (...args) => runInDevelopment(() => console.warn(...args)); // eslint-disable-line no-console
export const logError = (...args) => runInDevelopment(() => console.error(...args)); // eslint-disable-line no-console
export const logTable = (...args) => runInDevelopment(() => console.table(...args)); // eslint-disable-line no-console

export const catchError = (func, onError) => {
  const handleError = (error) => {
    logWarn(error);
    return onError?.(error);
  };
  try {
    const output = func?.();
    if (output?.constructor?.name !== 'Promise') return output;
    if (output?.catch?.constructor?.name !== 'Function') return output;
    return output?.catch?.(handleError);
  } catch (error) {
    return handleError(error);
  }
};

export const typeOf = (input, type) => input?.constructor?.name === (type ?? null);

export const isArray = (input) => typeOf(input, 'Array');

export const isObject = (input) => typeOf(input, 'Object');

export const isBoolean = (input) => typeOf(input, 'Boolean');

export const isString = (input) => typeOf(input, 'String');

export const isNumber = (input) => typeOf(input, 'Number') && !Number.isNaN(input) && Number.isFinite(input);

export const isNumeric = (input, strict = false) =>
  new RegExp(strict ? REGEX.NUMERIC.STRICT : REGEX.NUMERIC.LOOSE).test(input);

export const isAlphaNumeric = (input, strict = false) =>
  new RegExp(strict ? REGEX.ALPHA_NUMERIC.STRICT : REGEX.ALPHA_NUMERIC.LOOSE).test(input);

export const isFunction = (input) => typeOf(input, 'Function');

export const callFunction = (func, ...args) => isFunction(func) && func(...args);

export const forEach = (instance, callback) => Array.prototype.forEach.call(instance, callback);

export const map = (instance, callback) => Array.prototype.map.call(instance, callback);

export const isHTMLElement = (input) => input instanceof HTMLElement;

export const isEmpty = (input, options) => {
  options = { isEmpty: [], isNotEmpty: [], ...options };

  if (options.isEmpty?.includes?.(input)) return true;
  if (options.isNotEmpty?.includes?.(input)) return false;
  if ([undefined, null].includes(input)) return true;

  if (input?.constructor?.name === 'Array') return !input.length;
  if (input?.constructor?.name === 'Number') return Number.isNaN(input);
  if (input?.constructor?.name === 'Object') return !Object.keys(input).length;
  if (input?.constructor?.name === 'String') return !input.trim().length;

  return false;
};

export const isNotEmpty = (...args) => !isEmpty(...args);

export const clone = _clone;

export const pruneEmpty = (input, options) => {
  options = { clone: true, ...options };
  const prune = (current) => {
    if (isEmpty(current, options)) return;
    if (isString(current)) return current?.trim?.();

    if (isArray(current)) {
      current = current.filter((value) => !isEmpty(prune(value), options));
    } else {
      forOwn(current, (value, key) => {
        if (isEmpty(value, options) || ((isObject(value) || isArray(value)) && isEmpty(prune(value), options)))
          delete current[key];
      });
    }
    if (isEmpty(current, options)) return;
    return current;
  };
  return prune(options.clone ? clone(input) : input);
};

export const returnIfNotEmpty = (value, replaceWith) => (isEmpty(value) ? replaceWith : value);

export const hasKey = (object, key) => isObject(object) && !isEmpty(object) && Object.keys(object).includes(key);

export const withDefaults = (object, defaults) => ({ ...defaults, ...object });

export const getCurrentTime = () => DateTime.local().setZone(TIMEZONE_IANA);

export const formatDateTime = memoize(
  (isoDate, format = LUXON_FORMAT.DATE_TIME) => {
    const dateTime = DateTime.fromISO(isoDate);
    return dateTime.isValid ? dateTime.toFormat(format) : undefined;
  },
  (...args) => JSON.stringify(args),
);

export const formatDate = (isoDate, format = LUXON_FORMAT.DATE) => formatDateTime(isoDate, format);

export const formatTime = (isoDate, format = LUXON_FORMAT.TIME) => formatDateTime(isoDate, format);

export const formatDuration = (duration, format = LUXON_FORMAT.DURATION) =>
  Duration.isDuration(duration) && duration?.isValid ? duration.toFormat(format) : undefined;

export const getDateTimeDiff = (startISO, endISO) => DateTime.fromISO(endISO).diff(DateTime.fromISO(startISO));

export const getFormattedDateTimeDiff = (startISO, endISO, format = LUXON_FORMAT.DURATION) =>
  formatDuration(getDateTimeDiff(startISO, endISO), format);

export const castToNumber = (input, altValue = 0) => {
  if (isString(input)) input = Number(`${input}`.replace(/[^0-9.+-]/g, ''));
  input = Number(input);
  return isNumber(input) ? input : altValue;
};

export const formatNumber = (input, options = {}) => {
  input = castToNumber(input, undefined);
  if (isEmpty(input)) return '-';
  if (isNumber(options)) options = { fractionLength: options };
  const { locale, ...rest } = { locale: LOCALE, ...options };
  const fractionLength = rest?.fractionLength ?? `${input}`.split('.')?.[1]?.length;
  const defaults = { maximumFractionDigits: fractionLength, minimumFractionDigits: fractionLength };
  return new Intl.NumberFormat(locale, { ...defaults, ...rest }).format(input);
};

export const formatCurrency = (input, options = {}) => {
  if (isNumber(options)) options = { fractionLength: options };
  return formatNumber(input, { style: 'currency', currency: CURRENCY, fractionLength: PRECISION, ...options });
};

export const formatDecimal = (input, options = {}) => {
  if (isNumber(options)) options = { fractionLength: options };
  return formatNumber(input, { fractionLength: PRECISION, ...options });
};

export const parseDecimal = (input, fractionLength = PRECISION) => {
  if (!isNumber(Number(input))) return undefined;
  return Number(parseFloat(input).toFixed(fractionLength));
};

export const formatFloat = (input, fractionLength = PRECISION) => {
  if (!isNumber(Number(input))) return undefined;
  return parseFloat(input).toFixed(fractionLength);
};

export const sortEntriesByKey = (desc = false) => {
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[0] < next?.[0] ? n.less : curr?.[0] > next?.[0] ? n.more : 0);
};

export const sortArrayByKey = (key = 'id', desc = false) => {
  if (!isString(key)) return undefined;
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[key] < next?.[key] ? n.less : curr?.[key] > next?.[key] ? n.more : 0);
};

export const reduceUnique = (key) => {
  return (a = [], c) => {
    const indexFound = a?.findIndex?.((item) => (key === undefined ? item === c : item[key] === c[key]));
    if (indexFound === -1) a.push(c);
    return a;
  };
};

export const padArray = (list, length, fillWith) => {
  return list.concat(Array(length).fill(fillWith)).slice(0, length);
};

export const reduceTotal = (list, key) => {
  if (!isArray(list) || isEmpty(list)) return 0;
  const numList = key === undefined ? list.map(Number) : list.map((item) => Number(item?.[key]));
  return numList.filter(isNumber).reduce((pv, cv) => (pv += cv), 0);
};

export const formatInlineList = (list, options = {}) => {
  options = { separator: ',', returnString: true, removeDupes: true, allowAppend: false, ...options };

  if (isArray(list)) list = list.join(options.separator);
  if (!isString(list)) return list;

  let output = `${list}`.replace(/[\s,]+/gm, options.separator).split(options.separator);
  output = output.filter(
    (value, index) => !isEmpty(value) || (options.allowAppend && index && output?.length === index + 1),
  );
  options.removeDupes = options.allowAppend
    ? isEmpty(output[output.length - 1]) && options.removeDupes
    : options.removeDupes;
  output = options.removeDupes ? output.reduce(reduceUnique(), []) : output;
  output = options.returnString ? output.join(options.separator) : output;

  return output;
};

export const classNames = (list) => list.filter(isString).join(' ');

export const upperFirst = (input, locale = LOCALE) =>
  isString(input) ? input.replace(/(^[a-z])/, (match) => match.toLocaleUpperCase(locale)) : input;

export const lowerFirst = (input, locale = LOCALE) =>
  isString(input) ? input.replace(/(^[a-z])/, (match) => match.toLocaleLowerCase(locale)) : input;

export const upperCase = (input, locale = LOCALE) => (isString(input) ? input.toLocaleUpperCase(locale) : input);

export const lowerCase = (input, locale = LOCALE) => (isString(input) ? input.toLocaleLowerCase(locale) : input);

export const titleCase = memoize(
  (input, locale = LOCALE) => {
    if (!isString(input)) return '';
    let output = input.split(/([ :–—-])/);
    output = output.map((current, index, list) => {
      return (
        // Check for small words
        current.search(/^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i) > -1 &&
          // Skip first and last word
          index !== 0 &&
          index !== list.length - 1 &&
          // Ignore title end and subtitle start
          list[index - 3] !== ':' &&
          list[index + 1] !== ':' &&
          // Ignore small words that start a hyphenated phrase
          (list[index + 1] !== '-' || (list[index - 1] === '-' && list[index + 1] === '-'))
          ? current.toLocaleLowerCase(locale)
          : current.substr(1).search(/[A-Z]|\../) > -1 // Ignore intentional capitalization
          ? current
          : list[index + 1] === ':' && list[index + 2] !== '' // Ignore URLs
          ? current
          : current.replace(/([A-Za-z0-9\u00C0-\u00FF])/, (match) => match.toLocaleUpperCase(locale)) // Capitalize the first letter
      );
    });
    output = output.join('');
    return output;
  },
  (input, locale) => `${input}_${locale}`,
);

export const objectToQueryString = (object) =>
  catchError(
    () =>
      `?${Object.entries(object)
        .map(([key, value]) => `${key}=${!isEmpty(value) && isFunction(value?.toString) ? value.toString() : ''}`)
        .join('&')}`,
    () => '',
  );

export const queryStringToObject = (search = window.location.search) =>
  catchError(
    () => {
      const urlParams = new URLSearchParams(search);
      return Object.fromEntries(urlParams.entries());
    },
    () => {},
  );

export const getUserName = memoize(
  (user, replace = '-') => {
    const name = [user?.firstName, user?.lastName].filter(isNotEmpty);
    return !isEmpty(name) ? name.join(' ') : titleCase(user?.name) ?? user?.username ?? replace;
  },
  (user, replace) => `${user?.firstName}${user?.lastName}${user?.name}${user?.username}${replace}`,
);

export const capitalize = (input) =>
  input?.replace(/_/g, ' ')?.replace(/(\w+)/g, (x) => x[0].toUpperCase() + x.substring(1));
