import React, { Component } from 'react';

import { DatePicker } from 'antd';

import InputBlock from '../../../../containers/InputBlock';

class AnnouncementAdd extends Component {
  render() {
    return (
      <div className="d-flex flex-wrap homeSettingWrapper">
        <div className="from-group inputBlock">
          <label className="commonLabel">Start Date</label>
          <DatePicker />
        </div>
        <div className="from-group inputBlock">
          <label className="commonLabel">End Date</label>
          <DatePicker />
        </div>
        <div className="from-group inputBlock">
          <label className="commonLabel">Please Add Announcement</label>
          <textarea className="commonTextArea" rows="3"></textarea>
        </div>
        <div className="width-100 d-flex">
          <button className="commonButton mr-10">Save</button>
          <butotn className="commonOutine" onClick={this.props.onClick}>
            Cancel
          </butotn>
        </div>
      </div>
    );
  }
}

export default AnnouncementAdd;
