import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import App from 'containers/App';

import { history } from 'util/history';

import 'styles/_main.less';

const NextApp = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  );
};

export default NextApp;
