// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SWITCH_STONE_TYPE = 'SWITCH-STONE-TYPE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const RESET_SEARCH = 'RESET_SEARCH';
export const CUURENT_PAGE_DATA = 'CUURENT_PAGE_DATA';
export const MODIFY_SEARCH = 'MODIFY_SEARCH';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const CURRENT_PAGE_DATA = 'CURRENT_PAGE_DATA';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_DEFAULT_PASSWORD_SET = 'user_default_password_set';
export const USERNAME_PASSWORD = 'USERNAME_PASSWORD';
export const REMOVE_USERNAME_PASSWORD = 'REMOVE_USERNAME_PASSWORD';
export const VERIFY_OTP = 'VERIFY_OTP';

export const LIST_DATA = 'list_data';

//Client Detail
export const SET_CLIENT = 'SET_CLIENT';
export const CLIENT_FETCH_START = 'CLIENT_FETCH_START';
export const CLIENT_FETCH_SUCCESS = 'CLIENT_FETCH_SUCCESS';
export const CLIENT_FETCH_ERROR = 'CLIENT_FETCH_ERROR';
