import { Storage } from 'services/storage';
import { callApiAsync, withUrlParams } from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';

export const getAccountDetails = (id, request = {}) => {
  const user = id || Storage.get('user')?.account?.id;
  if (isEmpty(user)) return [];

  return callApiAsync({ ...withUrlParams(API_ROUTES.Account.get, [user]), request });
};

export const getAccountsList = (request = {}) => callApiAsync({ ...API_ROUTES.Account.paginate, request });

export const createAccount = (request = {}) => callApiAsync({ ...API_ROUTES.Account.create, request });

export const updateAccount = (id, request = {}) =>
  callApiAsync({ ...API_ROUTES.Account.update, request: { id, ...request } });

export const deleteAccount = (id) => callApiAsync(withUrlParams(API_ROUTES.Account.delete, [id]));

export const changeAccountStatus = (request = {}) => callApiAsync({ ...API_ROUTES.Account.changeStatus, request });
