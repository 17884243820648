import React from 'react';

import { isArray, isEmpty, isObject, reduceTotal } from 'util/utils';

import { STAGE_FILTERS, STAGE_OPTIONS } from 'constants/stage';

const StatusMap = {
  all: { label: 'All', color: 'transparent', key: undefined },
  A: { label: 'Available', color: '#6bc950', key: 'AVAILABLE' },
  NA: { label: 'Not Available', color: '#ff960b', key: 'NOT_AVAILABLE' },
  UN: { label: 'Unsynced', color: '#ff960b', key: 'UNSYNCED' },
  C: { label: 'Hold/Order Pending', color: '#1c4e7f', key: 'HOLD__ORDER_PENDING' },
  D: { label: 'Best Buy', color: '#ffc0cb', key: 'BEST_BUY' },
  H: { label: 'Hold', color: '#db1c1c', key: 'HOLD' },
  M: { label: 'On Memo', color: '#307bea', key: 'MEMO' },
  B: { label: 'Bid', color: '#6b6b6b', key: 'BID' },
  O: { label: 'Sold', color: '#d98e21', key: 'SOLD' },
  S: { label: 'Show', color: '#ff77ff', key: 'SHOW' },
  U: { label: 'Upcoming', color: '#d2b48c', key: 'UPCOMING' },
};

const Status = React.memo((props) => {
  const { row, bestBuyInitSts } = props;

  const status = React.useMemo(() => {
    if (isEmpty(row)) return StatusMap.all;

    const weightedEntries = Object.entries(STAGE_FILTERS).map(([key, filter]) => {
      const weightList = Object.entries(filter)
        .filter(([, value]) => !isEmpty(value))
        .map(([key, value]) => {
          if (isObject(value) && isArray(value?.nin)) return !value.nin.includes(row[key]) ? 1 : 0;
          if (isArray(value)) return value.includes(row[key]) ? 1 : 0;
          if (value === row[key]) return 1;
          return 0;
        });
      const stats = { matchCount: reduceTotal(weightList), exactMatch: weightList.every(Boolean) };
      return [key, stats];
    });

    const nonZeroEntries = weightedEntries.filter(([, stats]) => {
      return stats.exactMatch || stats.matchCount > 0;
    });

    const exactOrHeaviestEntries = nonZeroEntries.filter(([, stats], index, list) => {
      const foundHeavierMatch = list.some(([, _stats, _index]) => {
        return _index !== index && _stats.matchCount >= stats.matchCount;
      });
      return !foundHeavierMatch || stats.exactMatch;
    });

    const heaviestExactEntries = exactOrHeaviestEntries.filter(([, stats], index, list) => {
      const foundHeavierMatch = list.some(([, _stats, _index]) => {
        return _index !== index && _stats.matchCount > stats.matchCount;
      });
      return !foundHeavierMatch && stats.exactMatch;
    });

    const matchedEntry = heaviestExactEntries?.[0];
    const matchedKey = matchedEntry?.[0];
    const matchedStatus = { ...(STAGE_OPTIONS.find(({ key }) => key === matchedKey) ?? StatusMap?.[row?.wSts]) };

    const hasBestBuyLabel = matchedStatus?.key?.split?.('__')?.includes?.('BEST_BUY');
    const hasBestBuyInitSts = bestBuyInitSts && row?.initSts === 'D';
    if (!hasBestBuyLabel && hasBestBuyInitSts) matchedStatus.label = `${matchedStatus?.label}/Best Buy`;

    // logInfo({ bestBuyInitSts, initSts: row?.initSts, row, wSts: row?.wSts });
    // logInfo({ weightedEntries, nonZeroEntries, exactOrHeaviestEntries, heaviestExactEntries });
    // logInfo({ matchedEntry, matchedKey, matchedStatus });

    return matchedStatus;
  }, [bestBuyInitSts, row]);

  const statusLabel = status?.label ?? row?.wSts;
  const statusColor = status?.color ?? '#fff';

  return (
    <div className="showSatusBlock">
      {statusColor && <div className="SelectcolorWiseList" style={{ backgroundColor: statusColor }} />}
      <span>{statusLabel}</span>
    </div>
  );
});

export default Status;
