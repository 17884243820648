import React from 'react';

import PropTypes from 'prop-types';

import { MemoService } from 'services/MemoService';

import { classNames } from 'util/classNames';
import * as Hooks from 'util/hooks';
import { isEmpty, isFunction } from 'util/utils';

import printIconSrc from 'assets/svg/print-dark.svg';

function PrintMemo({ row }) {
  const [loading, startLoading, stopLoading] = Hooks.useLoading();

  const blockTxnId = React.useMemo(() => row?.original?.blockTxnId, [row?.original?.blockTxnId]);

  const printStone = React.useCallback(async () => {
    startLoading();
    await MemoService.viewPdf(blockTxnId);
    stopLoading();
  }, [blockTxnId, startLoading, stopLoading]);

  const handleClick = React.useCallback(
    (event) => {
      if (isFunction(event?.preventDefault)) event.preventDefault();
      printStone();
    },
    [printStone],
  );

  if (isEmpty(blockTxnId)) return null;
  return (
    <>
      <a href="#!" title="Print Memo Details" onClick={handleClick} className={classNames([loading && 'progress'])}>
        <img src={printIconSrc} alt="View Memo Details" style={{ display: 'inline', height: '15px', width: '15px' }} />
      </a>
    </>
  );
}

PrintMemo.propTypes = {
  row: PropTypes.object,
};

export default React.memo(PrintMemo);
