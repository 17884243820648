import { last } from 'lodash';

import { printPdf } from 'services/CommonApi';
import { LoaderService } from 'services/LoaderService';
import { MediaService } from 'services/MediaService';
import { callApi, callApiAsync } from 'services/util';

import { isArray, isEmpty, isFunction } from 'util/utils';

import { CommonPrintExcel } from 'constants/Common';
import { API_ROUTES } from 'constants/CommonApi';
import { COMMON_URL } from 'constants/CommonUrl';
import { DIAMOND_VIEW_TYPE } from 'constants/Inventory';
import OpenNotification from 'constants/OpenNotifications';
import { store } from 'appRedux/store/index';

function updateRequest(request) {
  return { request: { ...request, labGrown: store.getState().commonData?.labGrown || 'N' } };
}

export const deleteBid = (request = {}, config) => {
  return callApiAsync({
    ...API_ROUTES.DiamondBid.delete,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

export const getDynamicDiamondBidList = (request = {}, config) => {
  return callApiAsync({
    ...API_ROUTES.DiamondBid.dynamicBid,
    request,
    ...(!isEmpty(config) && { config }),
  });
};

// Inventory > Single Stone > Overview > Stock Summary
export const getStockSummary = (request, config) =>
  callApiAsync({ ...API_ROUTES.StockSummary.list, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

export const getWithoutDiamondList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Inventory.imageCheck, request, ...(!isEmpty(config) && { config }) });

export const placeOrderHandel = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondConfirm.Request, request, ...(!isEmpty(config) && { config }) });

export const getGridColumnList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Gridcolumn.list, request, ...(!isEmpty(config) && { config }) });

export const saveGridColumnList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Gridcolumn.update, request, ...(!isEmpty(config) && { config }) });

export const savedSearchList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Diamond.search, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

export const getSearchCounts = async (request = {}, config) => {
  request = { isNotReturnTotal: true, isReturnCountOnly: true, isSkipSave: true, ...request };
  const [, res] = await callApiAsync({ ...API_ROUTES.Diamond.paginate, ...updateRequest(request), config });
  return res?.data?.[0]?.count ?? 0;
};

export const getReportNoList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Diamond.getReportNoList, ...updateRequest(request), config });

export const getCount = (filter, cb) => {
  const request = {
    ...API_ROUTES.Diamond.paginate,
    config: { loader: false },
    request: {
      isNotReturnTotal: true,
      isReturnCountOnly: true,
      isSkipSave: true,
      filters: [{ ...filter, labGrown: store.getState().commonData?.labGrown || 'N' }],
    },
  };
  callApi(request, (err, res) => void cb(res?.data?.[0]?.count ?? 0));
};

export const getNewArrivalCount = (filter, cb) => {
  const request = {
    ...API_ROUTES.Diamond.paginate,
    config: { loader: false },
    request: {
      isNotReturnTotal: true,
      isReturnCountOnly: true,
      isSkipSave: true,
      filters: [{ ...filter, labGrown: store.getState().commonData?.labGrown || 'N' }],
      viewType: DIAMOND_VIEW_TYPE.NEW_ARRIVAL,
    },
  };
  callApi(request, (err, res) => void cb(res?.data?.[0]?.count ?? 0));
};

// Diamond track
export const getDiamondTrackList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondTrack.paginate, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

export const getOfficeList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.office.paginate, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

export const deleteOffice = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.office.delete, request, ...(!isEmpty(config) && { config }) });

export const getDiamondBidList = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondBid.paginate, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

export const getDiamondTrackRemove = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondTrack.delete, request, ...(!isEmpty(config) && { config }) });

export const getDiamondTrackChangeStatus = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondTrack.changeStatus, request, ...(!isEmpty(config) && { config }) });

export const getDiamondTrackUpdateStatus = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondTrack.statusUpdate, request, ...(!isEmpty(config) && { config }) });

export const createDiamondTrack = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.DiamondTrack.create, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

// Transaction Hold/Memo
export const getTransactionHoldMemo = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Memo.paginate, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

// Diamond excel
export const diamondFtc = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Inventory.ftc, request, ...(!isEmpty(config) && { config }) });

export const sendEmailAttachment = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Inventory.exportExcel, request, ...(!isEmpty(config) && { config }) });

export const sendEmailExcel = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Inventory.downloadZip, request, ...(!isEmpty(config) && { config }) });

export const sendXRayEmailAttachment = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Inventory.xRayMail, request, ...(!isEmpty(config) && { config }) });

export const diamondSearchUpsert = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Demand.Upsert, request, ...(!isEmpty(config) && { config }) });

export const getZipPath = (request = {}) => callApiAsync({ ...API_ROUTES.Inventory.downloadZip, request });

// Download
export const downloadExcelFile = async (request = {}, cb, config) => {
  const [err, res] = await callApiAsync({
    ...API_ROUTES.Inventory.exportExcel,
    request,
    ...(!isEmpty(config) && { config }),
  });

  if (!err && res && res.code === 'OK') {
    const server = COMMON_URL.EXCELFILE_URL;
    const filePath = server + res?.data?.data;
    window.open(filePath, 'Download');
    cb(true);
  }
};

export const downloadPdfFile = async (diamond, requestUrl, filter, moduleParam, cb) => {
  if (isFunction(cb)) cb(true);

  const request = {
    ...(isArray(diamond) && diamond?.length && moduleParam != 'inOrder'
      ? { id: diamond?.map((o) => o.id) }
      : moduleParam == 'inOrder'
        ? { orderDiamond: diamond?.map((o) => o.id) }
        : {}),
    ...(!isEmpty(filter) ? { filter } : {}),
    ...(CommonPrintExcel[moduleParam] ? CommonPrintExcel[moduleParam] : {}),
  };

  LoaderService.startLoading(true);
  const [err, res] =
    isEmpty(requestUrl) || (isArray(diamond) && diamond?.length) || !isEmpty(filter)
      ? await printPdf(request)
      : await requestUrl?.urlMethod({ ...requestUrl?.request, isPdf: true });
  LoaderService.stopLoading(true);

  if (!err && res && res.code === 'OK') {
    if (isFunction(cb)) cb(false);
    const server = COMMON_URL.EXCELFILE_URL;
    const filePath = server + res?.data;
    window.open(filePath, 'Download');
  } else {
    if (isFunction(cb)) cb(false);
  }
};

export const downloadZip = async (request = {}, cb, config) => {
  const [err, res] = await callApiAsync({
    ...API_ROUTES.Inventory.downloadZip,
    request,
    ...(!isEmpty(config) && { config }),
  });

  if (!err && res && res.code === 'OK' && !isEmpty(res?.data)) {
    const filePath = COMMON_URL.BASE_URL + res?.data;
    if (`${filePath}`.split('.').pop() === 'html') {
      await MediaService.viewFile(filePath);
      if (isFunction(cb)) cb();
    } else {
      try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', filePath, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (xhr.statusText == 'OK' || xhr.status == 200) {
            const urlCreator = window.URL || window.webkitURL;
            const Url = urlCreator.createObjectURL(this.response);
            const tag = document.createElement('a');
            tag.href = Url;
            tag.target = '_blank';
            tag.download = isArray(res?.data) ? last(res?.data?.[0]?.split('/')) : last(res.data?.split('/'));
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Resource Not Found.',
            });
          }
          if (isFunction(cb)) cb();
        };
        xhr.send();
      } catch (error) {
        if (isFunction(cb)) cb();
        return error;
      }
    }
  } else {
    OpenNotification({
      type: 'error',
      title: 'Resource Not Found.',
    });
    if (isFunction(cb)) cb();
  }
  //  window.open(filePath, 'Download');
};

export const downloadFile = async (request = {}, config) => {
  const [err, res] = await callApiAsync({
    ...API_ROUTES.Inventory.downloadFile,
    request,
    ...(!isEmpty(config) && { config }),
  });

  if (!err && res && res.code === 'OK') {
    const server = COMMON_URL.EXCELFILE_URL;
    const file_path = server + res.data.path;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', file_path, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = request.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }
};

export const downloadVideo = (apiObj) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', apiObj.path, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.target = '_blank';
      tag.download = apiObj.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.onerror = (err) => {
      alert(err);
    };
    xhr.send();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
};

export const downloadCertificate = async (request = {}, config) => {
  const [err, res] = await callApiAsync({
    ...API_ROUTES.Inventory.downloadCertificate,
    request,
    ...(!isEmpty(config) && { config }),
  });

  if (!err && res && res.code === 'OK') {
    const server = COMMON_URL.EXCELFILE_URL;
    const filePath = server + res.data;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = url;
      tag.download = request.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }
};
