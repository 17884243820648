import * as CommonAPI from 'services/CommonApi';
import * as SellerAPI from 'services/SellerApi';

import { CacheStore } from './CacheStore';
import { isArray, isEmpty, isString } from './utils';

const cacheStore = new CacheStore('options');
const OptionsUtils = {};

/**
 * Memoized function for `Country` dropdown
 */
OptionsUtils.getCountryOptions = async () => {
  const cache = cacheStore.get(`countries`);
  if (!isEmpty(cache)) return cache;

  const [, res] = await CommonAPI.getCountries({ loader: false });
  const list = res?.data?.list;
  if (!isArray(list) || isEmpty(list)) return [];

  const output = list.map(({ id, name, ISDCode }) => ({ key: id, value: name, code: ISDCode }));
  cacheStore.set(`countries`, output);
  return output;
};

/**
 * Memoized function for `State` dropdown
 */
OptionsUtils.getStateOptions = async (country) => {
  if (!isString(country) && isEmpty(country)) return [];
  // const cache = cacheStore.get(`states.${country}`);
  // if (!isEmpty(cache)) return cache;

  const [, res] = await CommonAPI.getStates({ country }, { loader: false });
  const list = res?.data?.list;
  if (!isArray(list) || isEmpty(list)) return [];

  const output = list.map(({ id, name }) => ({ key: id, value: name }));
  cacheStore.set(`states.${country}`, output);
  return output;
};

/**
 * Memoized function for `City` dropdown
 */
OptionsUtils.getCityOptions = async (state) => {
  if (!isString(state) && isEmpty(state)) return [];
  // const cache = cacheStore.get(`cities.${state}`);
  // if (!isEmpty(cache)) return cache;

  const [, res] = await CommonAPI.getCities({ state }, { loader: false });
  const list = res?.data?.list;
  if (!isArray(list) || isEmpty(list)) return [];

  const output = list.map(({ id, name }) => ({ key: id, value: name }));
  cacheStore.set(`cities.${state}`, output);
  return output;
};

/**
 * Memoized function for `SalesPerson` dropdown
 */
OptionsUtils.getSellersList = async () => {
  // const cache = cacheStore.get(`sellers`);
  // if (!isEmpty(cache)) return cache;

  const [, res] = await SellerAPI.getSellerList({}, { loader: false });
  const list = res?.data;
  if (!isArray(list) || isEmpty(list)) return [];

  const output = list.map((details) => ({ ...details }));
  cacheStore.set(`sellers`, output);
  return output;
};

export default OptionsUtils;
