import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTable, useFlexLayout } from 'react-table';

import { Tooltip } from 'antd';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

import PlusIcon from '../../../../containers/PlusIcon';

const data = [
  {
    srno: '1',
    trandate: '10/09/2020',
    fromdate: '12/09/2020',
    todate: '30/09/2020',
    imagename: 'Banner',
  },
  {
    srno: '2',
    trandate: '10/09/2020',
    fromdate: '12/09/2020',
    todate: '30/09/2020',
    imagename: 'Banner',
  },
  {
    srno: '3',
    trandate: '10/09/2020',
    fromdate: '12/09/2020',
    todate: '30/09/2020',
    imagename: 'Banner',
  },
  {
    srno: '4',
    trandate: '10/09/2020',
    fromdate: '12/09/2020',
    todate: '30/09/2020',
    imagename: 'Banner',
  },
  {
    srno: '5',
    trandate: '10/09/2020',
    fromdate: '12/09/2020',
    todate: '30/09/2020',
    imagename: 'Banner',
  },
];
class AnnouncementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: false,
      form: true,
      smallLeft: true,
    };
  }
  toggle = () => {
    this.setState({
      tab: true,
    });
  };
  toggle1 = () => {
    this.setState({
      form: false,
    });
  };
  closeForm = () => {
    this.setState({
      form: true,
    });
  };
  toggle = () => {
    this.setState({
      smallLeft: !this.state.smallLeft,
    });
  };
  render() {
    const { location } = this.props;
    const userListPath = location.pathname === PREFIX_URL_WITH_SLASH + '/user/user-list/list';
    const userFormPath = location.pathname === PREFIX_URL_WITH_SLASH + '/user/user-list/form';
    const columns = [
      {
        Header: 'SR Number',
        width: '50px',
        accessor: 'srno',
      },
      {
        Header: 'Description',
        accessor: 'description',
        shortingSelect: true,
        shortingCount: '1',
        Cell: ({ row, cell }) => (
          <p className="tableDec text-left m-0">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </p>
        ),
      },
      {
        Header: 'Trans Date',
        accessor: 'trandate',
      },
      {
        Header: 'From Date',
        accessor: 'fromdate',
      },
      {
        Header: 'To Date',
        accessor: 'todate',
      },
      {
        Header: 'Image Name',
        accessor: 'imagename',
      },
      {
        Header: 'Action',
        Cell: ({ row, cell }) => (
          <div className="actionButton j-flex-start">
            <div className="actionAction">
              <img src={require('../../../../assets/svg/edit.svg')} />
            </div>
            <div className="actionAction">
              <img src={require('../../../../assets/svg/delet.svg')} />
            </div>
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td width="100px" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      //  <div className="configurationInnerSubTb">
      <>
        <>
          <PlusIcon onClick={this.props.onClick}></PlusIcon>
          <div className="contactDirectoryWrapper">
            <div className="contactListMain">
              <div className="contactList">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </>
      </>
      //  </div>
    );
  }
}

export default withRouter(AnnouncementList);
