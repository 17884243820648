import React, { Component } from 'react';

import { Checkbox } from 'antd';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import DatePickerInput from 'containers/DatePickerInput';
import FileUpload from 'containers/FileUpload';
import InputBlock from 'containers/InputBlock';
import TextArea from 'containers/TextArea';

import * as EventAPI from 'services/EventApi';
import { showNotification } from 'services/NotificationService';

import { isEmpty } from 'util/utils';

import { EVENT } from 'constants/Common';

class EventApp extends Component {
  state = {
    saveContinue: false,
  };

  initialValues = {
    name: '',
    fromdateTime: '',
    toDateTime: '',
    shortDescription: '',
    image: '',
    images: [],
    dashboardimage: '',
    isActive: true,
  };

  getInitialValues = () => {
    const { update } = this.props;
    if (!isEmpty(update)) {
      update.fromdateTime = update?.fromdateTime && moment(update?.fromdateTime);
      update.toDateTime = update?.toDateTime && moment(update?.toDateTime);
    }

    return Object.fromEntries(
      Object.entries(this.initialValues).map(([key, value]) => [key, isEmpty(update?.[key]) ? value : update?.[key]]),
    );
  };

  submitForm = async (values, { setSubmitting, resetForm }) => {
    const { update } = this.props;
    const { saveContinue } = this.state;

    values.type = EVENT.TYPE.EVENT;
    setSubmitting(true);
    const [err, res] = await (!isEmpty(update?.id)
      ? EventAPI.updateEvent(update?.id, { ...update, ...values })
      : EventAPI.storeEvent(values));
    setSubmitting(false);

    if (!err) showNotification(res);

    if (!err && isEmpty(update?.id)) resetForm();
    if (!err && !saveContinue) this.props.closeForm();
  };

  render() {
    const { update } = this.props;
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required('Title is required').typeError('Title must be a string'),
          shortDescription: Yup.string()
            .trim()
            .required('Description is required')
            .typeError('Description must be a string'),
        })}
        onSubmit={this.submitForm}
      >
        {({ handleChange, handleBlur, setFieldValue, setValues, submitForm, touched, errors, values }) => (
          <Form>
            <div className="d-flex flex-wrap homeSettingWrapper">
              <InputBlock
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                label="Title *"
                placeholder="Name"
                error={touched?.name && errors?.name}
              />

              <DatePickerInput
                showTime
                onChange={(value) => setFieldValue('fromdateTime', value)}
                onBlur={handleBlur}
                label="Start Date"
                value={values.fromdateTime}
                error={touched?.fromdateTime && errors?.fromdateTime}
              />

              <DatePickerInput
                showTime
                onChange={(value) => setFieldValue('toDateTime', value)}
                onBlur={handleBlur}
                label="End Date"
                value={values.toDateTime}
                error={touched?.toDateTime && errors?.toDateTime}
              />

              <TextArea
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Description"
                label="Description *"
                value={values.shortDescription}
                name="shortDescription"
                error={touched?.shortDescription && errors?.shortDescription}
              />
              <div className="d-flex from-group">
                <FileUpload
                  files={[values?.image]}
                  onChange={(value) => setFieldValue('image', value)}
                  onBlur={handleBlur}
                  isValidate
                  accept="JPG,JPEG,PNG,GIF"
                  label="Upload Background Image"
                  name="image"
                  size="JPG,JPEG,PNG,GIF file allowed"
                  error={touched?.image && errors?.image}
                />
              </div>
              <div className="d-flex from-group">
                <FileUpload
                  files={values?.images}
                  onChange={(images) => setValues((values) => ({ ...values, images }))}
                  multiple
                  isValidate
                  accept="JPG,JPEG,PNG,GIF"
                  onBlur={handleBlur}
                  label="Multiple Images"
                  name="images"
                  size="JPG,JPEG,PNG,GIF file allowed"
                  error={touched?.images && errors?.images}
                />
              </div>
              <div className="d-flex from-group">
                <FileUpload
                  files={[values?.dashboardimage]}
                  label="Upload Background Image"
                  onChange={(value) => setFieldValue('dashboardimage', value)}
                  onBlur={handleBlur}
                  isValidate
                  accept="JPG,JPEG,PNG,GIF"
                  name="dashboardimage"
                  size="JPG,JPEG,PNG,GIF file allowed"
                  error={touched?.dashboardimage && errors?.dashboardimage}
                />
              </div>

              <div className="from-group mt-20">
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.isActive}
                  name="isActive"
                  error={touched?.isActive && errors?.isActive}
                >
                  Is Active
                </Checkbox>
              </div>
              <div className="width-100 d-flex mt-40">
                <button className="commonButton mr-10" type="submit" value="Submit">
                  {isEmpty(update?.id) ? 'Save' : 'Update'}
                </button>
                <button className="commonOutine" type="reset" onClick={this.props.closeForm}>
                  Back
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default EventApp;
