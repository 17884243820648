import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { returnIfNotEmpty } from 'util/utils';

import SortingFilterDropdownItem from './SortingFilterDropdownItem';

import closeBlackSvg from 'assets/svg/InventoryResult/close-black.svg';

const SortingFilterDropdown = (props) => {
  const leftFilterArray =
    props.FilterOption === false
      ? []
      : props?.selectedFilterArray[props?.column]
      ? props?.filterArray[props?.column].filter((el) => !props?.selectedFilterArray[props?.column].includes(el))
      : props?.filterArray[props?.column];

  return (
    <div className="menuInnerFilterOption">
      <div className="menuInnerFilterInner">
        {props.sort.length > 0 && (
          <>
            <div className="llSortingTitle">Sorting Order</div>
            <div className="llSortingTitleList">
              <DndProvider backend={HTML5Backend}>
                {props.sort.map((item, index) => (
                  <SortingFilterDropdownItem
                    key={index}
                    index={index}
                    moverow={props.handleSort}
                    para={Object.keys(item)[0]}
                    title={props.canSort[Object.keys(item)[0]]}
                    sortClick={props.sortClick}
                    sort={props.sort}
                  />
                ))}
              </DndProvider>
            </div>
          </>
        )}
        {props.FilterOption !== false && props.filterArray[props.column]?.length > 0 && (
          <div className="llfilterMenuDropdown" style={props.sort.length ? { borderTop: '1px solid #e9ebf0' } : {}}>
            {props.selectedFilterArray[props.column] && (
              <ul className="llfilterSelect">
                <div className="llSortingTitle">Selected Filter</div>
                {props.selectedFilterArray[props.column].map((x, index) => (
                  <li key={index}>
                    {x}
                    <div className="closeFilter" onClick={() => props.selectFilter(x, props.column, true)}>
                      <img src={closeBlackSvg} alt="icon" />
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {leftFilterArray.length > 0 && (
              <ul>
                <div className="llSortingTitle">Select Filter</div>
                {leftFilterArray.map((item, index) => (
                  <li key={index} onClick={() => props.selectFilter(item, props.column)}>
                    {returnIfNotEmpty(item, '-')}
                  </li>
                ))}
              </ul>
            )}
            {props.offer == true && (
              <div className="llfilterMenuDropdown pt-10">
                <ul className="">
                  <div className="llSortingTitle">Hide Colunm</div>
                  <li onClick={props.shapeClick} className={`${props.shapeShow == false && 'active'}`}>
                    Shape
                  </li>
                  <li onClick={props.colorClick} className={`${props.colorShow == false && 'active'}`}>
                    Color
                  </li>
                  <li onClick={props.clarityClick} className={`${props.clarityShow == false && 'active'}`}>
                    Clarity
                  </li>
                  <li onClick={props.flClick} className={`${props.flShow == false && 'active'}`}>
                    Fl
                  </li>
                  <li onClick={props.cutClick} className={`${props.cutShow == false && 'active'}`}>
                    Cut
                  </li>
                  <li onClick={props.polClick} className={`${props.polShow == false && 'active'}`}>
                    Pol
                  </li>
                  <li onClick={props.symClick} className={`${props.symShow == false && 'active'}`}>
                    SYM
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SortingFilterDropdown;
