import React from 'react';
import { Link } from 'react-router-dom';

import { Popconfirm } from 'antd';
import { DateTime } from 'luxon';

import CommonSearch from 'containers/CommonSearch';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import ListingWrapper from 'containers/ListingWrapper';
import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';

import Table, { LIMIT } from 'components/Inventory/Table';
import { allSortObject } from 'components/Inventory/inventoryFunctions';

import { showNotification } from 'services/NotificationService';
import * as SubMasterAPI from 'services/SubMasterApi';

import { isArray, isEmpty } from 'util/utils';

import Form from './Form';

class Master extends React.Component {
  state = {
    tab: false,
    form: true,
    checked: [],
    data: [],
    page: 1,
    limit: LIMIT,
    sort: [{ sortingSequence: 'ASC' }],
    loading: true,
  };

  componentDidMount() {
    this.getMastersList();
  }

  getMastersList = async (search = null) => {
    this.setState({ loading: true });
    const requestConf = { isOnlyParents: true, isDeleted: false, sort: this.state.sort, filter: {} };
    if (search) requestConf.search = { keyword: search, keys: ['name', 'code'] };
    const [, res] = await SubMasterAPI.getMastersList(requestConf);

    this.setState(
      res?.code == 'OK' && isArray(res?.data) && !isEmpty(res?.data)
        ? { data: res.data, count: res.data.length, loading: false }
        : { data: [], count: 0, loading: false },
    );
  };

  openForm = () => this.setState({ form: false });

  closeForm = () => this.setState({ form: true, detail: {} }, () => this.getMastersList());

  editMasterDetail = async (editId) => {
    const [, res] = await SubMasterAPI.findMasterDetail(editId);
    if (res?.code === 'OK') this.setState({ detail: res.data, form: false });
  };

  deleteMasterDetail = async (updateId) => {
    const [err, res] = await SubMasterAPI.updateMasterDetail({ updateId, isDeleted: true });
    !err && showNotification(res);
  };

  handleSearch = (value) => {
    if (value?.length > 2 || isEmpty(value)) this.getMastersList(value);
  };

  updateActiveStatus = async (id, status) => {
    const [err, res] = await SubMasterAPI.updateMasterDetail({ updateId: id, isActive: status });
    !err && showNotification(res);
    return !err && res.code === 'OK';
  };

  render() {
    const columns = [
      { Header: 'SR Number', width: '50px', accessor: 'srno', id: 'srno', Cell: ({ row }) => ++row.id },
      { Header: 'Name', accessor: 'name', id: 'name', shortingSelect: true, shortingCount: '2' },
      { Header: 'Code', accessor: 'code', id: 'code' },
      { Header: 'Sequence', accessor: 'sortingSequence', id: 'sortingSequence' },
      {
        Header: 'Status',
        accessor: 'active',
        id: 'active',
        Cell: ({ row }) => {
          const [isActive, setIsActive] = React.useState(row?.original?.isActive);

          return (
            <div
              onClick={async () => {
                const newIsActive = !isActive;
                row.original.isActive = newIsActive;
                const success = await this.updateActiveStatus(row.original.id, newIsActive);
                success && setIsActive(newIsActive);
              }}
            >
              <StatusBlock status={isActive ? 'active' : 'InActive'} title={isActive ? 'Active' : 'Inactive'} />
            </div>
          );
        },
      },
      {
        Header: 'Created By',
        accessor: 'createdby',
        Cell: ({ row }) => (
          <div className="lastEditUser">
            <div className="lastEditUserName">
              <span className="userName">Created by</span>
            </div>
            <div className="userName mt-5">
              {row.original?.createdBy?.name} {DateTime.fromISO(row.original.createdAt).toLocaleString()}
            </div>
          </div>
        ),
      },
      {
        Header: 'Sub Master',
        accessor: 'submaster',
        id: 'submaster',
        Cell: ({ row }) => (
          <Link
            to={{ pathname: 'submaster', state: { parentId: row.original.id, parentCode: row.original.code } }}
            className="tableLink"
          >
            View
          </Link>
        ),
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton">
            <div className="actionAction" title="Edit" onClick={() => this.editMasterDetail(row.original.id)}>
              <img src={require('assets/svg/edit.svg')} />
            </div>
            <Popconfirm
              placement="left"
              title={`Are you sure you want to delete this Master?`}
              okText={`Delete`}
              onConfirm={() => this.deleteMasterDetail(row.original.id)}
            >
              <div className="actionAction" title="Delete">
                <img src={require('assets/svg/delet.svg')} />
              </div>
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <div className="configurationInnerSubTb">
        <div className="d-flex j-space-between p-15 align-items-center masterTopBlock">
          <div className="headeTitle">
            <h2 className="mb-0">Master</h2>
          </div>
          {this.state.form && (
            <div>
              <CommonSearch placeholder="Search" handlechange={this.handleSearch} />
            </div>
          )}
        </div>
        {this.state.form ? (
          <ListingWrapper>
            <>
              <PlusIcon onClick={this.openForm.bind(this)} />
              <GridHeightContainer
                id="MasterTable"
                className="searchResultTable"
                style={{ height: 'auto', overflow: 'auto' }}
                subtractFrom={['.masterTopBlock']}
                adjustment={0}
              >
                <Table
                  {...this.state}
                  nocheck
                  columns={columns}
                  handleSort={(sort) => this.setState({ sort }, () => this.getMastersList())}
                  handleCheck={(checked) => this.setState({ checked })}
                  canSort={allSortObject(this.state.columns)}
                  filterArray={{}}
                  selectedFilterArray={{}}
                  selectFilter={(selectedFilterArray) => this.setState({ selectedFilterArray })}
                />
              </GridHeightContainer>
            </>
          </ListingWrapper>
        ) : (
          <Form onClick={this.closeForm.bind(this)} detail={this.state.detail} />
        )}
      </div>
    );
  }
}

export default Master;
