import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useTable } from 'react-table';

import { Drawer } from 'antd';
import PropTypes from 'prop-types';

import CheckBox from 'components/CheckBox/index';
import CircularProgress from 'components/CircularProgress';
import DiamondDetail from 'components/DiamondDetail';
import DiamondSliderImage from 'components/DiamondDetail/DiamondSliderImage';
import Embed from 'components/DiamondDetail/Embed';
import IFrame from 'components/DiamondDetail/IFrame';
import NotAvailable from 'components/DiamondDetail/NotAvailable';
import ListTableHeaderBack from 'components/DiamondListing/ListTableHeaderBack';
import NoDataShow from 'components/DiamondListing/NoDataShow';
import Status from 'components/DiamondListing/Status';
import HoldDetailsCell from 'components/Transaction/Hold/HoldDetailsCell';
import MemoDetailsCell from 'components/Transaction/Hold/MemoDetailsCell';
import OfferDetailsCell from 'components/Transaction/Offer/OfferDetailsCell';
import OrderDetailsCell from 'components/Transaction/Order/OrderDetailsCell';

import { RowSelectService } from 'services/RowSelectService';

import { useBoolean } from 'util/hooks';
import useFilters from 'util/useFilters';
import useRowSelect from 'util/useRowSelect';
import useSortBy from 'util/useSortBy';
import { classNames, isEmpty, isArray, isNotEmpty, isObject } from 'util/utils';

import { FILE_URLS, LAB_LINKS, TRACK_TYPES } from 'constants/Common';
import { PREFIX_URL } from 'constants/CommonUrl';

import { RouteAdmin } from 'routes';

import TableGrouping from './TableGrouping';
import {
  ALT_COLUMNS,
  NOCHECK_COLUMNS,
  TOTAL_COLUMNS,
  FLOAT_COLUMNS,
  ROUND_COLUMNS,
  SHOW_ALT_CERT,
} from './table-config';
import { updateList, formatValues, getStickyStyle, memoizedGetGroupTitle } from './table-utils';

import iconHistory from 'assets/svg/InventoryResult/history.svg';
import sortingLightSvg from 'assets/svg/InventoryResult/sorting-light.svg';
import iconCamera from 'assets/svg/camera.svg';
import iconCert from 'assets/svg/certi.svg';
import iconVideo from 'assets/svg/video.svg';

export {
  SORTABLE_COLUMNS as canSortCommon,
  FLOAT_COLUMNS as floatkeys,
  ROUND_COLUMNS as roundkeys,
  ALT_COLUMNS as ALT_KEYS,
  NOCHECK_COLUMNS as NOCHECKIDS,
  TOTAL_COLUMNS as DISPLAY_TOTAL,
  FILTER_COLUMNS,
  TABLE_PAGE_LIMIT as LIMIT,
} from './table-config';

export const NoDataFound = ({ loading, length }) =>
  loading ? <NoDataShow message={<CircularProgress />} /> : length === 0 ? <NoDataShow /> : <></>;

const Actions = React.memo((props) => {
  const { isHeader, row, rows, allChecked, noStatus, noCheck, currentType } = props;
  const { bestBuyInitSts, liveStatus, historyStatus } = props;

  const { isRowChecked, isHeaderChecked, toggleRowSelection, toggleAllRowSelection } = useRowSelect(currentType);

  React.useEffect(() => {
    if (!isObject(row)) return;
    if (!row?.selectionKey && row?.id) row.selectionKey = row?.id;
  }, [row]);

  React.useEffect(() => {
    if (noCheck) return;
    if (allChecked || row?.isDefaultChecked) toggleRowSelection(row, true);
  }, [allChecked, noCheck, row, toggleRowSelection]);

  const isChecked = React.useMemo(() => {
    if (noCheck) return;
    return isHeader ? isHeaderChecked(rows) : isRowChecked(row?.selectionKey);
  }, [noCheck, isHeader, isHeaderChecked, rows, isRowChecked, row?.selectionKey]);

  const handleChange = React.useCallback(() => {
    if (noCheck) return;
    !isHeader ? toggleRowSelection(row, !isChecked) : toggleAllRowSelection(rows);
  }, [noCheck, isHeader, toggleRowSelection, row, isChecked, toggleAllRowSelection, rows]);

  if (noStatus && noCheck) return null;


  const newRow = {
    ...row,
    wSts: row?.wSts == "A" && props.unavailable?.has(row?.id) ? "UN" : row?.wSts
  }

  return (
    <div className="selectActionIcon">
      {!noStatus && (
        <Status
          wSts={isHeader ? 'ALL' : newRow?.wSts}
          row={newRow}
          showLive={liveStatus}
          showHistory={historyStatus}
          bestBuyInitSts={bestBuyInitSts}
        />
      )}
      {!noCheck && (
        <div className="selectActionIconWrapper">
          <CheckBox key={`selection_${isHeader ? 'header' : row.id}`} checked={isChecked} onChange={handleChange} />
        </div>
      )}
    </div>
  );
});
Actions.displayName = 'Actions';

const Resource = React.memo((props) => {
  const { row = {}, extraDetails = [], inTrackDiamonds = [], inBlockDiamonds = [] } = props;

  const [showImage, setShowImage] = useBoolean();
  const [showVideo, setShowVideo] = useBoolean();
  const [showCert, setShowCert] = useBoolean();

  const offerCount = React.useMemo(() => {
    if (!(extraDetails === 'offerHistoryData' || extraDetails.includes('offerHistoryData'))) return;
    return inTrackDiamonds?.find(
      (track) => track?._id?.trackType === TRACK_TYPES.Offer && row.original.id === track?._id?.diamond,
    )?.data;
  }, [extraDetails, inTrackDiamonds, row.original.id]);

  const gotoOfferHistory = React.useCallback(() => {
    window.open(`/${PREFIX_URL}/inventory/single-stone/offer-history?id=${row.original.id}`);
  }, [row.original.id]);

  return (
    <div className="d-flex width-max-content tableSmallImage">
      <img src={iconCamera} width="15px" alt="" onClick={setShowImage.true} />
      <img src={iconVideo} width="15px" alt="" onClick={setShowVideo.true} />
      <img src={iconCert} width="15px" alt="" onClick={setShowCert.true} />
      {(extraDetails === 'Memo' || row.original.wSts === 'M' || row.original.bestBuyMemo) &&
        !['Order', 'Offer', 'OrderPending'].includes(extraDetails) && (
          <MemoDetailsCell row={row} extraDetails={extraDetails} inBlockDiamonds={inBlockDiamonds} />
        )}
      {(extraDetails === 'Hold' || row.original.wSts === 'H') &&
        !['Order', 'Offer', 'OrderPending'].includes(extraDetails) && (
          <HoldDetailsCell row={row} extraDetails={extraDetails} inBlockDiamonds={inBlockDiamonds} />
        )}
      {extraDetails === 'Order' && <OrderDetailsCell row={row} />}
      {extraDetails.includes('Offer') && <OfferDetailsCell row={row} />}
      {offerCount && !row.original.sgiHold && (
        <div className="position-relative">
          <div>
            <img src={iconHistory} alt="" width="15px" onClick={gotoOfferHistory} />
            <div className="offerCount">{offerCount}</div>
          </div>
        </div>
      )}
      <Drawer
        visible={showImage}
        onClose={setShowImage.false}
        wrapClassName="diamondListinSidebar onlyImageSlider"
        destroyOnClose
      >
        {showImage && (
          <DiamondSliderImage
            image1={FILE_URLS.img.replace('***', row.original?.mfgStnId)}
            image2={FILE_URLS.lightBlack.replace('***', row.original?.mfgStnId)}
          />
        )}
      </Drawer>
      <Drawer
        visible={showVideo}
        onClose={setShowVideo.false}
        wrapClassName="diamondListinSidebar onlyImageSlider"
        destroyOnClose
      >
        {showVideo && (
          <IFrame video tempSrc={iconVideo} src={FILE_URLS.videoFile.split('***').join(row.original?.mfgStnId)} />
        )}
      </Drawer>
      <Drawer
        visible={showCert}
        onClose={setShowCert.false}
        wrapClassName="diamondListinSidebar onlyImageSlider"
        destroyOnClose
      >
        {showCert && (
          <Embed
            src={FILE_URLS.certFile.replace('***', row.original?.rptNo)}
            height="100%"
            width="100%"
            hideOnError
            checkHeaders
          >
            <NotAvailable className="not-found" src={iconCert} type="Certificate" />
          </Embed>
        )}
      </Drawer>
    </div>
  );
});
Resource.displayName = 'Resource';

const MemoizedCell = React.memo((props) => {
  const { cell, clickHandler, row, extraDetails, noStatus, noCheck, inTrackDiamonds, inBlockDiamonds } = props;

  const handleClick = React.useCallback((e) => clickHandler(e, row, cell), [cell, clickHandler, row]);

  const cellProps = React.useMemo(
    (props) => {
      const stickyStyles = getStickyStyle({ noStatus, noCheck });
      return {
        ...props,
        style: {
          ...(cell.column.id === 'selection' && stickyStyles.cell.first),
          ...(cell.column.id === 'vStnId' && stickyStyles.cell.second),
          ...(cell.column.id === 'Details' && stickyStyles.cell.third),
          position: ['selection', 'vStnId', 'Details'].includes(cell.column.id) ? 'sticky' : undefined,
          fontWeight: ['dna'].includes(cell.column.id) ? '600' : undefined,
          color: (['shpNm', 'lbNm', 'rptNo'].includes(cell.column.id) || cell.column.link) && '#008cba',
          textDecoration: ['vStnId'].includes(cell.column.id) && 'underline',
        },
      };
    },
    [cell.column.id, cell.column.link, noCheck, noStatus],
  );

  return (
    <td {...cell.getCellProps(cellProps)} onClick={handleClick}>
      {cell.column.id === 'Details' ? (
        <Resource
          row={row}
          extraDetails={extraDetails}
          inTrackDiamonds={inTrackDiamonds}
          inBlockDiamonds={inBlockDiamonds}
        />
      ) : SHOW_ALT_CERT && row.original.altCert && ALT_COLUMNS.includes(cell.column.id) ? (
        <span style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <span style={{ width: '100%' }}>{cell.render('Cell')}</span>
          <span
            style={{ width: '100%' }}
            className={classNames([[...FLOAT_COLUMNS, ...ROUND_COLUMNS].includes(cell.column.id) && 'numberValue'])}
          >
            {formatValues(row.original.altCert[cell.column.id], cell.column.id)}
          </span>
        </span>
      ) : (
        cell.render('Cell')
      )}
    </td>
  );
});
MemoizedCell.displayName = 'MemoizedCell';

const TrRow = React.memo((props) => {
  const { row, noCheck, noStatus } = props;
  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // move row
      const r1 = props.data[dragIndex];
      if (props.data[dragIndex]?.sortingSequence) {
        const b = props.data[dragIndex]?.sortingSequence;
        props.data[dragIndex].sortingSequence = props.data[hoverIndex].sortingSequence;
        props.data[hoverIndex].sortingSequence = b;
      }
      let newlist = [...props.data];
      newlist = [...newlist.slice(0, dragIndex), ...newlist.slice(dragIndex + 1, newlist.length)];
      newlist = [...newlist.slice(0, hoverIndex), r1, ...newlist.slice(hoverIndex, newlist.length)];
      if (props.moverow) props.moverow(newlist);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [showDetail, setShowDetail] = useBoolean();

  const opacity = isDragging ? 0 : 1;
  preview(drop(dropRef));
  drag(dragRef);

  // common functions
  const clickHandler = React.useCallback(
    (e, row, cell) => {
      switch (cell?.column?.id) {
        case 'selection':
          return;

        case 'shpNm':
        case 'vStnId':
          return setShowDetail.true();

        case 'dna':
          return window.open(`/${RouteAdmin}/dna/${row.original.id}`);

        case 'ftc':
          return !isEmpty(cell.value) && window.open(`/ftc/${row.original.id}`);

        case 'lbNm':
        case 'rptNo':
          return (
            !isEmpty(row.original.lbNm) &&
            LAB_LINKS[row.original.lbNm.toUpperCase()] &&
            !isEmpty(row.original.rptNo) &&
            window.open(LAB_LINKS[row.original.lbNm.toUpperCase()].replace('***', row.original.rptNo))
          );

        default:
          break;
      }

      if (cell.column.link && typeof cell.column.link === 'string') {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        const link = cell.column.link.replace('$' + field + '$', row.original[field]);
        window.open(link);
      } else if (!NOCHECK_COLUMNS.includes(cell.column.id)) {
        if (noCheck) return;
        const parentNode = e.currentTarget.parentNode;
        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName('input');
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [noCheck, setShowDetail],
  );

  const rowProps = React.useMemo(() => {
    return { 'table-row': props.currentType + row.original.id };
  }, [props.currentType, row.original.id]);

  return (
    <>
      {props.dragDrop && (
        <div className="tableSortingTd" ref={dragRef}>
          <img src={sortingLightSvg} />
        </div>
      )}
      <tr {...row.getRowProps(rowProps)} ref={dropRef} style={{ opacity }}>
        {row.cells.map((cell) => (
          <MemoizedCell
            key={cell.column.id + '_cell'}
            row={row}
            cell={cell}
            clickHandler={clickHandler}
            noCheck={noCheck}
            noStatus={noStatus}
            extraDetails={props?.extraDetails}
            inTrackDiamonds={props?.inTrackDiamonds}
            inBlockDiamonds={props?.inBlockDiamonds}
          />
        ))}
      </tr>
      <Drawer onClose={setShowDetail.false} visible={showDetail} wrapClassName="diamondDetailPopup" destroyOnClose>
        {showDetail && (
          <DiamondDetail
            data={row.original}
            onClose={setShowDetail.false}
            tabPrintExcel={props?.tabPrintExcel}
            inDiamondPopup
          />
        )}
      </Drawer>
    </>
  );
});
TrRow.displayName = 'TrRow';

const Table = React.memo((props) => {
  const {
    initialSort = [],
    handleSort = () => { },
    allChecked = false,
    noSort = false,
    noCheck = false,
    noStatus = false,
    noDots = false,
    noGrouping = false,
    noFilter = false,
    currentType,
    liveStatus,
    historyStatus,
    bestBuyInitSts,
    inBlockDiamonds, // For Sgi Memo and Hold
  } = props;

  const data = React.useMemo(() => {
    return isArray(props.data)
      ? updateList(
        props.data.map((row) => ({ ...row })),
        { inBlockList: inBlockDiamonds, inOrder: props.inOrder },
      )
      : [];
  }, [inBlockDiamonds, props.data, props.inOrder]);

  const isMultiSortEvent = React.useCallback(() => true, []);


  const unavailableDiamonds = new Set((inBlockDiamonds || []).flatMap(x => x.blockDetails).map(x => x.diamond))


  const actionColumn = React.useMemo(() => {
    return {
      id: 'selection',
      Header({ rows }) {
        const originalRows = React.useMemo(() => rows.map((row) => row.original), [rows]);

        return (
          <Actions
            isHeader
            rows={originalRows}
            liveStatus={liveStatus}
            historyStatus={historyStatus}
            allChecked={allChecked}
            noStatus={noStatus}
            noCheck={noCheck}
            currentType={currentType}
            unavailable={unavailableDiamonds}
          />
        );
      },
      Cell({ row }) {
        return (
          <Actions
            row={row.original}
            liveStatus={liveStatus}
            historyStatus={historyStatus}
            allChecked={allChecked}
            noStatus={noStatus}
            noCheck={noCheck}
            currentType={currentType}
            bestBuyInitSts={bestBuyInitSts}
            unavailable={unavailableDiamonds}
          />
        );
      },
    };
  }, [allChecked, bestBuyInitSts, currentType, historyStatus, liveStatus, noCheck, noStatus]);

  const columns = React.useMemo(() => {
    if (!isArray(props.columns)) return [];
    if (noCheck && noStatus) return props.columns;
    const _columns = [...props.columns];
    const selectionIndex = _columns.findIndex((column) => column?.id === 'selection');
    selectionIndex > -1 ? (_columns[selectionIndex] = actionColumn) : _columns.unshift(actionColumn);
    return _columns;
  }, [actionColumn, props.columns, noCheck, noStatus]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        sortBy: initialSort
          .map((item) => {
            return Object.entries(item).map(([id, desc]) => {
              return { id, desc: `${desc}`.toUpperCase() === 'DESC' };
            })?.[0];
          })
          .filter(isNotEmpty),
      },
      manualSortBy: true,
      isMultiSortEvent,
      disableSortBy: noSort,
    },
    useFilters,
    useSortBy,
  );

  React.useEffect(() => {
    if (!isArray(rows) || noCheck) return;
    RowSelectService.resetSelectedRows(currentType);
  }, [currentType, noCheck, rows]);

  React.useEffect(() => {
    if (noSort) return;
    handleSort(sortBy.map(({ id, desc }) => ({ [id]: desc ? 'DESC' : 'ASC' })));
  }, [handleSort, noSort, sortBy]);

  const getGroupTitle = React.useCallback(
    (record, options) => {
      return memoizedGetGroupTitle(record, {
        user: props?.sortUser,
        date: props?.sortDate,
        dateCustomer: props?.sortDateCustomer,
        sortStone: props?.sortStone,
        country: props?.sortCountry,
        salesPerson: props?.sortSalesPerson,
        orderShow: props?.orderShow,
        stageShow: props?.stageShow,
        bidGrouping: props?.currentType == 'BidTransectList' && props?.sortStone,
        ...options,
      });
    },
    [
      props?.sortUser,
      props?.sortDate,
      props?.sortDateCustomer,
      props?.sortStone,
      props?.sortCountry,
      props?.sortSalesPerson,
      props?.orderShow,
      props?.stageShow,
      props?.currentType,
    ],
  );

  const getHeaderProps = React.useCallback(
    ({ column }) => {
      const stickyStyles = getStickyStyle({ noStatus, noCheck });
      return {
        style: {
          ...(column.id === 'selection' && stickyStyles.head.first),
          ...(column.id === 'vStnId' && stickyStyles.head.second),
          ...(column.id === 'Details' && stickyStyles.head.third),
          width: (column.width || '100') + 'px',
          textAlign: column.cellClass ? column.cellClass.replace('text-', '') : 'center',
        },
        className: classNames([
          (FLOAT_COLUMNS.includes(column?.id) || ROUND_COLUMNS.includes(column?.id)) && 'number-header',
        ]),
      };
    },
    [noCheck, noStatus],
  );

  const displayTotal = React.useMemo(() => {
    return columns.filter((column) => TOTAL_COLUMNS.includes(column?.id)).map((column) => column?.id);
  }, [columns]);

  const tableProps = React.useMemo(() => getTableProps(), [getTableProps]);
  const tableBodyProps = React.useMemo(() => getTableBodyProps(), [getTableBodyProps]);

  if (isEmpty(rows)) return <NoDataShow />;

  return (
    <table {...tableProps}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, k) => {
              return (
                <th key={k} {...column.getHeaderProps(getHeaderProps({ column }))}>
                  {['selection', 'actions', 'action'].includes(column.id) ? (
                    column.render('Header')
                  ) : (
                    <ListTableHeaderBack
                      toggleSortBy={toggleSortBy}
                      setSortBy={setSortBy}
                      sortBy={sortBy}
                      column={column}
                      filters={filters}
                      noDots={noDots}
                      noFilter={noFilter}
                    />
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...tableBodyProps}>
        <DndProvider backend={HTML5Backend}>
          {!isArray(rows.length) &&
            rows.map((row, index) => {
              const { isHeader, isHoldHeader, isMatchHeader, userGroupHold } = row.original;
              const filterIsEmpty = isEmpty(filters);

              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  {!noGrouping && filterIsEmpty && (
                    <>
                      {isHeader && isHoldHeader && (
                        <TableGrouping groupingTitle={row.original.blockCode} row={row.original} />
                      )}
                      {isHeader && isMatchHeader && (
                        <TableGrouping
                          displayTotal={displayTotal}
                          totalDiamonds={row.original.totalDiamonds}
                          groupingTitle={row.original.groupName}
                          columns={columns}
                          row={row.original}
                        />
                      )}
                      {isHeader && userGroupHold && (
                        <TableGrouping
                          displayTotal={displayTotal}
                          openDna={props}
                          totalDiamonds={row.original.totalDiamonds}
                          groupingTitle={getGroupTitle(row.original)}
                          columns={columns}
                          row={row.original}
                        />
                      )}
                    </>
                  )}
                  <TrRow row={row} index={row.index} {...props} />
                </React.Fragment>
              );
            })}
        </DndProvider>
      </tbody>
    </table>
  );
});
Table.displayName = 'Table';

export function TableWrapper(props) {
  const { loading, data, columns } = props;

  if (loading) return <CircularProgress />;
  if (isEmpty(data) || isEmpty(columns)) return <NoDataShow />;
  return <Table {...props} />;
}

TableWrapper.propTypes = {
  data: PropTypes.array, // table data
  loading: PropTypes.bool, // data && grid column loading
  columns: PropTypes.array, // grid column response
  currentType: PropTypes.string, // diamond reducer prop
  initialSort: PropTypes.array, // initial sort
  handleSort: PropTypes.func, // called on sort change

  noSort: PropTypes.bool, // remove sSorting
  noCheck: PropTypes.bool, // remove checkbox
  noStatus: PropTypes.bool, // remove status
  noDots: PropTypes.bool, // remove dots
  noGrouping: PropTypes.bool, // remove grouping
  noFilter: PropTypes.bool, // remove header filter

  sortCountry: PropTypes.bool, // is grouped by Country
  sortDate: PropTypes.bool, // is grouped by Date
  sortDateCustomer: PropTypes.bool, // is grouped by DateCustomer
  sortSalesPerson: PropTypes.bool, // is grouped by SalesPerson
  sortUser: PropTypes.bool, // is grouped by User

  bestBuyInitSts: PropTypes.bool, // check initSts and show "status / best buy"
};

export default TableWrapper;
