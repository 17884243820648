import React from 'react';

import { Select } from 'antd';
import './search.less';

function SearchWithSelect({ children, ...restProps }) {
  return (
    <Select
      className="searchWithSelect"
      optionFilterProp="children"
      // onSearch={onSearch}
      allowClear
      showArrow
      showSearch
      {...restProps}
    >
      {/* {props.selectValue.map((d,index) => (
            <Select.Option value={d.value}>{d.value}</Select.Option>
        ))} */}
      {children}
    </Select>
  );
}
export default SearchWithSelect;
