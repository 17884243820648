import React from 'react';
import { useSelector } from 'react-redux';

import { classNames } from 'util/classNames';

import loaderSvg from 'assets/images/finestar.svg';

function ConnectedProgress() {
  const loading = useSelector((state) => state?.loader?.loading);

  React.useEffect(() => {
    const hasClass = document.body.classList.contains('progress');
    loading > 0
      ? !hasClass && document.body.classList.add('progress')
      : hasClass && document.body.classList.remove('progress');
  }, [loading]);

  return loading ? (
    <div className={classNames([`loader`, `fixLoader`])}>
      <img src={loaderSvg} alt="loader" />
    </div>
  ) : null;
}

export default React.memo(ConnectedProgress);
