import { callApiAsync } from 'services/util';

import { isEmpty } from 'util/utils';

import { API_ROUTES } from 'constants/CommonApi';
import { store } from 'appRedux/store/index';

function updateRequest(request) {
  return { request: { ...request, labGrown: store.getState().commonData?.labGrown || 'N' } };
}

export const insertDiamondBlock = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Memo.Insert, request, ...(!isEmpty(config) && { config }) });

export const changeStatusDiamondBlock = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Memo.changeStatus, request, ...(!isEmpty(config) && { config }) });

export const setStageMemo = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.StageMemo.paginate, request, ...(!isEmpty(config) && { config }) });

export const memoHoldPaginate = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Memo.paginate, ...updateRequest(request), ...(!isEmpty(config) && { config }) });

export const viewDetails = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Memo.viewDetails, request, ...(!isEmpty(config) && { config }) });

export const getMemoPdf = (request = {}, config) =>
  callApiAsync({ ...API_ROUTES.Memo.GeneratePdf, request, ...(!isEmpty(config) && { config }) });
