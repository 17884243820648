import React from 'react';
import { Link } from 'react-router-dom';

const MoreOptionItem = (props) => {
  return (
    <Link to={props.Link ? props.Link : ''}>
      <img src={props.image} alt="icon" className={props.className ? props.className : null} />
      {props.title}
    </Link>
  );
};
export default MoreOptionItem;
