import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { loadable } from 'util/asyncComponent';

import { PREFIX_URL as URL } from 'constants/CommonUrl';

export const RouteAdmin = URL;

function Routes({ match }) {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}${URL}/dashboard`} component={loadable(() => import('./Dashboard'))} />
        <Route path={`${match.url}${URL}/inventory`} component={loadable(() => import('./Inventory'))} />
        <Route path={`${match.url}${URL}/client`} component={loadable(() => import('./Client'))} />
        <Route path={`${match.url}${URL}/transaction`} component={loadable(() => import('./Transaction'))} />
        <Route path={`${match.url}${URL}/user`} component={loadable(() => import('./User'))} />
        <Route path={`${match.url}${URL}/notification`} component={loadable(() => import('./Notifications'))} />
        <Route path={`${match.url}${URL}/setting`} component={loadable(() => import('./Settings'))} />
        <Route path={`${match.url}${URL}/master`} component={loadable(() => import('./Master'))} />
        <Route path={`${match.url}${URL}/sub-master/:id?`} component={loadable(() => import('./SubMaster'))} />
        <Redirect from="*" to={`${match.url}${URL}/dashboard`} />

        {/* <Route path={match.url + RouteAdmin + '/document'} component={asyncComponent(() => import('./Documents'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/pricing'} component={asyncComponent(() => import('./Pricing'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/setting'} component={asyncComponent(() => import('./Setting'))} /> */}
        {/* <Route path={`${match.url}${RouteAdmin}emailsync/email`} component={asyncComponent(() => import('./EmailSync'))} /> */}
        {/* <Route path={`${match.url}${RouteAdmin}emailsync/call-log`} component={asyncComponent(() => import('./EmailSync'))} /> */}
        {/* <Route path={`${match.url}${RouteAdmin}emailsync/meeting`} component={asyncComponent(() => import('./EmailSync'))} /> */}
        {/* <Route path={`${match.url}${RouteAdmin}emailsync/calendar`} component={asyncComponent(() => import('./EmailSync'))} /> */}
        {/* <Route path={`${match.url}${RouteAdmin}todo`} component={asyncComponent(() => import('./Todo'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/notification'} component={asyncComponent(() => import('./Notifications'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/setting/notifications'} component={asyncComponent(() => import('./NotificationsSetting'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/setting/contact-directory'} component={asyncComponent(() => import('./ContactDirectory'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/setting/profile'} component={asyncComponent(() => import('./EditProfile'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/compare'} component={asyncComponent(() => import('./Compare'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/setting/configuration'} component={asyncComponent(() => import('./Configuration'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/setting/home-setting'} component={asyncComponent(() => import('../components/Settings/HomeSettings'))} /> */}
        {/* <Route path={match.url+RouteAdmin+'/inventory/carat-range'} component={CaratRange}/> */}
        {/* <Route path={match.url + RouteAdmin + '/inventory/configuration/carat-range'} component={asyncComponent(() => import('../components/Inventory/Configuration/CaratRange'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/inventory/configuration'} component={asyncComponent(() => import('./Inventory'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/xray/:id'} component={asyncComponent(() => import('./Inventory/XRAY'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/diamond-ftp/:id'} component={asyncComponent(() => import('./Inventory/FTC'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/offer'} component={asyncComponent(() => import('./Offer'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/contact'} component={asyncComponent(() => import('./ContactUs'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/suggestion'} component={asyncComponent(() => import('./ContactUs'))} /> */}
        {/* <Route path={match.url+RouteAdmin+'/profile'} component={asyncComponent(() => import('./MyAccount'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/banner'} exact component={asyncComponent(() => import('./Banner'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/banner/upsert/:id?'} component={asyncComponent(() => import('./Banner/BannerUpsertForm'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/location'} exact component={asyncComponent(() => import('./Location'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/location/upsert/:id?'} component={asyncComponent(() => import('./Location/LocationUpsert'))} /> */}

        {/* <Route path={match.url+RouteAdmin+'/product/:id?'} component={asyncComponent(() => import("./Product"))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/account'} component={asyncComponent(() => import('./Account'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/account-details/:id?'} component={asyncComponent(() => import('./Account/AccountUpsert'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/permission/:user?/:id?'} component={asyncComponent(() => import('./Permission'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/rolemaster'} component={asyncComponent(() => import('./RoleManager'))} /> */}
        {/* <Route path={match.url+RouteAdmin+'/size-master'} component={asyncComponent(() => import("./SizeMaster"))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/page-tracking'} component={asyncComponent(() => import('./PageTrack'))} /> */}
        {/*<Route path={match.url+RouteAdmin+'/terms'} exact component={asyncComponent(() => import("./Terms"))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/country/:id?'} component={asyncComponent(() => import('./Country'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/state/:id?'} component={asyncComponent(() => import('./State'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/city/:id?/:countryId?'} component={asyncComponent(() => import('./City'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/upload-history'} component={asyncComponent(() => import('./UploadHistory'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/stock-summary'} component={asyncComponent(() => import('./StockSummary'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/uploaded-sheet-details/:id'} component={asyncComponent(() => import('./UploadHistory/InvalidDiamond'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/diamond-details/:id'} component={asyncComponent(() => import('../components/DiamondDetail'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/gridcolumn'} component={asyncComponent(() => import('./GridColumn/index'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/stock'} component={asyncComponent(() => import('./Diamond'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/cart/:id?'} component={asyncComponent(() => import('./Cart'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/watchlist/:id?'} component={asyncComponent(() => import('./Watchlist'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/reminder/:id?'} component={asyncComponent(() => import('./Reminder'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/enquiry'} component={asyncComponent(() => import('./Enquiry'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/enquiry'} component={asyncComponent(() => import('../components/Enquiry/EnquiryDetail'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/enquiry/enquiry/confirm'} component={asyncComponent(() => import('../components/Enquiry/EnquiryDetail'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/enquiry/enquiry/hold'} component={asyncComponent(() => import('../components/Enquiry/EnquiryDetail'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/reports'} component={asyncComponent(() => import('./Reports'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/comment/:id?'} component={asyncComponent(() => import('./Comment'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/ex'} component={asyncComponent(() => import('./GridColumn/ex'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/parcel-master'} component={asyncComponent(() => import('./ParcelMaster'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/feedback'} component={asyncComponent(() => import('./Feedback'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/featured-stone'} component={asyncComponent(() => import('./FeaturedStone'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/cabin'} component={asyncComponent(() => import('./Cabin'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/slot'} component={asyncComponent(() => import('./Slot'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/schedule'} component={asyncComponent(() => import('./Schedule'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/notification-setting'} component={asyncComponent(() => import('./NotificationSetting'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/notification/upsert/:id?'} component={asyncComponent(() => import('./NotificationSetting/NotificationUpsert'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/settingtable'} component={asyncComponent(() => import('./Setting/settingTable'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/version-apk'} component={asyncComponent(() => import('./Version'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/report'} component={asyncComponent(() => import('./Dashboard'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/platform'} component={asyncComponent(() => import('./Platform'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/general-setting'} component={asyncComponent(() => import('./GeneralSetting'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/transport'} component={asyncComponent(() => import('./Transport'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/hospitality'} component={asyncComponent(() => import('./Hospitality'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/career'} component={asyncComponent(() => import('./Career'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/search-history'} component={asyncComponent(() => import('./SearchHistory'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/hit-counter'} component={asyncComponent(() => import('./HitCounter'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/sync-setting'} component={asyncComponent(() => import('./SyncSetting'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/ftp-request'} component={asyncComponent(() => import('./FtpRequest'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/theme-setup'} component={asyncComponent(() => import('./ThemeSetup'))} /> */}
        {/* <Route path={match.url + RouteAdmin + '/diamond-details/:id'} component={asyncComponent(() => import('../components/DiamondDetail'))} /> */}
      </Switch>
    </div>
  );
}

export default Routes;
