import React from 'react';
import Slider from 'react-slick';

import CircularProgress from 'components/CircularProgress';
import MagnifierImage from 'components/DiamondDetail/MagnifierImage';
import NotAvailable from 'components/DiamondDetail/NoDataFoundComponent';

import { isArray, isEmpty } from 'util/utils';

import Image from './Image';

import { ReactComponent as NoImageIcon } from 'assets/svg/NoImg.svg';
import cameraSvg from 'assets/svg/camera.svg';

function ImageSliderInner(props) {
  const { list = [], noNav = false, ..._props } = props;

  const [sliderRef, setSliderRef] = React.useState();
  const [navRef, setNavRef] = React.useState();

  const filteredList = React.useMemo(() => {
    if (!isArray(list)) return [];
    return list.filter((image) => image?.available);
  }, [list]);

  const slideNotFound = <NotAvailable className="nodatFoundImage" src={cameraSvg} type="Image" />;

  return (
    <div className="diamondImagePopup">
      <Slider asNavFor={navRef} ref={setSliderRef} arrows={false} swipeToSlide {..._props}>
        {filteredList.map(({ available, ...imageProps }, index) => {
          return (
            <div className="diamondDetailImageBox" key={index}>
              {available ? <MagnifierImage {...imageProps}>{slideNotFound}</MagnifierImage> : slideNotFound}
            </div>
          );
        })}
        {isEmpty(filteredList) && <div className="diamondDetailImageBox">{slideNotFound}</div>}
      </Slider>
      {!noNav && (
        <Slider
          ref={setNavRef}
          asNavFor={sliderRef}
          className="smallSliderBlock smallSliderBlockimage"
          slidesToShow={4}
          focusOnSelect
          swipeToSlide
        >
          {filteredList.map(({ available, ...imageProps }, index) => {
            const notFound = <NoImageIcon />;

            return (
              <div className="smallSliderImage" key={index}>
                {available ? <Image {...imageProps}>{notFound}</Image> : notFound}
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
}

function ImageSlider(props) {
  const { loading, ...restProps } = props;

  const propsRef = React.useRef(restProps);

  React.useEffect(() => {
    propsRef.current = restProps;
  }, [restProps]);

  if (loading) return <CircularProgress />;

  return <ImageSliderInner {...propsRef.current} />;
}

export default React.memo(ImageSlider);
