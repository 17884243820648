import { USERS_TYPE } from 'constants/Common';

const getInitialState = () => ({
  clientList: {
    count: 0,
    clientList: [],
    detailShow: true,
    listShow: false,
    visible: true,
    show: true,
    customerDetail: null,
    filter: { page: 1, limit: 10 },
    clientStates: [],
    clientCities: [],
    startWith: { keyword: null, keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'] },
  },
  userList: {
    filter: {
      type: [USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN, USERS_TYPE.SELLER, USERS_TYPE.MASTER_SALES],
    },
    page: 1,
    limit: 10,
    data: [],
    count: 0,
    activeData: {},
    startWith: { keyword: null, keys: ['companyName', 'name', 'firstName', 'lastName', 'mobile'] },
  },
});

export const TabActionTypes = {
  SET_CLIENT_LIST: 'SET_CLIENT_LIST',
  SET_USER_LIST: 'SET_USER_LIST',
  RESET_TAB: 'RESET_TAB',
};

export const TabActions = {
  setClientList: (client) => ({ type: TabActionTypes.SET_CLIENT_LIST, payload: client }),
  setUserList: (user) => ({ type: TabActionTypes.SET_USER_LIST, payload: user }),
  resetList: () => ({ type: TabActionTypes.RESET_TAB }),
};

const initialState = { ...getInitialState() };

export default (state = initialState, action) => {
  switch (action?.type) {
    case TabActionTypes.SET_CLIENT_LIST:
      return { ...state, clientList: { ...state.clientList, ...action?.payload } };

    case TabActionTypes.SET_USER_LIST:
      return { ...state, userList: { ...state.userList, ...action?.payload } };

    case TabActionTypes.RESET_TAB:
      return { ...getInitialState() };

    default:
      return state;
  }
};
