import UtilService from 'services/util';

import { isEmpty } from 'util/utils';

import ConstantApi from 'constants/CommonApi';

export const getBannerList = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Banner.paginate, request, ...(!isEmpty(config) && { config }) });

export const storeBanner = (request = {}, config) =>
  UtilService.callApiAsync({ ...ConstantApi.Banner.add, request, ...(!isEmpty(config) && { config }) });

export const findBanner = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Banner.view, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const updateBanner = (id, request = {}, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Banner.update, [id]),
    request,
    ...(!isEmpty(config) && { config }),
  });

export const deleteBanner = (id, config) =>
  UtilService.callApiAsync({
    ...UtilService.withUrlParams(ConstantApi.Banner.delete, [id]),
    ...(!isEmpty(config) && { config }),
  });
