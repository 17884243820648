const getBankAccountTypes = () => ({
  CURRENT: 'Current A/C',
  SAVINGS: 'Savings A/C',
});

const getBankAccountTypeOptions = () => {
  const BankAccountTypes = getBankAccountTypes();
  return Object.entries(BankAccountTypes).map(([key, value]) => ({ key, value }));
};

export default {
  getBankAccountTypes,
  getBankAccountTypeOptions,
};
