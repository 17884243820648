import React from 'react';

import './checkBox.less';

const CheckBox = React.memo(
  React.forwardRef((props, ref) => {
    const { label, indeterminate, ...rest } = props;

    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="customCheckBox d-flex flex-wrap">
        <input type="checkbox" ref={resolvedRef} {...rest} />
        <span className="customCheckBoxLebel"></span>
        {label && <label>{label}</label>}
      </div>
    );
  }),
);

CheckBox.displayName = 'CheckBox';

export default CheckBox;
