import 'util/Yup';

import React from 'react';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';

import ConnectedLoader from 'components/CircularProgress/ConnectedLoader';

import NextApp from 'NextApp';
import { store } from 'appRedux/store';

import { unregister } from './registerServiceWorker';

const CacheContainer =
  process.env.REACT_APP_ENV !== 'development'
    ? function CacheContainer({ children }) {
        return <ClearCacheProvider duration={180000}>{children}</ClearCacheProvider>;
      }
    : function CacheContainer() {
        return null;
      };

const ClearCache =
  process.env.REACT_APP_ENV !== 'development'
    ? function ClearCache() {
        const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

        React.useEffect(() => {
          if (!isLatestVersion) emptyCacheStorage();
        }, [emptyCacheStorage, isLatestVersion]);

        return null;
      }
    : function ClearCache() {
        return null;
      };

function App() {
  return (
    <Provider store={store}>
      <CacheContainer>
        <ClearCache />
      </CacheContainer>
      <ConnectedLoader />
      <AppContainer>
        <NextApp />
      </AppContainer>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

unregister();
