import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'util/classNames';

const ListFooter = ({ hidden, sticky, contentCenter, contentSpaceBetween, homeSetting, className, ...props }) => {
  return (
    !hidden && (
      <div
        className={classNames([
          'botoomStickyBar',
          sticky && 'positionSticky',
          homeSetting && 'homeSettingfooter',
          contentCenter && 'justify-content-center',
          contentSpaceBetween && 'j-space-between',
          className,
        ])}
        {...props}
      >
        {props.children}
      </div>
    )
  );
};

ListFooter.propTypes = {
  children: PropTypes.node,
  homeSetting: PropTypes.bool,
  sticky: PropTypes.bool,
  contentCenter: PropTypes.bool,
  contentSpaceBetween: PropTypes.bool,
  className: PropTypes.string,
};

export default ListFooter;
