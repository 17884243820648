import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { isArray, isEmpty } from 'util/utils';

import SortingFilterDropdownItem from './OverlayItemBack';

const OverlayDropdownBack = React.memo(({ column, setSortBy, toggleSortBy, noFilter, ...props }) => {
  const sortBy = React.useMemo(
    () => (isArray(props?.sortBy) ? props?.sortBy.filter((el) => el.id !== 'createdAt') : props?.sortBy),
    [props?.sortBy],
  );

  return (
    <div className="menuInnerFilterOption">
      <div className="menuInnerFilterInner">
        {isArray(sortBy) && !isEmpty(sortBy) && (
          <>
            <div className="llSortingTitle">Sorting Order</div>
            <div className="llSortingTitleList">
              <DndProvider backend={HTML5Backend}>
                {sortBy.map((sort, index) => (
                  <SortingFilterDropdownItem
                    key={sort?.id}
                    index={index}
                    setSortBy={setSortBy}
                    currentSort={sort}
                    sortBy={sortBy}
                    toggleSortBy={toggleSortBy}
                  />
                ))}
              </DndProvider>
            </div>
          </>
        )}
        {!noFilter && column?.canFilter && column.render('Filter')}
      </div>
    </div>
  );
});

export default OverlayDropdownBack;
