import React from 'react';
import { Link } from 'react-router-dom';

import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';

const SingleStoneCollection = () => {
  return (
    <div className="subTabInnerDropdown">
      <ul>
        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/single-stone/collection/trending'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/single-stone/collection/trending'}>Trending</Link>
        </li>
        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/single-stone/collection/best-of-cs'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/single-stone/collection/best-of-cs'}>Best of CS</Link>
        </li>
        <li
          className={
            window.location.pathname === PREFIX_URL_WITH_SLASH + '/inventory/single-stone/collection/premium'
              ? 'active'
              : null
          }
        >
          <Link to={PREFIX_URL_WITH_SLASH + '/inventory/single-stone/collection/premium'}>Premium</Link>
        </li>
      </ul>
    </div>
  );
};
export default SingleStoneCollection;
