import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useTable } from 'react-table';

import { Popconfirm } from 'antd';
import { DateTime } from 'luxon';

import PlusIcon from 'containers/PlusIcon';
import StatusBlock from 'containers/StatusBlock';

import { LIMIT } from 'components/Client/Table';
import { NoDataFound } from 'components/Inventory/Table';
import ListFooter from 'components/List/ListFooter';
import Pagination from 'components/List/Pagination';

import { showNotification } from 'services/NotificationService';

import { EVENT, BASE_URL } from 'constants/Common';

import * as VersionAPI from './VersionApi';

class VersionList extends Component {
  state = {
    page: 1,
    limit: LIMIT,
    data: [],
    count: 0,
  };

  componentDidMount() {
    this.getVersions();
  }

  getVersions = async () => {
    this.setState({ loading: true });
    const { page, limit } = this.state;
    const [, res] = await VersionAPI.getVersionList({ page, limit, filter: { platform: this.props?.platform } });

    const list = res?.data?.list;

    this.setState(
      res.code === 'OK' && list?.constructor?.name === 'Array'
        ? { data: list, count: res.data.count, loading: false }
        : { data: [], count: 0, loading: false },
    );
  };

  updateVersionDetail = async ({ ...request }) => {
    const [, res] = await VersionAPI.updateVersion({ ...request });
    showNotification(res);

    if (res?.code === 'OK') this.getVersions();
  };

  deleteVersionDetail = async (id) => {
    const [, res] = await VersionAPI.deleteVersion(id);
    showNotification(res);

    if (res?.code === 'OK') this.getVersions();
  };

  render() {
    const columns = [
      { Header: 'SR Number', width: '50px', accessor: 'srno', Cell: ({ row }) => ++row.id },
      {
        Header: 'Version Name',
        accessor: 'name',
      },
      { Header: 'Version Number', accessor: 'number' },
      {
        Header: 'platform',
        Cell: ({ row }) => (
          <div>
            <span>{row.original.platform == 1 ? 'Android' : 'Iphone'}</span>
          </div>
        ),
      },
      {
        Header: 'Hard Update',

        Cell: ({ row }) => {
          const [isHardUpdate, setIsHardUpdate] = React.useState(row?.original?.isHardUpdate);

          return (
            <div
              onClick={async () => {
                const newIsHardUpdate = !isHardUpdate;
                row.original.isHardUpdate = newIsHardUpdate;
                const success = await this.updateVersionDetail({ id: row.original.id, isHardUpdate: newIsHardUpdate });
                success && setIsHardUpdate(newIsHardUpdate);
              }}
            >
              <StatusBlock status={isHardUpdate ? 'active' : 'InActive'} title={isHardUpdate ? 'Yes' : 'No'} />
            </div>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton j-flex-start">
            <div className="actionAction" onClick={() => this.props.editVersionDetail(row.original.id)}>
              <img src={require('assets/svg/edit.svg')} />
            </div>
            <Popconfirm
              placement="left"
              title={`Are you sure you want to delete this Version?`}
              okText={`Delete`}
              onConfirm={() => this.deleteVersionDetail(row.original.id)}
            >
              <div className="actionAction">
                <img src={require('assets/svg/delet.svg')} />
              </div>
            </Popconfirm>
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, key) => (
              <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, key) => (
                  <th key={key} {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, key) => (
                    <td key={key} width="100px" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <>
        <PlusIcon onClick={this.props.onClick} />
        <div className="contactDirectoryWrapper">
          <div className="contactListMain">
            <div className="contactList">
              <Table columns={columns} data={this.state.data} />
              <NoDataFound length={this.state.data?.length} />
            </div>
          </div>
        </div>
        <ListFooter homeSetting flush>
          <span style={{ margin: 'auto' }}>
            <Pagination
              page={this.state?.page}
              limit={this.state?.limit}
              count={this.state?.count}
              length={this.state?.data.length}
              onPaginationChange={(page, limit) => this.setState({ page, limit }, () => this.getVersions())}
            />
          </span>
        </ListFooter>
      </>
    );
  }
}

export default withRouter(VersionList);
