import React from 'react';

import { Select } from 'antd';

import { classNames, hasKey, isArray, isFunction } from 'util/utils';
import './selectOption.less';

function SelectOption({ orderDetail, defaultValue, required, ...props }) {
  const { label, className, selectValue, options, nameKey, valueKey, error, ...rest } = props;

  const optionList = React.useMemo(() => {
    const list = isArray(options) ? options : isArray(selectValue) ? selectValue : [];
    const defaultkeys = hasKey(list?.[0], 'key') && hasKey(list?.[0], 'value');

    const _nameKey = defaultkeys ? nameKey ?? 'value' : nameKey ?? 'label';
    const _valueKey = defaultkeys ? valueKey ?? 'key' : valueKey ?? 'value';

    return (
      isArray(list) &&
      list.map((option) => {
        const value = rest?.type === 'number' ? option?.[_valueKey] : `${option?.[_valueKey]}`;
        const label = defaultkeys ? option?.value : option?.[_nameKey];
        return (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        );
      })
    );
  }, [nameKey, options, rest?.type, selectValue, valueKey]);

  const handleVisibleChange = React.useCallback(
    (open) => {
      if (!open && isFunction(rest.onBlur)) rest.onBlur();
    },
    [rest],
  );

  return (
    <div className={classNames([`formSelect from-group`, className, error && ' error-message'])}>
      <label>
        {label} {required && <span className="required-ast">*</span>}
      </label>
      <Select
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onDropdownVisibleChange={handleVisibleChange}
        allowClear
        showSearch
        showArrow
        {...rest}
      >
        {optionList}
      </Select>
      {Boolean(error) && <div className="error">{error}</div>}
    </div>
  );
}

export default React.memo(SelectOption);
