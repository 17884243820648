import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Layout } from 'antd';

import App from 'routes/index';

import { Sidebar } from 'containers/Sidebar';

function MainApp() {
  const match = useRouteMatch();

  return (
    <Layout className={'gx-app-layout'}>
      <Sidebar />
      <Layout>
        <Layout.Content className={'gx-layout-content gx-container-wrap'}>
          <App match={match} />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default MainApp;
