import { PREFIX_URL_WITH_SLASH, COMMON_URL } from 'constants/CommonUrl';

export const INVENTORY_PAGE = {
  newArrival: `${PREFIX_URL_WITH_SLASH}/inventory/single-stone/new-arrival`,
  upcoming: `${PREFIX_URL_WITH_SLASH}/inventory/single-stone/upcoming`,
};

export const FORNT_SIDE_URL = {
  diamondDetail: `${COMMON_URL.FRONT_SITE_URL}/diamond-detail`,
};
