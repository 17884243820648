import { getTermsDetailList } from 'services/TermsDetailApi';

import { isArray } from 'util/utils';

const initialState = {};

export const TermDetailActionTypes = {
  SET_EDIT_TERM: 'SET_EDIT_TERM',
  SET_TERM_DETAILS_LIST: 'SET_TERM_DETAILS_LIST',
  GET_TERM_DETAILS_LIST: 'GET_TERM_DETAILS_LIST',
};

export const TermDetailActions = {
  setEditTerm: (user) => ({
    type: TermDetailActionTypes.SET_EDIT_TERM,
    payload: user,
  }),
  setTermDetailsList: (id, list) => ({
    type: TermDetailActionTypes.SET_TERM_DETAILS_LIST,
    payload: { id, list },
  }),
  getTermDetailsList: (id, page, limit) => async (dispatch) => {
    const [, res] = await getTermsDetailList({
      isDeleted: false,
      page,
      limit,
      filter: { account: id },
    });

    const list = res?.data?.list;
    return dispatch({
      type: TermDetailActionTypes.SET_TERM_DETAILS_LIST,
      payload: { id, list: res?.code === 'OK' && isArray(list) ? list : [], count: res?.data?.count },
    });
  },
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case TermDetailActionTypes.SET_EDIT_TERM:
      return { ...state, editTerm: action?.payload };

    case TermDetailActionTypes.SET_TERM_DETAILS_LIST:
      return { ...state, list: action?.payload?.list, count: action?.payload?.count };

    default:
      return { ...state };
  }
};
