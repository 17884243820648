import React from 'react';

import PropTypes from 'prop-types';

import { HeightUpdater } from 'util/grid';

const defaultStyles = { height: '0', overflow: 'auto' };

function GridHeightContainer({ tag = 'div', style, subtractFrom, adjustment, ...restProps }) {
  const ref = React.useRef();

  const heightUpdater = React.useMemo(() => {
    return new HeightUpdater(subtractFrom, [ref], adjustment);
  }, [ref, subtractFrom, adjustment]);

  React.useEffect(() => {
    heightUpdater.setHeight();
    heightUpdater.addResizeListener();
    return () => {
      heightUpdater.removeResizeListener();
    };
  }, [heightUpdater]);

  React.useEffect(() => {
    heightUpdater.setHeight();
  });

  return React.createElement(tag, { ref, style: { ...defaultStyles, ...style }, ...restProps });
}

GridHeightContainer.propTypes = {
  tag: PropTypes.string,
  subtractFrom: PropTypes.any,
  adjustment: PropTypes.number,
};

export default React.memo(GridHeightContainer);
