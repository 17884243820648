import * as ActionTypes from 'constants/ActionTypes';
import * as ThemeSetting from 'constants/ThemeSetting';

const initialSettings = {
  navCollapsed: true,
  navStyle: ThemeSetting.NAV_STYLE_MINI_SIDEBAR,
  layoutType: ThemeSetting.LAYOUT_TYPE_FULL,
  themeType: ThemeSetting.THEME_TYPE_LITE,
  colorSelection: ThemeSetting.THEME_COLOR_SELECTION_PRESET,
  pathname: '',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: { languageId: 'english', locale: 'en', name: 'English', icon: 'us' },
};

export default function settings(state = initialSettings, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return { ...state, pathname: action.payload.pathname, navCollapsed: false };

    case ActionTypes.TOGGLE_COLLAPSED_NAV:
      return { ...state, navCollapsed: action.navCollapsed };

    case ActionTypes.WINDOW_WIDTH:
      return { ...state, width: action.width };

    case ThemeSetting.THEME_TYPE:
      return { ...state, themeType: action.themeType };

    case ThemeSetting.THEME_COLOR_SELECTION:
      return { ...state, colorSelection: action.colorSelection };

    case ThemeSetting.NAV_STYLE:
      return { ...state, navStyle: action.navStyle };

    case ThemeSetting.LAYOUT_TYPE:
      return { ...state, layoutType: action.layoutType };

    case ActionTypes.SWITCH_LANGUAGE:
      return { ...state, locale: action.payload };

    default:
      return state;
  }
}
