import React, { Component } from 'react';
import { TabPanel } from 'react-tabs';

import AnnouncementAdd from './add';
import AnnouncementList from './list';

class Announcement extends Component {
  constructor(props) {
    super();
    this.state = {
      list: true,
    };
  }
  toggle = () => {
    this.setState({
      list: !this.state.list,
    });
  };
  render() {
    return (
      <div>
        {this.state.list ? <AnnouncementList onClick={this.toggle} /> : <AnnouncementAdd onClick={this.toggle} />}
      </div>
    );
  }
}

export default Announcement;
