import React from 'react';
import { Link } from 'react-router-dom';

import { PREFIX_URL_WITH_SLASH as PREFIX_PATH } from 'constants/CommonUrl';

const SingleStoneConfiguration = () => {
  const pathname = window.location.pathname;

  return (
    <div className="subTabInnerDropdown">
      <ul>
        {/* <li className={pathname === PREFIX_PATH + '/inventory/configuration/carat-range' ? 'active' : ''}>
          <Link to={PREFIX_PATH + '/inventory/configuration/carat-range'}>Carat Range</Link> */}
        {/* {TabData.subTitle.subMenuDropdown.map((d,index) => ( */}
        <li className={pathname === PREFIX_PATH + '/inventory/configuration/carat-range' ? 'active' : null}>
          <Link to={PREFIX_PATH + '/inventory/configuration/carat-range'}>Carat Range</Link>
        </li>
        {/* ))} */}
        {/* Carat Range */}
        {/* PREFIX_URL_WITH_SLASH + '/inventory/configuration/carat-range' */}
        <li className={pathname === PREFIX_PATH + '/inventory/configuration/global-policy' ? 'active' : null}>
          <Link to={`${PREFIX_PATH}/inventory/configuration/global-policy`}>Rapnet Policy</Link>
        </li>

        <li className={pathname === PREFIX_PATH + '/inventory/configuration/cut-group' ? 'active' : ''}>
          <Link to={PREFIX_PATH + '/inventory/configuration/cut-group'}>Cut Group</Link>
        </li>
        {/* <li className={pathname === PREFIX_PATH + '/inventory/configuration/config-match' ? 'active' : ''}>
          <Link to={PREFIX_PATH + '/inventory/configuration/config-match'}>Match Pair/Calibrated</Link>
        </li> */}
        {/* <li className={pathname === PREFIX_PATH + '/inventory/configuration/config-similar' ? 'active' : ''}>
          <Link to={PREFIX_PATH + '/inventory/configuration/config-similar'}>Similar Stones</Link>
        </li> */}
        <li
          className={`menuDropdown ${
            pathname === PREFIX_PATH + '/inventory/configuration/diamond-column-setting' ? 'active' : ''
          }`}
        >
          <Link to={PREFIX_PATH + '/inventory/configuration/diamond-column-setting'}>Diamond List Column Setting</Link>
        </li>
        {/* <li
          className={`menuDropdown ${
            pathname === PREFIX_PATH + '/inventory/configuration/download-Excel' ? 'active' : ''
          }`}
        >
          <Link to={PREFIX_PATH + '/inventory/configuration/download-Excel'}>Download Excel</Link>
        </li> */}
        {/* <li className={pathname === PREFIX_PATH + '/inventory/configuration/client-action' ? 'active' : ''}>
          <Link to={PREFIX_PATH + '/inventory/configuration/client-action'}>Client Actions Configuration</Link>
        </li> */}
        {/* <li className={pathname === PREFIX_PATH + '/inventory/configuration/manage-parameters' ? 'active' : ''}>
          <Link to={PREFIX_PATH + '/inventory/configuration/manage-parameters'}>Manage Parameters</Link>
        </li> */}
      </ul>
    </div>
  );
};
export default SingleStoneConfiguration;
