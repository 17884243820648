import React from 'react';
import './status.less';

function StatusBlock({ status, title }) {
  return (
    <div className={`statusBlock ${status}`}>
      <span>{title}</span>
    </div>
  );
}

export default React.memo(StatusBlock);
