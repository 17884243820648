export default [
  'id',
  'companyName',
  'vendorCode',
  'address',
  'emails',
  'phones',
  'zipCode',
  'country',
  'state',
  'city',
  // 'seller',
  'group',
  'isActive',
  'name',
  'website',
  'mobile',
  'fax',
  'bank',
  'showLabGrown',
  'showNatural',
];

/*[
  'createdAt',
  'updatedAt',
  'addedBy',
  'updatedBy',
  'id',
  'stockCategory',
  'vendorLogo',
  'vendorCode',
  'vendorNormalizeCode',
  'vendorNo',
  'prefix',
  'gender',
  'name',
  'profileImage',
  'code',
  'displayName',
  'companyName',
  'companyNormalizeName',
  'companyType',
  'ledgerType',
  'normalizeName',
  'accountType',
  'hasBroker',
  'brokerInfo',
  'registrationType',
  'grpCompany',
  'departmentHead',
  'department',
  'dateOfJoin',
  'dateOfAnniversary',
  'addressType',
  'address',
  'landMark',
  'street',
  'area',
  'zipCode',
  'country',
  'state',
  'city',
  'emails',
  'Website',
  'faxes',
  'phones',
  'mobiles',
  'bank',
  'social',
  'defaultNarration',
  'orderEmail',
  'newStoneEmail',
  'newsEventEmail',
  'noOfEmployee',
  'contactSource',
  'dateOfBirth',
  'accountName',
  'isSubscribeForNewGoodFavourite',
  'isSubscribeForNewGoodPurchase',
  'isSubscribeForNotification',
  'gstNo',
  'panNo',
  'verifyBy',
  'verifyDate',
  'seller', // Error 500
  'businessType',
  'designation',
  'howKnow',
  'referenceFrom',
  'isDeleted',
  'isActive',
  'androidPlayerId',
  'iosPlayerId',
  'status',
  'connectedSockets',
  'updateIp',
  'createIp',
  'createdBy',
  'user',
  'founded',
  'specialties',
  'companySize',
  'videoUrl',
  'about',
  'showPublicly',
  'coverImage',
  'isVerified',
  'accountTerm',
  'group',
  'approvedBy',
]*/
