export const SORTABLE_COLUMNS = {
  stoneId: 'Packet No',
  shpNm: 'Shape',
  crt: 'Carat',
  colNm: 'Color',
  clrNm: 'Clarity',
  shape: 'Shape',
  color: 'Color',
  carat: 'Carat',
};

// prettier-ignore
export const FLOAT_COLUMNS = ["amt", "back", "buyBack", "buyCtPr", "cAng", "cHgt", "crt", "ctPr", 'tblPer', "depPer", "fnDis", "grdlPer", "height", "length", "newAmount", "newDiscount", "newPricePerCarat", "oldBack", "pAng", "pHgt", "rapAvg", "ratio", "strLn", "width"];
// prettier-ignore
export const ROUND_COLUMNS = ['lwrHal', 'rap'];
// prettier-ignore
export const ALT_COLUMNS = ['lbNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'back', 'rap', 'fluNm'];
// prettier-ignore
export const NOCHECK_COLUMNS = ['Action', 'bidPricePerCarat', 'confirm', 'Details', 'dna', 'expBack', 'FutureDisc', 'hours', 'note', 'quote', 'reject', 'stockCount'];
// prettier-ignore
export const TOTAL_COLUMNS = ['ctPr', 'amt', 'rap', 'crt'];
// prettier-ignore
export const FILTER_COLUMNS = ['blkIncNm', 'blkSdNm', 'blkTblNm', 'brlncyNm', 'clrNm', 'colNm', 'cultNm', 'cutNm', 'eClnNm', 'fluNm', 'hANm', 'lbNm', 'locNm', 'mixTintNm', 'mlkNm', 'naturalNm', 'opCrwnNm', 'opIncNm', 'opPavNm', 'opTblNm', 'polNm', 'shdNm', 'shpNm', 'symNm', 'wSdNm', 'wTblNm', 'eFact', 'girdleStr', 'lstr', 'vndNm'];

export const TABLE_PAGE_LIMIT = 100;

export const SHOW_ALT_CERT = false;
export const SGI_MEMO_NAME = 'SGI Memo';
