import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'util/classNames';
import { isArray, isEmpty, isObject } from 'util/utils';

const InlineList = React.memo(({ list, separator, defaultStyle, parentClassName, labelClassName, skipEmpty }) => {
  if (!isArray(list)) return null;
  if (defaultStyle) {
    parentClassName = 'savedSearchValue';
    labelClassName = 'savedSearchLabel';
  }
  const max = list.length;

  return list.map((item, i) => (
    <React.Fragment key={i}>
      {React.isValidElement(item) ? (
        <>
          <span className={classNames(['list-item-inline', parentClassName])}>{item}</span>
          {max !== i + 1 && !defaultStyle && (separator || ', ')}
        </>
      ) : isObject(item) && (!isEmpty(item?.title) || !isEmpty(item?.value)) ? (
        <>
          <span className={classNames(['list-item-inline', parentClassName])}>
            {!isEmpty(item?.title) && <span className={classNames([labelClassName])}>{item?.title} : </span>}
            {!isEmpty(item?.value) && (
              <span>
                {isArray(item?.value) ? (
                  <InlineList skipEmpty list={item.value} />
                ) : item?.value?.toString ? (
                  item.value.toString()
                ) : (
                  `${item?.value}`
                )}
              </span>
            )}
          </span>
          {max !== i + 1 && !defaultStyle && (separator || ', ')}
        </>
      ) : (
        <>
          <span className={classNames(['list-item-inline', parentClassName])}>
            {!isEmpty(item) && (item?.toString ? item?.toString() : `${item}`)}
            {isEmpty(item) && !skipEmpty && '-'}
          </span>
          {max !== i + 1 &&
            (skipEmpty ? !isEmpty(item) && !defaultStyle && (separator || ', ') : !defaultStyle && (separator || ', '))}
        </>
      )}
    </React.Fragment>
  ));
});

InlineList.displayName = 'InlineList';

InlineList.propTypes = {
  list: PropTypes.array,
  separator: PropTypes.any,
};

export default InlineList;
