import React from 'react';

function NotAvailable(props) {
  const { title, type = 'Resource', src, ..._props } = props;
  return (
    <div {..._props}>
      {src && <img style={{ width: '300px', height: '303px' }} src={src} />}
      <span>{title ?? `${type} not available`}</span>
    </div>
  );
}

export default React.memo(NotAvailable);
