/**
 * Handles exceptions in a function
 * @param   {Function} func
 * @param   {Object}   options
 * @returns {Promise}
 */
export const asyncHandler = async (func, options = {}) => {
  const defaultOptions = { result: true };
  options = { ...defaultOptions, ...options };
  try {
    const response = await func();
    return options.result ? [false, response] : false;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn({ error });
    return options.result ? [true, error] : true;
  }
};
