import React, { Component } from 'react';

import { Checkbox } from 'antd';

import FileUpload from 'containers/FileUpload';
import InputBlock, { InputNumber } from 'containers/InputBlock';
import SelectOption from 'containers/SelectOption';
import TextArea from 'containers/TextArea';

import { showNotification } from 'services/NotificationService';
import { storeMasterDetail, updateMasterDetail } from 'services/SubMasterApi';

import { isEmpty, isArray } from 'util/utils';

let submitAction = 'save';

const groupData = [
  { value: 'Round', key: 'Round' },
  { value: 'Princess', key: 'Princess' },
  { value: 'Emerald', key: 'Emerald' },
  { value: 'Sq. Emerald', key: 'Sq. Emerald' },
];

class Form extends Component {
  state = {
    formData: {
      isActive: true,
      isWebVisible: true,
      isDefault: false,
    },
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.detail && nextProps.detail?.id != prevState.formData?.id) {
      const detail = nextProps.detail;
      if (!isEmpty(detail?.likeKeyWords) && isArray(detail?.likeKeyWords)) {
        detail.likeKeyWords = detail.likeKeyWords.join(',');
      }
      return { formData: { ...prevState.formData, ...nextProps.detail } };
    }
    return true;
  }

  handleChange = (name, value) => {
    if (name == 'likeKeyWords') {
      value = value ? value.split(',') : [];
    }

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };

  handleValidation = () => {
    let formIsValid = true;
    const errors = {};
    const data = { ...this.state.formData };
    if (!data['name']) {
      formIsValid = false;
      errors['name'] = 'Please enter name.';
    }
    if (!data['code']) {
      formIsValid = false;
      errors['code'] = 'Please enter code.';
    }
    if (!data['webDisplay']) {
      formIsValid = false;
      errors['webDisplay'] = 'Please enter Web Display.';
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  formRef = React.createRef();
  handleSubmit = async (event) => {
    if (!isEmpty(event)) event.preventDefault();

    const data = { ...this.state.formData };
    data.parentId = this.props.parentId;
    data.parentCode = this.props.parentCode;
    data.group = data.group || '';

    if (this.handleValidation()) {
      if (!this.props?.detail?.id) {
        const [, res] = await storeMasterDetail(data);
        showNotification(res);
      } else {
        data.updateId = this.props?.detail?.id;
        const [, res] = await updateMasterDetail(data);
        showNotification(res);
      }

      const { detail } = this.props;
      if (submitAction == 'continue') {
        if (isEmpty(detail?.id)) {
          this.formRef.current.reset();
          this.setState({
            formData: {
              isActive: true,
              isWebVisible: true,
              isDefault: false,
            },
          });
        }
      } else {
        this.props.onClick();
      }
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="caratRangeWrapper">
        <form onSubmit={this.handleSubmit} ref={this.formRef}>
          <div className="caratRangeCol">
            <InputBlock
              label="Name *"
              placeholder="Name"
              name="name"
              value={!isEmpty(this.state?.formData?.name) ? this.state?.formData?.name : null}
              onChange={(event) => {
                this.handleChange('name', event.target.value.replace(/[^\w\s\][_-]/gi, ''));
              }}
              error={errors?.name?.length > 0 && errors?.name}
            />
            <InputBlock
              label="Code *"
              placeholder="Code"
              name="code"
              value={this.state?.formData?.code}
              onChange={(event) => {
                this.handleChange('code', event.target?.value?.toUpperCase());
              }}
              error={errors?.code?.length > 0 && errors?.code}
              disabled={this.props?.detail?.id ? true : false}
            />
            <InputBlock
              label="Web Display *"
              placeholder="Web Display"
              name="webDisplay"
              value={this.state?.formData?.webDisplay}
              onChange={(event) => {
                this.handleChange('webDisplay', event.target?.value);
              }}
              error={errors?.webDisplay?.length > 0 && errors?.webDisplay}
            />
            <SelectOption
              selectValue={groupData}
              label="Group"
              placeholder="Select Group"
              name="group"
              value={this.state.formData.group ? this.state.formData.group : 'Select Group'}
              onChange={(value) => {
                this.handleChange('group', value);
              }}
              error={errors?.group?.length > 0 && errors?.group}
            />
            <TextArea
              label="Like Keywords"
              placeholder="Like Keywords"
              defaultValue=""
              value={this.state?.formData?.likeKeyWords}
              name="likeKeyWords"
              onChange={(event) => {
                this.handleChange('likeKeyWords', event.target.value);
              }}
            />
            <TextArea
              label="Description"
              placeholder="Description"
              name="description"
              defaultValue=""
              value={this.state?.formData?.description}
              onChange={(event) => {
                this.handleChange('description', event.target.value);
              }}
            />
            <div className="from-group">
              <InputNumber
                label="Sequence"
                type="number"
                placeholder="Sequence"
                name="sortingSequence"
                className="width-100"
                value={this.state?.formData?.sortingSequence}
                onChange={(event) => {
                  this.handleChange('sortingSequence', event.target.value);
                }}
              />
              <div className="d-flex">
                <div className="checkBoxForm">
                  <label>Active</label>
                  <Checkbox
                    checked={this.state.formData.isActive}
                    name="isActive"
                    onChange={(event) => {
                      this.handleChange('isActive', event.target.checked);
                    }}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Display</label>
                  <Checkbox
                    checked={this.state.formData.isWebVisible}
                    name="isWebVisible"
                    onChange={(event) => {
                      this.handleChange('isWebVisible', event.target.checked);
                    }}
                  />
                </div>
                <div className="checkBoxForm">
                  <label>Default</label>
                  <Checkbox
                    checked={this.state?.formData?.isDefault}
                    name="isDefault"
                    onChange={(event) => {
                      this.handleChange('isDefault', event.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
            <FileUpload
              files={[this.state?.formData?.image]}
              label="Choose File"
              name="image"
              onChange={(e) => this.handleChange('image', e || '')}
            />
          </div>
          <div className="configActionButton">
            <button className="commonButton" type="submit" onClick={() => (submitAction = 'continue')}>
              Save & Continue
            </button>
            <button className="commonButton" type="submit" onClick={() => (submitAction = 'save')}>
              Save
            </button>
            <button className="commonOutine" type="reset" onClick={this.props.onClick}>
              Back
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Form;
