import memoize from 'lodash/memoize';

import { DIAMOND_VIEW_TYPE } from 'constants/Inventory';

import { COMMON_URL } from './CommonUrl';
import ProjectDetails from './ProjectDetails.json';

export const LOCALE = 'en-US';
export const CURRENCY = 'USD';
export const TIMEZONE_IANA = 'Asia/Kolkata';
export const LUXON_FORMAT = { DATE: 'dd/LL/y', TIME: 'hh:mm a', DATE_TIME: 'dd/LL/y hh:mm a', DURATION: 'hh:mm:ss' };
export const FLOAT_PRECISION = 2;
export const HOME_SETTING_FORMAT = 'DD-MM-Y HH:mm:ss';
export const LOCAL_STORAGE_VAR = 'pn';

export const FORMAT_DATETIME = LUXON_FORMAT.DATE_TIME;
export const FORMAT_DATE = LUXON_FORMAT.DATE;
export const FORMAT_TIME = LUXON_FORMAT.TIME;
export const FORMAT_DURATION = LUXON_FORMAT.DURATION;
export const PRECISION = FLOAT_PRECISION;

export const ALLOW_ROW_SELECTION = true;
export const ALLOW_ROW_HIGHLIGHT_BY_LAB = true;
export const ALLOW_ROW_GROUP_TITLE_TOGGLE = false;

export const CALENDER_FIELD = [
  'SHOW',
  'INFO',
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const USER_TYPE = {
  ADMIN: { SUPER: 1, GENERAL: 11 },
  PHARMACY: { ADMIN: 2, PHARMACIST: 21, LICENSED_TECHNICIAN: 22, INTERN: 23, SHIPPER: 24, RECEIVER: 25 },
  ORGANIZATION: { ADMIN: 3 },
  HOME: {
    ADMIN: 4,
    HOME_AREA: { ADMIN: 5, DOCTOR: 51, NURSE: 52, STAFF: 53, PATIENT: 54 },
  },
};

export const FILE_TYPES = {
  pdf: ['application/pdf'],
  image: ['image/jpeg', 'image/png', 'image/jpg'],
  json: ['application/json'],
};

export const VERSION_PLATFORM = { ANDROID: 1, IPHONE: 2 };

export const EVENT = {
  TYPE: { EVENT: '1', NEWS: '2', SHOW: '3', OTHER: '4', CSR: '5', POPUP: '6' },
};

export const FEATURE_STONE = {
  BEST_BUY: 'stone_of_day',
  // BEST_OF_FINESTAR: 'best',
  // EXCLUSIVE_STONE: 'exclusive',
  // PAIR: 'pair',
  FEATURE_STONE: 'featured_stone',
};

export const USERS_TYPE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_USER: 3,
  PRIMARY: 4,
  PERMANENT_GUEST: 5,
  EMPLOYEE: 6,
  API_USER: 7,
  SELLER: 8,
  CUSTOMER: 9,
  SECONDARY: 10,
  MASTER_SALES: 11,
};

export const Employe_Type = { commonPER: 2, AdminPER: 1 };

export const PERMISSION_TYPE = { user: 'user', role: 'role' };

export const PARCEL_PRICE_OPERATION_TYPE = { SAVE_AS_NEW: '1', UPDATE: '2' };

export const PARCEL_PRICE_SHAPE = {
  ROUND: 'Round',
  PRINCESS: 'Princess',
  PEAR: 'Pear',
  OVAL: 'Oval',
  MARQUISE: 'Marquise',
};

export const NEWS = {
  DISPLAYON: { WEB: '1', APP: '2' },
  TYPE: { NOTIFICATION: '1', 'HELLO BAR': '2', 'TRADE SHOW': '3', OTHER: '4' },
};

export const BANNER_TYPES = {
  HOMEPAGE_TOP: 1,
  HOMEPAGE_SECOND: 2,
  HOMEPAGE_THIRD_LEFT: 3,
  HOMEPAGE_THIRD_RIGHT: 4,
  HOMEPAGE_FOUR: 5,
  HOMEPAGE_BOTTOM: 6,
  LOGIN_REGISTER: 7,
};

export const BANNER_SEARCH_BAR_POSITION = {
  CENTER: 1,
  TOP: 2,
  DOWN: 3,
  LEFT: 4,
  RIGHT: 5,
};

export const PAGE_TRACKING_DEVICE = { WEB: 1, ANDROID: 2, IOS: 3 };

export const PAGE_TRACKING_ACTION = {
  OPEN: 'open',
  CLOSE: 'close',
  UPLOAD: 'upload',
  RESET: 'reset',
  SELECT: 'select',
  UNSELECT: 'unselect',
  CUSTOM: 'custom',
  EXISTING: 'existing',
  FANCY: 'fancy',
  WHITE: 'white',
  LIST: 'list',
  CLICK: 'click',
  PRINT: 'print',
  EXCEL: 'excel',
  GRID: 'grid',
  VIDEO: 'video',
  PICTURE: 'picture',
  CERTIFICATE: 'certificate',
  CHANGE: 'change',
  DOWNLOAD: 'download',
  FOCUS: 'focus',
  SEARCH: 'search',
  COMPLETE: 'complete',
};

export const PAGE_TRACKING_SECTION = {
  ADVANCE_SEARCH: 'AdvanceSearch',
  ADD_DEMAND: 'AddDemand',
  SAVE_SEARCH: 'SavedSearch',
  UPLOAD_EXCEL: 'UploadExcel',
  RESET_FILTER: 'ResetFilter',
  THREE_EX: 'ThreeEx',
  TWO_EX: 'TwoEx',
  THREE_VG: 'ThreeVg',

  CARAT_SIZE: 'CaratSize',
  COLOR: 'Color',
  DIAMONDS: 'Diamonds',
  MODIFY: 'Modify',
  STICKY_FILTER: 'StickyFilter',
  PLACE_ORDER: 'PlaceOrder',
  SHIPMENT: 'Shipment',
  ENQUIRY: 'Enquiry',
  UPDATE: 'Update',
  EXPORT: 'Export',
  EXPORT_SEND_EMAIL: 'Export-Send_Email',
  ADD_TO_CART: 'AddToCart',
  ADD_TO_WATCHLIST: 'AddToWatchList',
  COMMENT: 'Comment',
  REMINDER: 'Reminder',
  VIEW: 'View',
  FILTER: 'Filter',
  SETTING: 'Setting',
  TABLE_ADD_TO_CART: 'Table-AddToCart',
  TABLE_ADD_TO_WATCHLIST: 'Table-AddToWatchList',
  TABLE_DELETE: 'Table-Delete',
  TABLE_COMMENT: 'Table-Comment',
  TABLE_REMINDER: 'Table-Reminder',
  TABLE_DETAILS: 'Table-Details',
  EDIT: 'Edit',
  DELETE: 'Delete',
  SEARCH: 'Search',
  CHANGE_PASSWORD: 'ChangePassword',
  INVENTORY: 'Inventory',
  MY_CART: 'MyCart',
  CALCULATOR: 'Calculator',
  TRACK_SHIPMENT: 'TrackShipment',
  LOCALE: 'Locale',
  LOGIN: 'login',
  LOGOUT: 'logout',
};

export const PAGE_TRACKING_PAGE = {
  DIAMOND_SEARCH: 'DiamondSearch',
  FANCY_SEARCH: 'FancySearch',
  MY_DEMAND: 'MyDemand',
  MY_SAVE_SEARCH: 'MySavedSearch',
  SEARCH_RESULT: 'SearchResult',
  UPCOMING_DIAMOND: 'UpcomingDiamond',
  NEW_DIAMOND: 'NewDiamond',
  CART: 'MyCart',
  WATCHLIST: 'MyWatchlist',
  REMINDER: 'MyReminder',
  COMMENT: 'MyComment',
  ENQUIRY: 'MyEnquiry',
  DEMAND: 'MyDemand',
  ORDER: 'MyOrder',
  ACCOUNT: 'MyAccount',
  HEADER: 'Header',
  RECOMMENDED: 'Recommended',
  USER: 'user',
  HOME: 'Home',
  DRAWER: 'Drawer',
  VENDOR_DIAMOND: 'vendor-diamond',
  SHEET_UPLOAD_V1: 'sheet-upload-V1',
  PROFILE: 'profile',
};

export const BANNER_TYPES_IMAGE = {
  Image: 1,
  Text: 2,
};

export const TRACK_TYPES = {
  Cart: 1,
  Watchlist: 2,
  Request: 3,
  Offer: 4,
  Reminder: 5,
  Enquiry: 6,
  Shipment: 7,
  Comment: 99,
};
export const TRANSACTION = {
  HOLD: 1,
  MEMO: 2,
};
export const TRANSACTION_ENQUIRY = {
  CUSTOMER_WISE: 1,
  DATE_WISE: 2,
  DIAMONDLIST: 3,
  DATE_CUSTOMER: 4,
  COUNTRY_WISE: 5,
  SALES_PERSON_WISE: 6,
};

export const TRANSPORT_STATUS = { Pending: '1', Done: '2', Cancel: '3' };

export const HOSPITALITY_STATUS = { Pending: '0', approved: '1', Cancel: '3' };

export const UserPage_Type = { Pending: '1', Verified: '2', Cancel: '3', verifyLogin: '4' };

export const Sheet_Processing = {
  PENDING: '1',
  PROCESSING: '2',
  COMPLETED: '3',
  FAILED: '4',
  UPLOADING: '5',
  UPLOADING_COMPLETED: '6',
  UPLOADING_FAILED: '7',
  ALL: '8',
};

export const FTP_REQUEST = { PENDING: '1', ACCEPTABLE: '2', CANCEL: '3' };

export const Account_Detail_tabs = {
  CompanyDetail: '3',
  BasicInfo: '1',
  AddressDetail: '2',
  Users: '4',
  Ftp: '5',
};

export const LOGIN_PLATFORM = { Android: '2', IOS: '3', Web: '1' };

export const MasterAction = {
  color: 'color',
  clarity: 'clarity',
  Symmetry: 'Symmetry',
  shape: 'shape',
  fluorescence: 'fluorescence',
  Location: 'Location',
  SIZE: 'SIZE',
  Polish: 'Polish',
  cut: 'Cut',
};

export const diamondParameters = {
  shp: { key: 'shp', name: 'Shape', master: 'SHAPE' },
  carat: { key: 'carat', name: 'Carat Range', master: 'SIZE' },
  col: { key: 'col', name: 'Color', master: 'COLOR' },
  clr: { key: 'clr', name: 'Clarity', master: 'CLARITY' },
  flu: { key: 'flu', name: 'Fluorescence', master: 'FLUORESCENCE' },
  crt: { key: 'crt', name: 'Carat', master: 'COLOR' },
  shd: { key: 'shd', name: 'Color Shade', master: 'SHADE' },
  lb: { key: 'lb', name: 'Lab', master: 'LAB' },
  cut: { key: 'cut', name: 'Cut', master: 'CUT' },
  pol: { key: 'pol', name: 'Polish', master: 'POLISH' },
  sym: { key: 'sym', name: 'Symmetry', master: 'SYMMETRY' },
  loc: { key: 'loc', name: 'Location', master: 'LOCATION' },
  inten: { key: 'inten', name: 'Intensity', master: 'INTENSITY' },
  ovrtn: { key: 'ovrtn', name: 'Overtone', master: 'OVERTONE' },
  blkInc: { key: 'blkInc', name: 'Black Table', master: 'BLACK_INCLUSION' },
  eCln: { key: 'eCln', name: 'Eye Clean', master: 'EYECLEAN' },
  mlk: { key: 'mlk', name: 'Milky', master: 'MILKEY' },
  hA: { key: 'hA', name: 'Heart and Arrow', master: 'H_AND_A' },
  rap: { key: 'rap', name: 'Rap Discount' },
  ctPr: { key: 'ctPr', name: 'Price/Carat' },
  amt: { key: 'amt', name: 'Amount' },
  depPer: { key: 'depPer', name: 'Depth %' },
  tblPer: { key: 'tblPer', name: 'Table %' },
  grdl: { key: 'grdl', name: 'Girdle', master: 'GIRDLE' },
  grdlThn: { key: 'grdlThn', name: 'Girdle', master: 'GIRDLE' },
  pAng: { key: 'pAng', name: 'Pavilion Angle' },
  cAng: { key: 'cAng', name: 'Crown Angle' },
  cHgt: { key: 'cHgt', name: 'Crown Height' },
  pHgt: { key: 'pHgt', name: 'Pavilian Depth' },
  dmtrMn: { key: 'dmtrMn', name: 'Diameter Min' },
  dmtrMxn: { key: 'dmtrMxn', name: 'Diameter Max' },
  or: { key: 'or', name: 'Carat' }, // or has more props than caret
  ratio: { key: 'ratio', name: 'Ratio' },
  showSrNo: { key: 'showSrNo', name: 'Show ID' },
  kToSStr: { key: 'kToSStr', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  fcCol: { key: 'fcCol', name: 'Fancy Color', master: 'FANCY_COLOR' },
  isFcCol: { key: 'isFcCol', name: 'Has Fancy Color' },
  noBGM: { key: 'noBGM', name: 'No Brown, Green or Milky' },
  grdlCond: { key: 'grdlCond', name: 'Girdle Condition' },
  cultCond: { key: 'cultCond', name: 'Culet Condition' },
  cult: { key: 'cult', name: 'Culet', master: 'CULET' },
  opTbl: { key: 'opTbl', name: 'Open Inclusion Table', master: 'OPEN_INCLUSION_TABLE' },
  opPav: { key: 'opPav', name: 'Open Inclusion Pavilion', master: 'OPEN_INCLUSION_PAVILION' },
  opCrwn: { key: 'opCrwn', name: 'Open Inclusion Crown', master: 'OPEN_INCLUSION_CROWN' },
  keyToSymbol: { key: 'keyToSymbol', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  wTbl: { key: 'wTbl', name: 'White Inclusion Table', master: 'WHITE_INCLUSION_TABLE' },
  blkTbl: { key: 'blkTbl', name: 'Black Inclusion Table', master: 'BLACK_INCLUSION_TABLE' },
  blkSd: { key: 'blkSd', name: 'Black Inclusion Side', master: 'BLACK_INCLUSION_SIDE' },
  wSd: { key: 'wSd', name: 'White Inclusion Side', master: 'WHITE_INCLUSION_SIDE' },
  brlncy: { key: 'brlncy', name: 'Brilliancy', master: 'BRILLIANCY' },
  stoneId: { key: 'stoneId', name: 'Stone ID/ Report Number' },
  vnd: { key: 'vnd', name: 'Seller' },
  vStnId: { key: 'vStnId', name: 'Seller Stone Number' },
  length: { key: 'length', name: 'Length' },
  width: { key: 'width', name: 'Width' },
  height: { key: 'height', name: 'Depth' },
  counId: { key: 'counId', name: 'Location' },
  country: { key: 'country', name: 'Country' },
  shpNm: { key: 'shpNm', name: 'Shape' },
  lab: { key: 'lab', name: 'Lab' },
  colNm: { key: 'colNm', name: 'Color' },
  clrNm: { key: 'clrNm', name: 'Clarity' },
  lap: { key: 'lap', name: 'LAP%' },
  lapCt: { key: 'lapCt', name: 'LAP/CT' },
  lapAmt: { key: 'lapAmt', name: 'Lap Amt' },
  Sale: { key: 'Sale', name: 'Sale' },
  saleCt: { key: 'saleCt', name: 'Sale/CT' },
  saleAmt: { key: 'saleAmt', name: 'Sale Amt' },
  cutNm: { key: 'cutNm', name: 'Cut' },
  polNm: { key: 'polNm', name: 'Pol' },
  symNm: { key: 'symNm', name: 'Sym' },
  flo: { key: 'flo', name: 'Flo' },
  tb: { key: 'tb', name: 'TB%' },
  dp: { key: 'dp', name: 'DP%' },
  msrmnt: { key: 'msrmnt', name: 'Measurement' },
  lbNm: { key: 'lbNm', name: 'Lab' },
  rptNo: { key: 'rptNo', name: 'CERT. NO.' },
  locNm: { key: 'locNm', name: 'Location' },
  fluNm: { key: 'fluNm', name: 'Fluorescence' },
  back: { key: 'back', name: 'Discount %' },
  eClnNm: { key: 'eClnNm', name: 'Eye Clean' },
  shdNm: { key: 'shdNm', name: 'Brown' },
  mlkNm: { key: 'mlkNm', name: 'Milky' },
  mixTintNm: { key: 'mixTintNm', name: 'Mix Tint' },
  grdlPer: { key: 'grdlPer', name: 'Girdle %' },
  strLn: { key: 'strLn', name: 'Star Length' },
  lwrHal: { key: 'lwrHal', name: 'Lower Half' },
  girdleStr: { key: 'girdleStr', name: 'Girdle' },
  grdlCondNm: { key: 'grdlCondNm', name: 'Girdle Faceted' },
  cultNm: { key: 'cultNm', name: 'Culet' },
  blkIncNm: { key: 'blkIncNm', name: 'Black Inclusion' },
  wInclNm: { key: 'wInclNm', name: 'Other Inclusion' },
  opIncNm: { key: 'opIncNm', name: 'Open Inclusion' },
  eFact: { key: 'eFact', name: 'Extra Facet' },
  natural: { key: 'natural', name: 'Natural' },
  hANm: { key: 'hANm', name: 'H & A' },
  lbCmt: { key: 'lbCmt', name: 'Lab Comments' },
  lsrInc: { key: 'lsrInc', name: 'Laser Ins' },

  blkTblNm: { name: 'Center Natts', key: 'blkTblNm' },
  blkSdNm: { name: 'Side Natts', key: 'blkSdNm' },
  wTblNm: { name: 'Center White', key: 'wTblNm' },
  wSdNm: { name: 'Side White', key: 'wSdNm' },
  opTblNm: { name: 'Table Open', key: 'opTblNm' },
  opCrwnNm: { name: 'Crown Open', key: 'opCrwnNm' },
  opPavNm: { name: 'Pavillion Open', key: 'opPavNm' },
  brlncyNm: { name: 'Brilliancy', key: 'brlncyNm' },
  type2Nm: { name: 'Type2 Cert', key: 'type2Nm' },
  mines: { name: 'Rough Mine', key: 'mines' },
  //
  wSts: { name: 'Stage', key: 'wSts' },
  sSts: { name: 'Stage Status', key: 'sSts' },
  prcStg: { name: 'Price Stage', key: 'prcStg' },
  cDis: { name: 'Dis %', key: 'cDis' },
  lowerhalf: { name: 'Lower Half', key: 'lowerhalf' },
  starlength: { name: 'Star Length', key: 'starlength' },
  kToSArr: { name: 'Key To Symbol', key: 'kToSArr' },
  opInc: { key: 'opInc', name: 'Open Inclusion' },
  strLngth: { key: 'strLngth', name: 'Star Length' },
  mixTint: { key: 'mixTint', name: 'Mix Tint' },
  lwr: { key: 'lwr', name: 'Lower Half' },
};

export const diamondparameters2 = [
  { key: 'stoneId', name: 'Stone Id' },
  { key: 'shapeId', name: 'Shape' },
];

export const SEARCH_HISTORY = { RECENT: 1, SAVE: 2, DEMAND: 3, VOICE_SEARCH: 6 };

export const OVERFLOW_COUNT = 1500000;
export const PAGES_PERMISSION = {
  // prettier-ignore
  '1': [1, 60, 61, 62, 63, 64, 65, 66, 67, 71, 72, 73, 74, 75, 76, 77, 78, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 120, 118, /* Appointment */ 119, /* Slot */ 107, 108, 109, 110, 111, 112, 113, 121, /* parcel-master */ 122, /* setting */ 123, /* notification-master */ 124, 125, 126, 127, 128, 129, 130, 131, 140, 141, 151, 162, 163, /* hit-counter */ 164, /* location */ 145, /* sync setting */ 146, /* upload history */ 147, /* stock-summary */ 148, /* ftp request */],
  3: [1],
  4: [1],
};

export const IMAGE_PATH = 'https://diamanti.s3.amazonaws.com/images/diamond/***.jpg';
export const VIDEO_PATH = 'https://diamanti.s3.amazonaws.com/video/Vision360.html?d=***';
export const CERTI_PATH = 'http://diamanti.s3.amazonaws.com/certificates/***.jpg';

export const BASE_URL = COMMON_URL.BASE_URL;
export const BASE_LINK = COMMON_URL.BASE_LINK;
export const BASE_URL_FILE_UPLOAD = COMMON_URL.BASE_URL_FILE_UPLOAD;
export const BASE_SYNC_URL = COMMON_URL.BASE_SYNC_URL;
export const EXCELFILE_URL = COMMON_URL.EXCELFILE_URL;

export const DEFAULT_API_ERROR = 'Something went wrong. Please contact admin.';
export const ERR_MSG = 'Something went wrong.';

export const MIME_TYPES = {
  png: ['89504e47'],
  jpeg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  jpg: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
  pdf: ['25504446'],
  xlsx: ['504b0304', '504b34', '504b0506', '504b0708'],
  ods: ['504b0304', '504b34', '504b0506', '504b0708'],
  xls: ['d0cf11e0'],
  csv: ['rfc4180', '5372204e', '50412c53', '53746f6e'],
  mp4: ['00020'],
};

export const PROJECT_DETAILS = {
  NAME: ProjectDetails && ProjectDetails.projectName ? ProjectDetails.projectName : 'FineStar',
  LOGO_1:
    ProjectDetails && ProjectDetails.logo1 ? BASE_URL + ProjectDetails.logo1 : require('../assets/images/logo.png'),
  LOGO_2:
    ProjectDetails && ProjectDetails.logo2 ? BASE_URL + ProjectDetails.logo2 : require('../assets/images/logo1.png'),
  LOGO_3:
    ProjectDetails && ProjectDetails.logo3 ? BASE_URL + ProjectDetails.logo3 : require('../assets/images/logo1.png'),
};

export const bussinessTypeArray = {
  // Polished_Dealer: 'Polished Dealer',
  // Jewellery_Manufacturer: 'Jewellery Manufacturer',
  // Diamond_Manufacturer: 'Diamond Manufacturer',
  // Retailer: 'Retailer',
  // Consumer: 'Consumer',
  'Independent Jeweler': 'Independent Jeweler',
  'Online Jewelry Store': 'Online Jewelry Store',
  'Diamond Dealer/ Broker': 'Diamond Dealer/ Broker',
  'Diamond Manufacturer / Cutter': 'Diamond Manufacturer / Cutter',
  'Jewelry Manufacturer': 'Jewelry Manufacturer',
  'Jewelry Retail Chain': 'Jewelry Retail Chain',
  'Pawn shop': 'Pawn shop',
  Appraiser: 'Appraiser',
  Designer: 'Designer',
  'Not in the Diamond / Jewelry Trade': 'Not in the Diamond / Jewelry Trade',
  'Gold Buyer': 'Gold Buyer',
  'Diamond Mining': 'Diamond Mining',
  Auctioneer: 'Auctioneer',
  'Trade Association': 'Trade Association',
  'Watch Dealer': 'Watch Dealer',
  'Finance / Banking': 'Finance / Banking',
  Investor: 'Investor',
  'Jewelry Appraiser': 'Jewelry Appraiser',
  'Jewelry Retailer': 'Jewelry Retailer',
  'Diamond and Pearl Dealer / Broker': 'Diamond and Pearl Dealer / Broker',
  'Colored Stone Dealer / Broker': 'Colored Stone Dealer / Broker',
  'Estate Jewelry Dealer / Broker': 'Estate Jewelry Dealer / Broker',
  Other: 'Other',
};

export const DIAMOND_SEARCH_TYPE = {
  RECENT: 1,
  SAVE: 2,
  DEMAND: 3,
  DEMAND_HOLD: 14,
  API_SEARCH: 4,
  PAIR_SEARCH: 5,
  VOICE_SEARCH: 6,
  LUCKY_SEARCH: 7,
  ARTICLE: 8,
  COLLECTION: 9,
  INVENTORY: 10,
  API_SAVED_SEARCH: 11,
};

export const INVENTORY_PLATFORMS = [
  { key: 1, value: 'RAPNET' },
  { key: 2, value: 'R2NET' },
  { key: 3, value: 'IDEX' },
  { key: 4, value: 'MARKET DIAMONDS' },
  { key: 5, value: 'GET DIAMONDS' },
  { key: 6, value: 'VDB' },
  { key: 7, value: 'UNI DIAMONDS' },
  { key: 8, value: 'DIAMONDS ON CALL' },
];

export const INVENTORY_UPLOAD_METHODS = [
  { key: 1, value: 'API' },
  { key: 2, value: 'FTP' },
  { key: 3, value: 'SFTP' },
];

export const INVENTORY_SYNC_TIME = [
  { key: 15, value: '15 mins' },
  { key: 30, value: '30 mins' },
  { key: 60, value: '60 mins' },
];

export const INVENTORY_FILE_TYPES = [
  { key: '.csv', value: 'csv' },
  { key: '.xlsx', value: 'xlsx' },
];

export const ACCOUNT_TERM_TYPE = {
  GLOBAL: 1,
  GROUP: 2,
  INDIVIDUAL: 3,
  SHOW: 4,
};

export const EXTRA_TERMS_TYPE = {
  AVG_DISCOUNT: 1, // same discount for all stones
  CTPR_USD: 2,
  CTPR_PERCENTAGE: 3,
  RAP_DISCOUNT: 4,
  PER_STONE_USD: 5,
  PER_STONE_DISCOUNT: 6,
  FIXED_CTPR: 7,
};

export const BANNER_TYPE = {
  HOMEPAGE: 1,
};
export const DIAMOND_BLOCK = {
  TYPE: {
    HOLD: 1,
    MEMO: 2,
  },
  STATUS: {
    PENDING: 1,
    APPROVED: 2,
    REJECT: 3,
    RELEASE: 4,
  },
  OFFER: {
    APPLIED: 1,
    ACCEPTED: 2,
    REJECTED: 3,
  },
};

export const ORDER_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  CANCELED: 4,
  HISTORY: 5,
};

export const QUOTE_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  EXPIRED: 4,
};

export const ACCOUNT_IS_VERIFIED = {
  1: 'PENDING',
  2: 'VERIFIED',
  3: 'CANCELED',
};

export const MEMO_STATUS = {
  PENDING: 1,
  APPROVED: [2, 5],
  REJECTED: 3,
  RELEASE: 4,
};

export const SHIP_MODE = [
  { value: 'Ship To Direct', key: 0 },
  { value: 'Ship To NY', key: 1 },
  { value: 'Ship To HK', key: 2 },
  { value: 'Ship to Dubai', key: 3 },
  { value: 'Ship to Antwerp', key: 4 },
  { value: 'Ship to Japan', key: 5 },
];

export const INVOICE_DATE = [
  { value: 'Today', key: 0 },
  { value: 'Tomorrow', key: 1 },
  { value: 'Later', key: 2 },
];

export const PROJECT_SETTINGS = {
  TYPE: {
    CM_CHARGES: 1,
    BANK_RATES: 2,
    MIN_MAX: 23,
  },
};

export const MEETING_TYPE = [
  { value: 'Phone Call', key: 1 },
  { value: 'Web Conference', key: 2 },
  { value: 'In Person', key: 3 },
];
export const LEDGER_TYPE = [
  { key: 'customer', value: 'Customer' },
  { key: 'broker', value: 'Broker' },
  { key: 'supplier', value: 'Supplier' },
  { key: 'associate office', value: 'Associate office' },
  { key: 'mfg unit', value: 'Mfg unit' },
  { key: 'individual', value: 'Individual' },
  { key: 'buyer', value: 'Buyer' },
];

export const OFFERSTATUS = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
};

export const KYC_STATUS = [
  { key: 1, value: 'Pending' },
  { key: 2, value: 'Approved' },
  { key: 3, value: 'Rejected' },
];

export const TRANSACTION_HOLD_STATUS = [
  'H',
  'N',
  'I',
  'CH',
  'SH',
  'EH',
  'CX',
  'SX',
  'EX',
  'B',
  'CB',
  'SB',
  'EB',
  'J',
  'CJ',
  'SJ',
  'EJ',
];
export const TRANSACTION_HOLD_BNR2_STATUS = ['B', 'CB', 'SB', 'EB', 'J', 'CJ', 'SJ', 'EJ'];
export const TRANSACTION_MEMO_CONSIGNMENT_STATUS = ['M', 'C1', 'C2', 'C3', 'C4', 'C5', 'T', 'CM', 'EM', 'SM', 'O'];
export const TRANSACTION_MEMO_OFFICE_STATUS = ['O'];

export const approveStatus = [
  { key: 1, value: 'Pending' },
  { key: 2, value: 'Approve' },
  { key: 3, value: 'Reject' },
];

export const approveStatusLabel = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
};

export const MEDIA_TYPES = {
  JPG_ARROW_BLACK: 'JPG_ARROW_BLACK',
  JPG_ASSET_SCOPE: 'JPG_ASSET_SCOPE',
  JPG_HEART_BLACK: 'JPG_HEART_BLACK',
  PDF_LAB_CERT: 'PDF_LAB_CERT',
  JPG_LIGHT_BLACK: 'JPG_LIGHT_BLACK',
  JPG_STILL: 'JPG_STILL',
  HTML_VIDEO_360: 'HTML_VIDEO_360',
  MP4_VIDEO_360: 'MP4_VIDEO_360',
};


export const MEDIA_URLS = {
  JPG_ARROW_BLACK: 'https://api.mydiamonds.co/web/v1/diamond/media/JPG_ARROW_BLACK/***',
  JPG_ASSET_SCOPE: 'https://api.mydiamonds.co/web/v1/diamond/media/JPG_ASSET_SCOPE/***',
  JPG_HEART_BLACK: 'https://api.mydiamonds.co/web/v1/diamond/media/JPG_HEART_BLACK/***',
  PDF_LAB_CERT: 'https://api.mydiamonds.co/web/v1/diamond/media/PDF_LAB_CERT/***',
  JPG_LIGHT_BLACK: 'https://api.mydiamonds.co/web/v1/diamond/media/JPG_LIGHT_BLACK/***',
  JPG_STILL: 'https://api.mydiamonds.co/web/v1/diamond/media/JPG_STILL/***',
  HTML_VIDEO_360: 'https://api.mydiamonds.co/web/v1/diamond/media/HTML_VIDEO_360/***',
  MP4_VIDEO_360: 'https://api.mydiamonds.co/web/v1/diamond/media/MP4_VIDEO_360/***',
};

export const getMediaUrl = (mediaType = MEDIA_TYPES.JPG_STILL, replace = '') =>
  `${MEDIA_URLS[mediaType] ?? ''}`.replace(/[*]{3}/g, replace);

export const LAB_LINKS = {
  IGI: 'https://igi.org/verify.php?r=***',
  GSI: 'http://wg.gemscience.net/vr/veri.aspx',
  GIA: 'https://www.gia.edu/report-check?reportno=***',
  'GIA RESULT': 'https://www.gia.edu/report-check?reportno=***',
};

export const FILE_URLS = {
  img: MEDIA_URLS.JPG_STILL,
  lightBlack: MEDIA_URLS.JPG_LIGHT_BLACK,
  videoFile: MEDIA_URLS.HTML_VIDEO_360,
  mp4Video: MEDIA_URLS.MP4_VIDEO_360,
  hAFile: MEDIA_URLS.JPG_HEART_BLACK,
  arrowFile: MEDIA_URLS.JPG_ARROW_BLACK,
  certFile: MEDIA_URLS.PDF_LAB_CERT,
  assetFile: MEDIA_URLS.JPG_ASSET_SCOPE,
};

export const HOLD_MEMO_BLOCK_SETTING = {
  HOLD: '5faa3e10fee4d616f95d9c42',
  MEMO: '5f9ff997acf27d10033f0171',
};

export const DateTitle = {
  offer: 'Offer Expiry Date :',
  officeView: 'Office Visit Date :',
  hold: 'Hold Expiry Date :',
};

export const Print = {
  OFFER: 1,
  ORDER: 2,
  CART: 3,
  WATCHLIST: 4,
  OFFICE: 5,
  MEMO: 6,
  HOLD: 7,
  ENQUIRY: 8,
  STONE: 9,
  STONEOFDAY: 13,
};

export const PrintTransaction = {
  CART: 'CART',
  WATCHLIST: 'WATCHLIST',
  ORDER: 'ORDER',
  OFFICE: 'OFFICE',
  OFFER: 'OFFER',
  REMINDER: 'REMINDER',
  ENQUIRY: 'ENQUIRY',
  COMMENT: 'COMMENT',
  SHIPMENT: 'SHIPMENT',
  SHOWSELECT: 'SHOWSELECT',
  MEMO: 'MEMO',
  HOLD: 'HOLD',
  STONE: 'STONE',
  STONEOFDAY: 'STONEOFDAY',
};

export const PrintSubTab = {
  pending: 1,
  approved: 2,
  rejected: 3,
  upComing: 1,
  expire: 2,
};

export const Office = {
  upComing: 1,
  expire: 2,
};

export const StatusList = [
  { value: 'Available', key: 'S' },
  { value: 'Memo', key: 'I' },
  { value: 'Hold', key: 'W' },
  // { value: 'Consignment', key: 'X' },
  { value: 'Best Buy', key: 'D' },
  { value: 'Reserved', key: 'Y' },
  { value: 'Reserved Sold', key: 'R' },
  { value: 'Available On Result', key: 'C' },
  // { value: 'Reject', key: 'R' },
  // { value: 'Blocked', key: 'B' },
  // { value: 'Recut Issue', key: 'K' },
  { value: 'Unreserved Sold', key: 'U' },
  { value: 'Not Available', key: 'NA' },
  // { value: 'Sold', key: 'O' },
  // { value: 'Reserved Sold', key: 'R' },
];

export const CommonPrintExcel = {
  inNewArrival: { filter: { isFm: { nin: ['CERT', 'ELIG'] } }, viewType: DIAMOND_VIEW_TYPE.NEW_ARRIVAL },
  inStoneOfDay: { filter: { wSts: ['D'] } },
};

export const dashBoardUrl = {
  Watchlist: { label: 'Watchlist', url: `/transaction/watchlist` },
  Cart: { label: 'Cart', url: `/transaction/cart` },
  Office: { label: 'Office View Request', url: `/transaction/office-view/today` },
  Offer: { label: 'Offer Pending', url: `/transaction/offer/pending` },
  Order: { label: 'Order Pending', url: `/transaction/order/pending` },
  Memo: { label: 'Memo Created', url: `/transaction/memo` },
  // Hold: { label: 'Hold', url: `/transaction/hold` },
  // comment: { label: 'Comment', url: '' },
  // reminder: { label: 'Reminder', url: '' },
};

export const CompareStoneData = {
  vStnId: { key: 'vStnId', name: 'Stone ID' },
  image: { key: 'mfgStnId', name: 'Diamond Image' },
  lbNm: { key: 'lbNm', name: 'Lab' },
  rptNo: { key: 'rptNo', name: 'Cert. No.' },
  locNm: { key: 'locNm', name: 'Location' },
  shpNm: { key: 'shpNm', name: 'Shape' },
  crt: { key: 'crt', name: 'Carat' },

  carat: { key: 'carat', name: 'Carat Range', master: 'SIZE' },
  col: { key: 'col', name: 'Color', master: 'COLOR' },
  clr: { key: 'clr', name: 'Clarity', master: 'CLARITY' },
  flu: { key: 'flu', name: 'Fluorescence', master: 'FLUORESCENCE' },

  shd: { key: 'shd', name: 'Color Shade', master: 'SHADE' },
  cut: { key: 'cut', name: 'Cut', master: 'CUT' },
  pol: { key: 'pol', name: 'Polish', master: 'POLISH' },
  sym: { key: 'sym', name: 'Symmetry', master: 'SYMMETRY' },
  loc: { key: 'loc', name: 'Location', master: 'LOCATION' },
  inten: { key: 'inten', name: 'Intensity', master: 'INTENSITY' },
  ovrtn: { key: 'ovrtn', name: 'Overtone', master: 'OVERTONE' },
  blkInc: { key: 'blkInc', name: 'Black Table', master: 'BLACK_INCLUSION' },
  eCln: { key: 'eCln', name: 'Eye Clean', master: 'EYECLEAN' },
  mlk: { key: 'mlk', name: 'Milky', master: 'MILKEY' },
  hA: { key: 'hA', name: 'Heart and Arrow', master: 'H_AND_A' },
  rap: { key: 'rap', name: 'Rap (%)' },
  ctPr: { key: 'ctPr', name: 'Price/Carat' },
  amt: { key: 'amt', name: 'Amount' },
  depPer: { key: 'depPer', name: 'Depth %' },
  tblPer: { key: 'tblPer', name: 'Table %' },
  grdl: { key: 'grdl', name: 'Girdle (%)', master: 'GIRDLE' },
  grdlThn: { key: 'grdlThn', name: 'Girdle (%)', master: 'GIRDLE' },
  pAng: { key: 'pAng', name: 'Pav Angle' },
  cAng: { key: 'cAng', name: 'Crown Angle' },
  cHgt: { key: 'cHgt', name: 'Crown Height (%)' },
  pHgt: { key: 'pHgt', name: 'Pavilian Depth (%)' },
  dmtrMn: { key: 'dmtrMn', name: 'Diameter Min' },
  dmtrMxn: { key: 'dmtrMxn', name: 'Diameter Max' },
  or: { key: 'or', name: 'Carat' }, // or has more props than caret
  ratio: { key: 'ratio', name: 'Ratio' },
  showSrNo: { key: 'showSrNo', name: 'Show ID' },
  kToSStr: { key: 'kToSStr', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  fcCol: { key: 'fcCol', name: 'Fancy Color', master: 'FANCY_COLOR' },
  isFcCol: { key: 'isFcCol', name: 'Has Fancy Color' },
  noBGM: { key: 'noBGM', name: 'No Brown, Green or Milky' },
  grdlCond: { key: 'grdlCond', name: 'Girdle Condition' },
  cultCond: { key: 'cultCond', name: 'Culet Condition' },
  cult: { key: 'cult', name: 'Culet', master: 'CULET' },
  opTbl: { key: 'opTbl', name: 'Open Inclusion Table', master: 'OPEN_INCLUSION_TABLE' },
  opPav: { key: 'opPav', name: 'Open Inclusion Pavilion', master: 'OPEN_INCLUSION_PAVILION' },
  opCrwn: { key: 'opCrwn', name: 'Open Inclusion Crown', master: 'OPEN_INCLUSION_CROWN' },
  keyToSymbol: { key: 'keyToSymbol', name: 'Key To Symbol', master: 'KEY_TO_SYMBOLS' },
  wTbl: { key: 'wTbl', name: 'White Inclusion Table', master: 'WHITE_INCLUSION_TABLE' },
  blkTbl: { key: 'blkTbl', name: 'Black Inclusion Table', master: 'BLACK_INCLUSION_TABLE' },
  blkSd: { key: 'blkSd', name: 'Black Inclusion Side', master: 'BLACK_INCLUSION_SIDE' },
  wSd: { key: 'wSd', name: 'White Inclusion Side', master: 'WHITE_INCLUSION_SIDE' },
  brlncy: { key: 'brlncy', name: 'Brilliancy', master: 'BRILLIANCY' },
  stoneId: { key: 'stoneId', name: 'Stone ID/ Report Number' },
  vnd: { key: 'vnd', name: 'Seller' },
  length: { key: 'length', name: 'Length' },
  width: { key: 'width', name: 'Width' },
  height: { key: 'height', name: 'Depth' },
  counId: { key: 'counId', name: 'Location' },
  country: { key: 'country', name: 'Country' },

  lab: { key: 'lab', name: 'Lab' },
  colNm: { key: 'colNm', name: 'Color' },
  clrNm: { key: 'clrNm', name: 'Clarity' },
  lap: { key: 'lap', name: 'LAP%' },
  lapCt: { key: 'lapCt', name: 'LAP/CT' },
  lapAmt: { key: 'lapAmt', name: 'Lap Amt' },
  Sale: { key: 'Sale', name: 'Sale' },
  saleCt: { key: 'saleCt', name: 'Sale/CT' },
  saleAmt: { key: 'saleAmt', name: 'Sale Amt' },
  cutNm: { key: 'cutNm', name: 'Cut' },
  polNm: { key: 'polNm', name: 'Polish' },
  symNm: { key: 'symNm', name: 'Symmetry' },
  flo: { key: 'flo', name: 'Flo' },
  tb: { key: 'tb', name: 'TB%' },
  dp: { key: 'dp', name: 'DP%' },
  msrmnt: { key: 'msrmnt', name: 'Dimension (MM)' },
  fluNm: { key: 'fluNm', name: 'Fluorescence' },
  back: { key: 'back', name: 'DISCOUNT %' },
  eClnNm: { key: 'eClnNm', name: 'EYE CLEAN' },
  shdNm: { key: 'shdNm', name: 'Brown' },
  mlkNm: { key: 'mlkNm', name: 'Milky' },
  mixTintNm: { key: 'mixTintNm', name: 'Mix Tint' },
  grdlPer: { key: 'grdlPer', name: 'Girdle %' },
  strLn: { key: 'strLn', name: 'Star length' },
  lwrHal: { key: 'lwrHal', name: 'Lower Half' },
  girdleStr: { key: 'girdleStr', name: 'Girdle' },
  grdlCondNm: { key: 'grdlCondNm', name: 'Girdle Faceted' },
  cultNm: { key: 'cultNm', name: 'Culet' },
  blkIncNm: { key: 'blkIncNm', name: 'Black Inclusion' },
  wInclNm: { key: 'wInclNm', name: 'Other Inclusion' },
  opIncNm: { key: 'opIncNm', name: 'Open Inclusion' },
  eFact: { key: 'eFact', name: 'Extra Facet' },
  natural: { key: 'natural', name: 'Natural' },
  hANm: { key: 'hANm', name: 'H & A' },
  lbCmt: { key: 'lbCmt', name: 'Lab Comments' },
  lsrInc: { key: 'lsrInc', name: 'Laser Ins' },

  blkTblNm: { name: 'Center Natts', key: 'blkTblNm' },
  blkSdNm: { name: 'Side Natts', key: 'blkSdNm' },
  wTblNm: { name: 'Center White', key: 'wTblNm' },
  wSdNm: { name: 'Side White', key: 'wSdNm' },
  opTblNm: { name: 'Table Open', key: 'opTblNm' },
  opCrwnNm: { name: 'Crown Open', key: 'opCrwnNm' },
  opPavNm: { name: 'Pavillion Open', key: 'opPavNm' },
  brlncyNm: { name: 'Brilliancy', key: 'brlncyNm' },
  type2Nm: { name: 'Type2 Cert', key: 'type2Nm' },
  mines: { name: 'Rough Mine', key: 'mines' },
  //
  wSts: { name: 'Stage', key: 'wSts' },
  sSts: { name: 'Stage Status', key: 'sSts' },
  prcStg: { name: 'Price Stage', key: 'prcStg' },
  cDis: { name: 'Dis %', key: 'cDis' },
  lowerhalf: { name: 'Lower Half', key: 'lowerhalf' },
  starlength: { name: 'Star Length', key: 'starlength' },
  kToSArr: { name: 'Key To Symbol', key: 'kToSArr' },
};
export const MEMO_HOLD_STATUS = {
  pending: 1,
  approved: 2,
  rejected: 3,
};

export const BID_WEEK_DAYS = [
  { key: 'Sunday', value: 'Sunday' },
  { key: 'Monday', value: 'Monday' },
  { key: 'Tuesday', value: 'Tuesday' },
  { key: 'Wednesday', value: 'Wednesday' },
  { key: 'Thursday', value: 'Thursday' },
  { key: 'Friday', value: 'Friday' },
  { key: 'Saturday', value: 'Saturday' },
];

export const DYNAMIC_BID_TYPE = [
  { key: 1, value: 'NEW_ARRIVAL' },
  { key: 2, value: 'BID_TO_BUY' },
];

export const INVENTORY_BID = [
  { key: 'DAILY', value: 'DAILY' },
  { key: 'WEEKLY', value: 'WEEKLY' },
  // { key: 'MONTHLY', value: 'MONTHLY' },
];

export const INVENTORY_TIMEZONE = [
  { key: 'CET', value: 'CET' },
  { key: 'IST', value: 'IST' },
];
export const INVENTORY_CALCOLUMN = [
  // { key: 'ctPr', value: 'PRICE/CT' },
  { key: 'back', value: 'DISC%' },
];
export const INVENTORY_RESULT_TYPE = [
  { key: 'AUTO', value: 'AUTO' },
  { key: 'MANUEL', value: 'MANUEL' },
];
export const INVENTORY_RESULT = [
  // { key: '1', value: 'Split' },
  { key: '2', value: 'Actual' },
];
export const INVENTORY_DIAMPRESTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];
export const INVENTORY_DIAMPOSTSTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'N', value: 'New Arrival' },
];
export const INVENTORY_DIAMSTATUS = [
  { key: 'B', value: 'Bid To Buy' },
  { key: 'D', value: 'Best Buy' },
  // { key: 'N', value: 'New Arrival' },
];
