import React from 'react';
import Magnifier from 'react-magnifier';

import { useBoolean } from 'util/hooks';

function MagnifierImage(props) {
  const { image, src, errSrc, zoomFactor = 1, clickToOpen, children, ...restProps } = props;

  const ref = React.useRef();
  const url = React.useMemo(() => src ?? image, [src, image]);
  const [error, setError] = useBoolean(false);

  const handleClick = React.useCallback(() => void (clickToOpen && window.open(url, '_blank')), [clickToOpen, url]);

  React.useEffect(() => {
    const image = ref?.current?.img;
    if (!image) return;
    image.addEventListener('load', setError.false);
    image.addEventListener('error', setError.true);
  });

  return error ? (
    children ?? (
      <div className="nodataFoundDetail">
        <div className="nodataFoundInner">
          <img src={errSrc ?? props.tempSrc} />
          <span>No Image Found</span>
        </div>
      </div>
    )
  ) : (
    <Magnifier ref={ref} src={url} zoomFactor={zoomFactor} onClick={handleClick} {...restProps} />
  );
}

export default React.memo(MagnifierImage);
