import React from 'react';

import { FLOAT_COLUMNS } from 'components/Inventory/table-config';

import { formatDecimal, formatCurrency, isNumber, reduceTotal } from 'util/utils';

import { PRECISION } from 'constants/Common';

function DiamondTotalCard(props) {
  const { row, col } = props;

  const result = React.useMemo(() => {
    const n =
      col === 'ctPr'
        ? reduceTotal(row?.groupData.amt) / reduceTotal(row?.groupData.crt)
        : reduceTotal(row?.groupData[col]);

    const formatter = FLOAT_COLUMNS.includes(col) && col === 'crt' ? formatDecimal : formatCurrency;
    const fractionLength = FLOAT_COLUMNS.includes(col) ? (col === 'crt' ? 3 : PRECISION) : 0;

    return formatter(isNumber(n) ? n : 0, { fractionLength });
  }, [row, col]);

  return <span className="selectAmount">{result}</span>;
}
export default DiamondTotalCard;
