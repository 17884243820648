import * as ActionTypes from 'constants/ActionTypes';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';

export const fetchStart = () => ({ type: ActionTypes.FETCH_START });

export const fetchSuccess = () => ({ type: ActionTypes.FETCH_SUCCESS });

export const fetchError = (error) => ({ type: ActionTypes.FETCH_ERROR, payload: error });

export const showMessage = (message) => ({ type: ActionTypes.SHOW_MESSAGE, payload: message });

export const hideMessage = () => ({ type: ActionTypes.HIDE_MESSAGE });

export const currentPageData = (data) => ({ type: ActionTypes.CUURENT_PAGE_DATA, data: data });

export const resetDiamondSearch = (data) => ({ type: ActionTypes.RESET_SEARCH, data: data });

export const modifyDiamondSearch = (data) => ({ type: ActionTypes.MODIFY_SEARCH, data: data });

export function switchStoneType(type) {
    if (!type) {
        const current = localStorage.getItem(`${LOCAL_STORAGE_VAR}-labGrown`) || 'N';
        if (current == 'N') type = 'Y';
        else type = 'N';
    }
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-labGrown`, type);
    return {
        type: ActionTypes.SWITCH_STONE_TYPE,
        payload: type,
    };
}